import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from "axios";
import * as environment from "../Global";
import {withRouter} from "react-router-dom";

class DetalleTomadas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mostrarvf1: true,
            resumenvf1: false,
            info: [],
            status: null,
            numVacFijas: 0,
            arrVacFijas: [],
            numVacTemp: 0,
            arrVacTemp: [],
            numVacOcup: 0,
            numVacDisp: 0,
            arrVacOcupad: [],
            loader: false,
            btnDescargar: false,
            inputFcUser: false
        };
    }

    componentDidMount() {
        console.log("estas proppppppppps", this.params)
        this.getDetalleSolicitudTomada();
    }


    getDetalleSolicitudTomada = () => {
        this.setState({ loader: true})
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/solicits/detailSolicit", {
            'data':
            {
                "idSolicit": this.props.props.idSolicit,
                "idSucursal": this.props.props.idSucursal
            }
        }).then(response => {
            console.log(response);
            this.setState({
                info: response.data.data.solicits[0],
                status: 'success',
                //arrVacOcupad: response.data.data.solicits[0].DetailUserRegisted,
                loader: false
            });
            console.log('DetalleTomada', this.state.info)
            for (const solicitudTomada of this.state.info.DetailUserRegisted) {
                if (solicitudTomada.type_user === "FIJO") {
                    this.setState({
                        numVacFijas: this.state.numVacFijas + 1,
                    });
                } else if (solicitudTomada.type_user === "TEMPORAL" ) {
                    this.setState({
                        numVacTemp: this.state.numVacTemp + 1
                    });
                }
                if (solicitudTomada.nom_use != null){
                    this.setState({
                        numVacOcup: this.state.numVacOcup + 1
                    });
                } else {
                    this.setState({
                        numVacDisp: this.state.numVacDisp + 1
                    });
                }
            }
            if (this.state.numVacDisp===0){
                this.setState({
                    btnDescargar: true
                })
            }
        });
    }

    vacanteFijaChange = (e) => {
        var nombreFijo = document.getElementsByName("nombreF" + e.target.id);
        var apellidoFijo = document.getElementsByName("apellidoF" + e.target.id);
        var check = document.getElementById("checkF" + e.target.id);

        if (nombreFijo[0].value !== "" && apellidoFijo[0].value !== "" && nombreFijo[0].value.length >= 3 && apellidoFijo[0].value.length >= 3) {

            check.style.display = "block";
        } else {

            check.style.display = "none";
        }

    }

    vacanteTemporalChange = (e) => {
        var nombreTemporal = document.getElementsByName("nombreT" + e.target.id);
        var apellidoTemporal = document.getElementsByName("apellidoT" + e.target.id);
        var check = document.getElementById("checkT" + e.target.id);
        if (nombreTemporal[0].value !== "" && apellidoTemporal[0].value !== "" && nombreTemporal[0].value.length >= 3 && apellidoTemporal[0].value.length >= 3) {
            check.style.display = "block";
        } else {
            check.style.display = "none";
        }
    }

    botonGuardar = () => {

        Swal.fire({
            position: 'center',
            title: "¿Quieres agregar estos candidatos a la solicitud?",
            confirmButtonColor: "#4DA357",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',

        }).then((result) => {
            if (result.isConfirmed) {
                if (this.state.numVacDisp === 0) {
                    console.log("se completa");
                    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/changeStatus", {
                        "data":{
                         "idSolicit": this.props.props.idSolicit
                        }
                       }).then(response => {
                        console.log("response");
                        console.log(response);
                        window.location.href = "/solicitudes/completas";
                    })
                    
                }
                else {
                    console.log("no se completa");
                }
            }
        });
    }

    render() {

        var arrVacFijas = [];
        var arrVacTemp = [];
        var arrVacantesOcupadas = [];
        if (this.state.info.typeVacancies != null) {
            for (const vacante of this.state.info.DetailUserRegisted) {
                if (vacante.type_user === "FIJO" && vacante.nom_use != null) {
                    arrVacantesOcupadas.push(vacante);

                } else if (vacante.type_user === "FIJO" && vacante.nom_use == null ) {
                    arrVacFijas.push(vacante);

                } else if (vacante.type_user === "TEMPORAL" && vacante.nom_use != null ) {
                    arrVacantesOcupadas.push(vacante);

                } else if (vacante.type_user === "TEMPORAL" && vacante.nom_use == null ) {
                    arrVacTemp.push(vacante);
                }
            }
        }
        /*if (this.state.arrVacOcupad != null) {
            this.state.arrVacOcupad.map((vacanteocupada) => {
                arrVacantesOcupadas.push(vacanteocupada);
            });
        }*/
        return (
            this.state.loader ?
                <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
                :
            <div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">

                    </div>
                    <div className="col col-lg-3 offset-lg-2">

                    </div>
                </div>
                <div className="row">
                    <div className="FondoBlanco mt-3 p-5">
                        <div className="row mt-3">
                            <div className="col col-lg-12">
                                <Link to="/solicitudes/tomadas">
                                    <button type="button" className="btn btn-outline-success btn-rounded btn-icon">
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                    </button>
                                </Link>
                                <b className="verdeTitle">{this.state.info.economicNumber}</b><b className="negroTitle"> - {this.state.info.nameSuc}</b>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col col-lg-10'>
                                <p className="pFade"><b className="pFadeB">Territorio:</b> {this.state.info.territory} <b className="pFadeB">Zona - Región</b> {this.state.info.zone} / {this.state.info.region} <b className="pFadeB">Dirección:</b> {this.state.info.address} C.P. {this.state.info.cp}</p>
                                <p className="pFade"><b className="pFadeB">Municipio:</b> {this.state.info.municipality} <b className="pFadeB">Estado:</b> {this.state.info.state}</p>
                            </div>
                            {
                                this.state.btnDescargar ?
                                    <div className='col col-lg-2'>
                                        <button onClick={this.botonGuardar} id="btnDescargar" type="button" className="btn btn-outline-ba-active ">Guardar</button>
                                    </div>
                                    :
                                    <div className='col col-lg-2'>
                                        <label></label>
                                    </div>
                            }

                        </div>
                        <div className='row mt-3'>
                            {<div className='col col-lg-10'>
                                {/*<p className="pNormal"><b className="bNormal">Responsable de la solicitud:</b> Hugo Galicia</p>*/}
                                <p className="pNormal"><b className="bNormal">Fecha de solicitud:</b> {this.state.info.solicit_date}</p>
                            </div>}
                        </div>
                        <div className='row mt-3'>
                            <div className='col col-lg-3'>
                                <p><b className="verdeTitle">Número de Vacantes Disponibles</b></p>
                                <p className="pNormal">{ this.state.numVacDisp }</p>
                            </div>
                            <div className='col col-lg-7'>
                                <p><b className="verdeTitle">Fecha tentativa de ingreso</b></p>
                                <p className="pNormal">{this.state.info.tentative_date}</p>
                            </div>
                        </div>

                        {
                            arrVacFijas.map((vacanteFija, index) => {

                                let hrs_init = "am"
                                if (Date(vacanteFija.time_init) > Date("12:00")) {
                                    hrs_init = "pm";
                                }
                                let hrs_end = "am"
                                if (Date(vacanteFija.time_end) >= Date("12:00")) {
                                    hrs_end = "pm";
                                }
                                return (
                                    <div className='row mt-3' id={"rowFija" + index} key={index}>
                                        <div className='col col-lg-3'>
                                            <p><b className="verdeTitle">Vacante fija {index + 1}:</b></p>
                                        </div>
                                        <div className='col col-lg-3'>
                                            <p className="pNormal"><b className="bNormal">Horario:</b> {vacanteFija.time_init} {hrs_init} - {vacanteFija.time_end} {hrs_end}</p>
                                        </div>
                                        <div className="col col-lg-2">
                                            <input onChange={this.vacanteFijaChange} id={index} type="text" name={'nombreF' + index} className="form-control" placeholder="Nombre" />
                                        </div>
                                        <div className="col col-lg-2">
                                            <input onChange={this.vacanteFijaChange} id={index} type="text" name={'apellidoF' + index} className="form-control" placeholder="Apellido" />
                                        </div>
                                        <div className="col col-lg-2">
                                            <i onClick={(e) => {
                                                var check = document.getElementById("checkF" + index);
                                                check.style.display = "none";
                                                var nombreFijo = document.getElementsByName("nombreF" + index);
                                                var apellidoFijo = document.getElementsByName("apellidoF" + index);
                                                console.log("horario entrada" + vacanteFija.time_init);
                                                var idHorario = 0;
                                                switch (vacanteFija.time_init) {
                                                    case "9:00":
                                                        console.log("es a las nueve");
                                                        idHorario = 1;
                                                        break;
                                                    case "10:00":
                                                        console.log("es a las diez");
                                                        idHorario = 3;
                                                        break;
                                                    case "11:00":
                                                        console.log("es a las once");
                                                        idHorario = 2;
                                                        break;
                                                    case "12:00":
                                                        console.log("es a las doce");
                                                        idHorario = 4;
                                                        break;

                                                    default: console.log("Case default");
                                                }
                                                //type vacancy 3 fijo (1 producción) y 4 temporal (2 producción)
                                                var idtipovacanteFijo;//cambiar dependiendo el ambiente 3 dev, 2 qa

                                                if (process.env.REACT_APP_ENVIRONMEN === 'dev') {
                                                    idtipovacanteFijo = 3;
                                                } else if (process.env.REACT_APP_ENVIRONMEN === 'qa') {
                                                    idtipovacanteFijo = 1;
                                                }


                                                axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/saveUser", {
                                                    data:  {
                                                        userModified: [{
                                                            name: nombreFijo[0].value,
                                                            lastName: apellidoFijo[0].value,
                                                            idStatus: 1,
                                                            idUser: vacanteFija.id_user,
                                                            action: 1 //1 PARA GUARDAR // 2 EDITAR //3 DELETE
                                                        }],
                                                        idSolicit: parseInt(this.props.props.idSolicit)
                                                    }
                                                }).then(response => {
                                                    var row = document.getElementById("rowFija" + index);
                                                    row.style.display = "none";
                                                    var check = document.getElementById("checkF" + index);
                                                    check.style.display = "none";
                                                    window.location.reload(false);
                                                })
                                            }} id={'checkF' + index} style={{ display: "none" }} className="fa fa-check checknombres m-over" aria-hidden="true" />
                                        </div>
                                    </div>
                                );
                            })
                        }

                        {
                            arrVacTemp.map((vacanteTemporal, index) => {

                                let hrs_init = "am"
                                if (Date(vacanteTemporal.time_init) > Date("12:00")) {
                                    hrs_init = "pm";
                                }
                                let hrs_end = "am"
                                if (Date(vacanteTemporal.time_end) >= Date("12:00")) {
                                    hrs_end = "pm";
                                }
                                return (
                                    <div className='row mt-3' id={"rowTemporal" + index} key={index}>
                                        <div className='col col-lg-3'>
                                            <p><b className="verdeTitle">Vacante temporal {index + 1}:</b></p>
                                        </div>
                                        <div className='col col-lg-3'>
                                            <p className="pNormal"><b className="bNormal">Semanas:</b> {vacanteTemporal.week_number}</p>
                                            <p className="pNormal"><b className="bNormal">Horario:</b> {vacanteTemporal.time_init} {hrs_init} - {vacanteTemporal.time_end} {hrs_end}</p>

                                        </div>
                                        <div className="col col-lg-2">
                                            <input maxLength={36} minLength={3} type="text" id={index} onChange={this.vacanteTemporalChange} name={'nombreT' + index} className="form-control" placeholder="Nombre" />
                                        </div>
                                        <div className="col col-lg-2">
                                            <input maxLength={36} minLength={3} type="text" id={index} onChange={this.vacanteTemporalChange} name={'apellidoT' + index} className="form-control" placeholder="Apellido" />
                                        </div>
                                        <div className="col col-lg-2">
                                            <i onClick={(e) => {
                                                var check = document.getElementById("checkT" + index);
                                                check.style.display = "none";
                                                var nombreTemporal = document.getElementsByName("nombreT" + index);
                                                var apellidoTemporal = document.getElementsByName("apellidoT" + index);
                                                var idHorario = 0;
                                                switch (vacanteTemporal.time_init) {
                                                    case "9:00":
                                                        console.log("es a las nueve");
                                                        idHorario = 1;
                                                        break;
                                                    case "10:00":
                                                        console.log("es a las diez");
                                                        idHorario = 3;
                                                        break;
                                                    case "11:00":
                                                        console.log("es a las once");
                                                        idHorario = 2;
                                                        break;
                                                    case "12:00":
                                                        console.log("es a las doce");
                                                        idHorario = 4;
                                                        break;

                                                    default: console.log("Case default");
                                                }
                                                //type vacancy 3 fijo (1 producción) y 4 temporal (2 producción)
                                                var idtipovacanteTemporal;//cambiar dependiendo el ambiente 3 dev, 2 qa

                                                if (process.env.REACT_APP_ENVIRONMEN === 'dev') {
                                                    idtipovacanteTemporal = 4;
                                                } else if (process.env.REACT_APP_ENVIRONMEN === 'qa') {
                                                    idtipovacanteTemporal = 2;
                                                }

                                                console.log("ESTA ES LA VACANTE TEMPORAL", vacanteTemporal);
                                                axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/saveUser", {

                                                    data:  {
                                                            userModified: [{
                                                                name: nombreTemporal[0].value,
                                                                lastName: apellidoTemporal[0].value,
                                                                idStatus: 1,
                                                                idUser: vacanteTemporal.id_user,
                                                                action: 1 //1 PARA GUARDAR // 2 EDITAR //3 DELETE
                                                            }],
                                                            idSolicit: parseInt(this.props.props.idSolicit)
                                                    }
                                                }).then(response => {
                                                    var row = document.getElementById("rowTemporal" + index);
                                                    row.style.display = "none";
                                                    var check = document.getElementById("checkT" + index);
                                                    check.style.display = "none";
                                                    window.location.reload(false);
                                                })
                                            }} id={'checkT' + index} style={{ display: "none" }} className="fa fa-check checknombres m-over" aria-hidden="true" />
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className='row mt-3'>
                            <div className='col col-lg-5'>
                                <p><b className="verdeTitle">Número de vacantes ocupadas:</b></p>
                                <p className="pNormal">{this.state.numVacOcup}</p>
                            </div>
                        </div>
                        {arrVacantesOcupadas.map((vacanteocupada, key) => {
                            return (
                                <div className='row mt-3' key={key}>
                                    <div className='col col-lg-1'>
                                        <p className="pNormal"><b className="bNormal">ID:{vacanteocupada.id_user}</b></p>
                                    </div>

                                    <div className='col col-lg-2' id={"form-editar-nom" + key} style={{ display: "none" }}>
                                        <input id={"form-editar-input-nom" + key} onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                var nombreeditar = document.getElementById("form-editar-input-nom" + key).value;
                                                var apellidoeditar = document.getElementById("form-editar-input-ape" + key).value;

                                                if(nombreeditar !== "" && apellidoeditar !== "" && nombreeditar.length >= 3 && apellidoeditar.length >= 3) {

                                                    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/saveUser", {
                                                        data: {
                                                            userModified: [{
                                                                name: nombreeditar,
                                                                lastName: apellidoeditar,
                                                                idStatus: 1,
                                                                idUser: vacanteocupada.id_user,
                                                                action: 2 //1 PARA GUARDAR // 2 EDITAR //3 DELETE
                                                            }],
                                                            idSolicit: parseInt(this.props.props.idSolicit)
                                                        }
                                                    }).then(response => {
                                                        Swal.fire({
                                                            position: 'center',
                                                            title: "El promotor se actualizó correctamente",
                                                            confirmButtonColor: "#4DA357",
                                                            showCancelButton: false,
                                                            showConfirmButton: true,
                                                            confirmButtonText: 'OK'
                                                        });
                                                        window.location.reload(false);
                                                    })
                                                } else {
                                                    Swal.fire({
                                                        position: 'center',
                                                        text: "El nombre de promotor y/o apellido son obligatorios y debe contener al menos 3 caracteres",
                                                        confirmButtonColor: "#4DA357",
                                                        showCancelButton: false,
                                                        showConfirmButton: true,
                                                        confirmButtonText: 'OK'
                                                    });
                                                }
                                            }
                                        }} maxLength={36} minLength={3} type="text" onChange={this.vacanteTemporalChange} className="form-control" defaultValue={vacanteocupada.nom_use} />
                                    </div>
                                    <div className='col col-lg-2' id={"form-editar-ape" + key} style={{ display: "none" }}>
                                        <input id={"form-editar-input-ape" + key} onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                var nombreeditar = document.getElementById("form-editar-input-nom" + key).value

                                                var apellidoeditar = document.getElementById("form-editar-input-ape" + key).value

                                                if (nombreeditar !== "" && apellidoeditar !== "" && nombreeditar.length >= 3 && apellidoeditar.length >= 3) {

                                                    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/saveUser", {
                                                        data: {
                                                            userModified: [{
                                                                name: nombreeditar,
                                                                lastName: apellidoeditar,
                                                                idStatus: 1,
                                                                idUser: vacanteocupada.id_user,
                                                                action: 2 //1 PARA GUARDAR // 2 EDITAR //3 DELETE
                                                            }],
                                                            idSolicit: parseInt(this.props.props.idSolicit)
                                                        }
                                                    }).then(response => {
                                                        Swal.fire({
                                                            position: 'center',
                                                            title: "El promotor se actualizó correctamente",
                                                            confirmButtonColor: "#4DA357",
                                                            showCancelButton: false,
                                                            showConfirmButton: true,
                                                            confirmButtonText: 'OK'
                                                        });
                                                        window.location.reload(false);
                                                    })
                                                } else {
                                                    Swal.fire({
                                                        position: 'center',
                                                        text: "El nombre de promotor y/o apellido son obligatorios y debe contener al menos 3 caracteres",
                                                        confirmButtonColor: "#4DA357",
                                                        showCancelButton: false,
                                                        showConfirmButton: true,
                                                        confirmButtonText: 'OK'
                                                    });
                                                }

                                            }
                                        }} maxLength={36} minLength={3} type="text" onChange={this.vacanteTemporalChange} className="form-control" defaultValue={vacanteocupada.ape_use} />
                                    </div>

                                    <div className='col col-lg-2' id={"form-nomape" + key} style={{ display: "block" }}>
                                        <p className="pNormal"><b className="bNormal">{vacanteocupada.nom_use} {vacanteocupada.ape_use}</b></p>
                                    </div>

                                    <div className='col col-lg-3' >
                                        <select className="form-control" value={vacanteocupada.status_vac}  id={"select-" + key} disabled onChange={() => {

                                            axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/changeStatusUser", {
                                                'data':
                                                    {
                                                        "userModified": [
                                                            {
                                                                "idStatus": document.getElementById("select-" + key).value,
                                                                "idUser": parseInt(vacanteocupada.id_user),
                                                                "fcUser": null
                                                            }
                                                        ],
                                                        "idSolicit": parseInt(this.props.props.idSolicit),
                                                        "idSucursal": parseInt(this.props.props.idSucursal)

                                                    }
                                            }).then(response => {
                                                Swal.fire({
                                                    position: 'center',
                                                    title: "El estatus del promotor se actualizó correctamente. ",
                                                    confirmButtonColor: "#4DA357",
                                                    showCancelButton: false,
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'OK'

                                                }).then(response => {
                                                    console.log(response);
                                                    window.location.reload(false);
                                                });
                                            });
                                        }}>
                                            <option value="1">Capacitación</option>
                                            <option value="2">Certificación</option>
                                            <option value="3">Ingreso</option>
                                        </select>
                                    </div>
                                    <div className='col col-lg-2'
                                         style={{display: vacanteocupada.status_vac === 3 ? "block" : "none"}}>
                                        <input maxLength={15} minLength={3}
                                               id={'inputFcUser' + key}
                                               type="text" className="form-control"
                                               disabled
                                               defaultValue={vacanteocupada.employee_number} placeholder="Ingresa un ID"/>
                                    </div>

                                    <div className="col col-lg-1"
                                         style={{display: vacanteocupada.status_vac === 3 ? "block" : "none"}}>
                                        <i onClick={(e) => {

                                            axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/changeStatusUser", {
                                                'data':
                                                    {
                                                        "userModified": [
                                                            {
                                                                "idStatus": document.getElementById("select-" + key).value,
                                                                "idUser": parseInt(vacanteocupada.id_user),
                                                                "fcUser": document.getElementById("inputFcUser" + key).value
                                                            }
                                                        ],
                                                        "idSolicit": parseInt(this.props.props.idSolicit),
                                                        "idSucursal": parseInt(this.props.props.idSucursal)

                                                    }
                                            }).then(response => {
                                                console.log("RESPONSE FCUSER", response);
                                                Swal.fire({
                                                    position: 'center',
                                                    title: "El número de empleado se asignó correctamente.",
                                                    confirmButtonColor: "#4DA357",
                                                    showCancelButton: false,
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'OK'
                                                });
                                            })
                                        }} id={'checkFcUser' + key} className="fa fa-check checknombres m-over" aria-hidden="true" />
                                    </div>

                                    <div className='col col-lg-1'>
                                        <i onClick={() => {
                                            Swal.fire({
                                                position: 'center',
                                                title: "¿Quieres editar la información de la vacante? ",
                                                confirmButtonColor: "#4DA357",
                                                showCancelButton: true,
                                                showConfirmButton: true,
                                                confirmButtonText: 'Si',
                                                cancelButtonText: 'No'

                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    document.getElementById("form-editar-nom" + key).style.display = "inline"
                                                    document.getElementById("form-editar-ape" + key).style.display = "inline"
                                                    document.getElementById("form-nomape" + key).style.display = "none"
                                                    document.getElementById("select-" + key).disabled = false
                                                    document.getElementById("inputFcUser" + key).disabled = false
                                                    //this.getDetalleSolicituEntrante();
                                                }
                                            });

                                        }} className="fa fa-pencil checknombres m-over" aria-hidden="true" />
                                        <i onClick={() => {
                                            Swal.fire({
                                                position: 'center',
                                                title: "¿Deseas cancelar el proceso de este candidato?",
                                                confirmButtonColor: "#4DA357",
                                                showCancelButton: true,
                                                showConfirmButton: true,
                                                confirmButtonText: 'Si',
                                                cancelButtonText: 'No'

                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/saveUser", {
                                                        data:  {
                                                            userModified: [{
                                                                name: vacanteocupada.nom_use,
                                                                lastName: vacanteocupada.ape_use,
                                                                idUser: vacanteocupada.id_user,
                                                                action: 3 //1 PARA GUARDAR // 2 EDITAR //3 DELETE
                                                            }],
                                                            idSolicit: parseInt(this.props.props.idSolicit)
                                                        }
                                                    }).then(response => {
                                                        window.location.reload(false);
                                                    })
                                                }
                                            });

                                        }} className="fa fa-trash-o checknombres m-over" aria-hidden="true" />
                                    </div>
                                </div>
                            )

                        })}

                    </div>
                </div>
            </div>
        );
    }
}
export default DetalleTomadas;