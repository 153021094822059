import React, {Component} from "react";
import Usuario from "../ComponentesGenerales/Usuario";
import {Link} from "react-router-dom";
import axios from "axios";
import * as environment from "../Global";

class DetalleEntrante extends Component{


    constructor(props) {
        super(props);
        this.state = {
            info: [],
            status: null,
            numVacFijas: 0,
            arrVacFijas: [],
            numVacTemp: 0,
            arrVacTemp: [],
            loader: false
        };
    }

    componentDidMount() {
        this.getDetalleSolicituEntrante();
    }

    getDetalleSolicituEntrante = () => {
        this.setState({ loader: true})
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/solicits/detailSolicit", {
            data:
                {
                    "idSolicit": this.props.props.idSolicit,
                    "idSucursal": this.props.props.idSucursal
                }
        }).then(response => {
            this.setState({
                info: response.data.data.solicits[0],
                status: 'success',
                loader: false
            });

            for (const responseKey of this.state.info.typeVacancies) {
                //if (responseKey.description === "FIJO" && responseKey.total_vacancy > 0){
                if (responseKey.description === "FIJO"){
                    this.setState({
                        numVacFijas: this.state.numVacFijas + responseKey.total,
                    });
                //}else if (responseKey.description === "TEMPORAL" && responseKey.total_vacancy > 0){
                }else if (responseKey.description === "TEMPORAL"){
                    this.setState({
                        numVacTemp: this.state.numVacTemp + responseKey.total
                    });
                }
            }
        });
    }

    render() {
        var arrVacFijas = [];
        var arrVacTemp = [];
        if (this.state.info.typeVacancies != null){
            for (const vacante of this.state.info.typeVacancies) {
                //if (vacante.description === "FIJO" && vacante.total_vacancy > 0){
                if (vacante.description === "FIJO"){
                    for (let i = 0; i < vacante.total; i++) {
                        arrVacFijas.push(vacante);
                    }

                //}else if (vacante.description === "TEMPORAL" && vacante.total_vacancy > 0){
                }else if (vacante.description === "TEMPORAL"){
                    for (let i = 0; i < vacante.total; i++) {
                        arrVacTemp.push(vacante)
                    }
                }
            }
        }

        return (
            this.state.loader ?
                <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
                :
            <div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">

                    </div>
                    <div className="col col-lg-3 offset-lg-2">
                        <Usuario />
                    </div>
                </div>
                <div className="row">
                    <div className="FondoBlanco mt-3 p-5">
                        <div className="row mt-3">
                            <div className="col col-lg-12">
                                <Link to="/solicitudes">
                                    <button type="button" className="btn btn-outline-success btn-rounded btn-icon">
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                    </button>
                                </Link>
                                <b className="verdeTitle">{this.state.info.economicNumber}</b><b className="negroTitle"> - {this.state.info.nameSuc} </b>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col col-lg-10'>
                                <p className="pFade"><b className="pFadeB">Territorio:</b> {this.state.info.territory} <b className="pFadeB">Zona - Región:</b> {this.state.info.zone} / {this.state.info.region} <b className="pFadeB">Dirección:</b> {this.state.info.address} C.P. {this.state.info.cp}</p>
                                <p className="pFade"><b className="pFadeB">Municipio:</b> {this.state.info.municipality} <b className="pFadeB">Estado:</b> {this.state.info.state}</p>
                            </div>
                        </div>
                        <div className='row mt-3 pt-5'>
                            <p><b className="verdeTitle">Nombre del Responsable:</b></p>
                            <p className="pNormal">{this.state.info.name}</p>
                            <p className="pNormal"><b className="bNormal">Fecha de solicitud:</b> {this.state.info.solicit_date}</p>
                            {/*<div className='col col-lg-6'>
                                <p><b className="verdeTitle">Nombre del Responsable:</b></p>
                                <p className="pNormal">{this.state.info.name}</p>
                                <p className="pNormal"><b className="bNormal">Fecha de solicitud:</b> {this.state.info.solicit_date}</p>
                            </div>*/}
                            {/*<div className='col col-lg-7'>
                                <p><b className="verdeTitle">Número de Promotores:</b></p>
                                <p className="pNormal">{this.state.info.promoters}</p>
                            </div>*/}
                            <div className='col col-lg-7'>
                                <p><b className="verdeTitle">Fecha tentativa de ingreso:</b></p>
                                <p className="pNormal">{this.state.info.tentative_date}</p>
                                <p className="pNormal"><b className="bNormal">Vacantes Fijas:</b> {this.state.numVacFijas} </p>
                            </div>
                            {
                                arrVacFijas.map((vacanteFija, index) => {
                                    let hrs_init = "am"
                                    if (Date(vacanteFija.time_init) > Date("12:00")){
                                        hrs_init = "pm";
                                    }
                                    let hrs_end = "am"
                                    if (Date(vacanteFija.time_end) >= Date("12:00")){
                                        hrs_end = "pm";
                                    }
                                    return(
                                        <div className='col col-lg-7' key={index}>
                                            <p className="bGreen"><b className="bNormal">Vacante {index + 1}:</b> </p>
                                            <p className="pNormal"> {vacanteFija.time_init} {hrs_init} - {vacanteFija.time_end} {hrs_end}</p>
                                        </div>                                    );
                                })
                            }

                            <div className='col col-lg-7 pt-5'>
                                <p className="pNormal"><b className="bNormal">Vacantes Temporales:</b> {this.state.numVacTemp} </p>
                            </div>

                            {
                                arrVacTemp.map((vacanteTemporal, index) => {
                                    let semanas = "semanas"
                                    if (vacanteTemporal.week_number === 1){
                                        semanas = "semana";
                                    }
                                    let hrs_init = "am"
                                    if (Date(vacanteTemporal.time_init) > Date("12:00")){
                                        hrs_init = "pm";
                                    }
                                    let hrs_end = "am"
                                    if (Date(vacanteTemporal.time_end) >= Date("12:00")){
                                        hrs_end = "pm";
                                    }
                                    return(
                                        <div className='col col-lg-7' key={index}>
                                            <p className="pNormal"><b className="bNormal">Número de semanas:</b> {vacanteTemporal.week_number } {semanas} </p>
                                            <p className="bGreen"><b className="bNormal">Vacante {index + 1}:</b> </p>
                                            <p className="pNormal"> {vacanteTemporal.time_init} {hrs_init} - {vacanteTemporal.time_end} {hrs_end}</p>
                                        </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetalleEntrante;