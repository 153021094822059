import React from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import * as environment from "../Global";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Usuario from "../ComponentesGenerales/Usuario";


var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() - days);
    return res.setHours(0, 0, 0, 0);
}



const columns = [
    { field: 'idSolicit', headerName: 'ID', width: 10 },
    { field: 'fechaSolicitud', headerName: 'FECHA DE SOLICITUD', width: 125 },
    { field: 'solicitante', headerName: 'SOLICITANTE' },
    { field: 'numPromotor', headerName: 'NÚMERO DE PROMOTOR', width: 140 },
    { field: 'nombrePromotor', headerName: 'NOMBRE DEL PROMOTOR', width: 180 },
    {
        field: 'fechaTentativaIngreso', headerName: 'FECHA TENTATIVA DE INGRESO', width: 170, renderCell: (cellValues) => {
            //console.log("cellValues", cellValues.value);
            //var day = new Date().getDate();
            //var month = new Date().getMonth() + 1;
            //var year = new Date().getFullYear();
            //var dateNow = day + "/" + month + "/" + year;
            //var daten3 = new Date(Date.now()).setDate(3);
            //console.log("date NOW ", new Date(new Date(Date.now())));
            //console.log("date 3 ", new Date(Date.parse(cellValues.value)));
            //console.log(cellValues.value.split("/"))
            var fechaCellValue = cellValues.value.split("/");
            var fechaCellValueCompare = new Date(Date.parse(fechaCellValue[1] + "/" + fechaCellValue[0] + "/" + fechaCellValue[2]));

            //console.log("date CELLVALUE ", fechaCellValueCompare);
            //var dayValue = new Date();
            //var dayValD = dayValue(cellValues.value);
            //console.log("dayValue", dayValue.setDate(3));

            var tmpDate = new Date(); // Today
            //console.log("::::::::::::::" + tmpDate + "     Cell    " + cellValues.value.toDateString()+":::::::::::::::::::::")

            /*if (addDaysToDate(tmpDate, 10) > cellValues.value) {
                console.log("Hoy esta en 3 dias de ser la fecha")
            }*/
            //console.log("::::::::::::::::::::::::::::::::::::::::::::::::::")
            //var prueba1 = fechaCellValueCompare;
            //console.log(fechaCellValueCompare + "_____________" + tmpDate);
            //console.log("1 ", new Date(addDaysToDate(tmpDate, 3))); // today -3
            //console.log("2 ", new Date(addDaysToDate(tmpDate, 2))); // today -3
            //console.log(" 3 ", new Date(addDaysToDate(tmpDate, 1))); // today -3
            //console.log("::::::::::::::::::::::::::::::::::::::::::::::::::")

            //if ( new Date(tmpDate).getTime() > fechaCellValueCompare.getTime() ){
            //  console.log("*************************" +fechaCellValueCompare + "es mayor aaa " + tmpDate +"**************");
            //return (
            //  <i className="fa fa-exclamation-triangle color-red m-over" aria-hidden="true" ><label className="text-label"> {cellValues.row.fechaTentativaIngreso}</label> </i>
            //);
            //}


            if (((new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 1)).getTime()) ||
                (new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 0)).getTime())) &&
                (cellValues.row.numPromotor == null)) {
                console.log(cellValues.row.status)
                return (
                    <Tooltip title="Esta vacante aún no tiene un candidato que haya finalizado su proceso de ingreso.">
                        <i className="fa fa-exclamation-triangle color-warning m-over" aria-hidden="true" ><label className="text-label"> {cellValues.row.fechaTentativaIngreso}</label> </i>
                    </Tooltip>
                );

            } else if (fechaCellValueCompare.getTime() < new Date(addDaysToDate(tmpDate, 0)).getTime() && cellValues.row.numPromotor == null) {
                //console.log("LA FECHA " + fechaCellValueCompare + " Es MENOR  a la fecha " + tmpDate);
                return (
                    <Tooltip title="Vacante no finalizada a tiempo.">
                        <i className="fa fa-exclamation-triangle color-red m-over" aria-hidden="true">
                            <label className="text-label"> {cellValues.row.fechaTentativaIngreso}</label>
                        </i>
                    </Tooltip>
                );
            } else {
                return (
                    <i className="fa fa-long-arrow-right color-white    " aria-hidden="true" ><label className="text-label"> {cellValues.row.fechaTentativaIngreso}</label> </i>
                );
            }
        }
    },
    {
        field: 'estatus', headerName: 'ESTATUS', width: 150, renderCell: (cellValues) => {
            var btnColor = "";
            if (cellValues.row.status === "Capacitación") {
                return (<button onClick={() => { window.location.href = "/vacantes/historialvacante/" + cellValues.row.idUser + "/" }} className="btn-table btn-orange" variant="contained">{cellValues.row.status}</button>);

            } else if (cellValues.row.status === "Solicitud") {
                return (<button onClick={() => { window.location.href = "/vacantes/historialvacante/" + cellValues.row.idUser + "/" }} className="btn-table btn-purple" variant="contained">{cellValues.row.status}</button>);

            } else if (cellValues.row.status === "En reclutamiento") {
                return (<button onClick={() => { window.location.href = "/vacantes/historialvacante/" + cellValues.row.idUser + "/" }} className="btn-table btn-blue-ba" variant="contained">{cellValues.row.status}</button>);

            } else if (cellValues.row.status === "Ingreso") {
                return (<button onClick={() => { window.location.href = "/vacantes/historialvacante/" + cellValues.row.idUser + "/" }} className="btn-table btn-gray" variant="contained">{cellValues.row.status}</button>);

            } else {
                return (<button onClick={() => { window.location.href = "/vacantes/historialvacante/" + cellValues.row.idUser + "/" }} className="btn-table" variant="contained">{cellValues.row.status}</button>);
            }
        }
    },
    {
        field: 'detail', headerName: '', width: 10, renderCell: (cellValues) => {
            return (<i onClick={() => { window.location.href = "/vacantes/historialvacante/" + cellValues.row.idUser + "/" }} className="fa fa-long-arrow-right color-green-ba m-over" aria-hidden="true" />);
        },
    }
];

class DetalleSucursal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cp_suc: 0,
            direccion_suc: "",
            estado: "",
            idSucursal: 0,
            municipio: "",
            nameSucursal: "",
            region: "",
            territorio: "",
            zona: "",
            dataTable: [],
            loader: false
        };
    }


    componentDidMount() {
        this.getDetailSucursal();
    }

    getDetailSucursal = () => {
        this.setState({ loader: true })
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/solicits/detailSucursal", {
            data:
            {
                idSucursal: parseInt(this.props.props.idSucursal)
            }
        }).then(response => {

            console.log("Response detailSucursal: ", response.data.data.sucursalData[0].dataTable)
            this.setState({
                cp_suc: response.data.data.sucursalData[0].cp,
                direccion_suc: response.data.data.sucursalData[0].address,
                estado: response.data.data.sucursalData[0].state,
                idSucursal: response.data.data.sucursalData[0].idSucursal,
                municipio: response.data.data.sucursalData[0].municipality,
                nameSucursal: response.data.data.sucursalData[0].nameSuc,
                region: response.data.data.sucursalData[0].region,
                territorio: response.data.data.sucursalData[0].territory,
                zona: response.data.data.sucursalData[0].zone,
                dataTable: response.data.data.sucursalData[0].dataTable,
                loader: false
            });
        });
    }

    iniciarBuscador = (e) => {
        console.log("buscando... " + e.target.value);
    }

    render() {
        var rows = [];

        /*const handleChange = e => {
            this.iniciarBuscador(e.target.value);
        };*/

        if (this.state.dataTable.length > 0) {
            for (let i = 0; i < this.state.dataTable.length; i++) {
                var element = {
                    id: i + 1,
                    idSolicit: this.state.dataTable[i].idSolicit,
                    fechaSolicitud: this.state.dataTable[i].solicit_date,
                    solicitante: this.state.dataTable[i].applicant,
                    numPromotor: this.state.dataTable[i].number_promoter,
                    nombrePromotor: this.state.dataTable[i].name_promoter,
                    fechaTentativaIngreso: this.state.dataTable[i].tentative_date,
                    idUser: this.state.dataTable[i].idUser,
                    //fechaTentativaIngreso: "21/03/22",
                    //fechaTentativaIngreso: "26/03/22",
                    status: this.state.dataTable[i].status
                }
                rows.push(element);
            }
        }


        return (
            this.state.loader ?
                <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
                :
                <div>
                    <div className="row">
                        <div className="col col-lg-7">
                            <h2>Vacantes</h2>
                        </div>

                        <div className="col col-lg-1 offset-lg-4">
                            <Usuario />
                        </div>
                    </div>
                    <div className="row">
                        <div className="FondoBlanco mt-3 p-5">
                            <div className="row mt-3">
                                <div>
                                    <div className="row">
                                        <div className="col col-lg-6">
                                            <Link to="/vacantes">
                                                <button type="button" className="btn btn-outline-success btn-rounded btn-icon">
                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                                </button>
                                            </Link>
                                            <b className="verdeTitle">{this.props.props.fcSucursal}</b><b className="negroTitle"> - {this.state.nameSucursal} </b>
                                        </div>
                                        <div className="col col-lg-4 offset-lg-2 div-btns">{/*
                                        <button onClick={this.volverMostar} id="btnDescargar" type="button" className="btn btn-outline-ba-active">
                                            Crear Solicitud
                                        </button>*/}
                                            <Link to="/vacantes/nueva"><button id="bntCrearSolicitud" className="btn btn-success" >Crear Solicitud</button></Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col col-lg-12">
                                    <p className="pNormal"><b className="pNormalB">Territorio:</b> {this.state.territorio} <b className="pNormalB">Zona - Región:</b> {this.state.zona} / {this.state.region} <b className="pNormalB">Dirección:</b> {this.state.direccion_suc} C.P. {this.state.cp_suc}</p>
                                    <p className="pNormal"><b className="pNormalB">Municipio:</b> {this.state.municipio} <b className="pNormalB">Estado:</b> {this.state.estado}</p>
                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col col-lg-8">
                                    <div className="btn-group" role="group">
                                        <Link to={"/vacantes/promotores/"+this.props.props.fcSucursal+"/"+this.props.props.idSucursal}><button type="button" className="btn btn-left-ba btn-outline-ba barra">Promotores</button></Link>
                                        <Link to={"/vacantes/detalleSucursal/"+this.props.props.fcSucursal+"/"+this.props.props.idSucursal}><button type="button" className="btn btn-outline-ba btn-outline-ba-active barra">Vacantes</button></Link>
                                        <Link to={"/vacantes/bajas/"+this.props.props.fcSucursal+"/"+this.props.props.idSucursal}><button type="button" className="btn btn-right-ba btn-outline-ba barra">Bajas</button></Link>
                                        {/*<Link to={"/vacantes/incidentes/"+this.props.match.params.fcSucursal+"/"+this.props.match.params.idSucursal}><button type="button" className="btn btn-right-ba btn-outline-ba barra">Incidentes</button></Link>*/}
                                    </div>
                                </div>
                                
                            </div>
                            <div style={{ height: 'auto', width: '100%', padding: '0 55px' }}>
                                <DataGrid
                                    autoHeight={true}
                                    disableSelectionOnClick
                                    rows={rows}
                                    columns={columns}
                                    pageSize={25}
                                    rowsPerPageOptions={[25]}
                                    rowBuffer={25}
                                    components={{
                                        NoRowsOverlay: () => (
                                            <Stack height="100%" alignItems="center" justifyContent="center">
                                                No se encontraron resultados
                                            </Stack>
                                        ),
                                        NoResultsOverlay: () => (
                                            <Stack height="100%" alignItems="center" justifyContent="center">
                                                No se encontraron resultados que coincidan con tu búsqueda
                                            </Stack>
                                        )
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>

        );
    }
}
export default DetalleSucursal;