import React from "react";
import logo from './Assets/img/bancoaztecalogo.png'

const Error = () => {
    return (
        <section className='text-center mt-5'>
            <h1>La ruta a la que deseas acceder no existe, favor de verificarla.</h1>
            <img className='mt-5' src={logo} alt="logo" />
        </section>
    );
}

export default Error;