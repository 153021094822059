import React from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import * as environment from "../Global";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Usuario from "../ComponentesGenerales/Usuario";


var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() - days);
    return res.setHours(0, 0, 0, 0);
}



const columns = [
    { field: 'idSolicitud', headerName: 'ID DE SOLICITUD', width: 105 },
    { field: 'fechaCreacion', headerName: 'FECHA DE SOLICITUD', width: 130 },
    { field: 'responsable', headerName: 'SOLICITANTE', width: 180 },
    { field: 'numBajas', headerName: 'NÚMERO DE BAJAS', width: 140 },
    { field: 'fechaTentativa', headerName: 'FECHA DE BAJA', width: 135 },
    {
        field: 'estatusSolicitud', headerName: 'ESTATUS', width: 160, renderCell: (cellValues) => {
            var btnColor = "";
            if (cellValues.row.estatusSolicitud === "POR CONFIRMAR") {
                return (<button className="btn-table btn-orange botonlista" variant="contained">Por confirmar</button>);

            } else if(cellValues.row.estatusSolicitud === "CONFIRMADA") {
                return (<button className="btn-table btn-gray botonlista" variant="contained">Confirmada</button>);
            } 
        }
    }
];

class VacantesBajas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cp_suc: 0,
            direccion_suc: "",
            estado: "",
            idSucursal: 0,
            municipio: "",
            nameSucursal: "",
            region: "",
            territorio: "",
            zona: "",
            dataTable: [],
            loader: false
        };
    }


    componentDidMount() {
        this.getDetailSucursal();
    }

    getDetailSucursal = () => {
        this.setState({ loader: true })
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/solicits/detailSucursal", {
            data:
            {
                idSucursal: parseInt(this.props.props.idSucursal)
            }
        }).then(response => {

            console.log("Response detailSucursal: ", response.data.data.sucursalData[0].dataTable)
            this.setState({
                cp_suc: response.data.data.sucursalData[0].cp,
                direccion_suc: response.data.data.sucursalData[0].address,
                estado: response.data.data.sucursalData[0].state,
                idSucursal: response.data.data.sucursalData[0].idSucursal,
                municipio: response.data.data.sucursalData[0].municipality,
                nameSucursal: response.data.data.sucursalData[0].nameSuc,
                region: response.data.data.sucursalData[0].region,
                territorio: response.data.data.sucursalData[0].territory,
                zona: response.data.data.sucursalData[0].zone

            });
        });
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/loss-promoters/listaBajas", {
            "data":
            {
                id_sucursal: parseInt(this.props.props.idSucursal)
            }
        }).then(response => {

            console.log("Response ListSolicitudBajas: ", response.data.data.ListSolicitudBajas)
            this.setState({
                dataTable: response.data.data.ListSolicitudBajas,
                loader: false
            });
        });
    }

    iniciarBuscador = (e) => {
        console.log("buscando... " + e.target.value);
    }

    render() {
        var rows = [];

        /*const handleChange = e => {
            this.iniciarBuscador(e.target.value);
        };*/

        if (this.state.dataTable.length > 0) {
            for (let i = 0; i < this.state.dataTable.length; i++) {
                var element = {
                    idSolicitud: this.state.dataTable[i].idSolicitud,
                    fechaCreacion: this.state.dataTable[i].fechaCreacion,
                    responsable: this.state.dataTable[i].reponsable,
                    numBajas: this.state.dataTable[i].numBajas,
                    fechaTentativa: this.state.dataTable[i].fechaTentativa,
                    estatusSolicitud: this.state.dataTable[i].estatusSolicitud
                }
                rows.push(element);
            }
        }


        return (
            this.state.loader ?
                <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
                :
                <div>
                    <div className="row">
                        <div className="col col-lg-7">
                            <h2>Bajas</h2>
                        </div>
                        <div className="col col-lg-1 offset-lg-4">
                            <Usuario />
                        </div>
                    </div>
                    <div className="row">
                        <div className="FondoBlanco mt-3 p-5">
                            <div className="row mt-3">
                                <div>
                                    <div className="row">
                                        <div className="col col-lg-6">
                                            <Link to="/vacantes">
                                                <button type="button" className="btn btn-outline-success btn-rounded btn-icon">
                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                                </button>
                                            </Link>
                                            <b className="verdeTitle">{this.props.props.fcSucursal}</b><b className="negroTitle"> - {this.state.nameSucursal} </b>
                                        </div>
                                        <div className="col col-lg-4 offset-lg-2 div-btns">{/*
                                        <button onClick={this.volverMostar} id="btnDescargar" type="button" className="btn btn-outline-ba-active">
                                            Crear Solicitud
                                        </button>*/}
                                            <Link to="/vacantes/nueva"><button id="bntCrearSolicitud" className="btn btn-success" >Crear Solicitud</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col col-lg-12">
                                    <p className="pNormal"><b className="pNormalB">Territorio:</b> {this.state.territorio} <b className="pNormalB">Zona - Región:</b> {this.state.zona} / {this.state.region} <b className="pNormalB">Dirección:</b> {this.state.direccion_suc} C.P. {this.state.cp_suc}</p>
                                    <p className="pNormal"><b className="pNormalB">Municipio:</b> {this.state.municipio} <b className="pNormalB">Estado:</b> {this.state.estado}</p>
                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col col-lg-8">
                                    <div className="btn-group" role="group">
                                        <Link to={"/vacantes/promotores/" + this.props.props.fcSucursal + "/" + this.props.props.idSucursal}><button type="button" className="btn btn-left-ba btn-outline-ba barra">Promotores</button></Link>
                                        <Link to={"/vacantes/detalleSucursal/" + this.props.props.fcSucursal + "/" + this.props.props.idSucursal}><button type="button" className="btn btn-outline-ba btn-outline-ba barra">Vacantes</button></Link>
                                        <Link to={"/vacantes/bajas/" + this.props.props.fcSucursal + "/" + this.props.props.idSucursal}><button type="button" className="btn btn-right-ba btn-outline-ba btn-outline-ba-active barra">Bajas</button></Link>
                                        {/*<Link to={"/vacantes/incidentes/"+this.props.match.params.fcSucursal+"/"+this.props.match.params.idSucursal}><button type="button" className="btn btn-right-ba btn-outline-ba barra">Incidentes</button></Link>*/}
                                    </div>
                                </div>

                            </div>
                            <div style={{ height: 'auto', width: '100%', padding: '0 55px' }}>
                                <DataGrid
                                    autoHeight={true}
                                    getRowId={(row) => row.idSolicitud}
                                    disableSelectionOnClick
                                    rows={rows}
                                    columns={columns}
                                    pageSize={25}
                                    rowsPerPageOptions={[25]}
                                    rowBuffer={25}
                                    components={{
                                        NoRowsOverlay: () => (
                                            <Stack height="100%" alignItems="center" justifyContent="center">
                                                No se encontraron resultados
                                            </Stack>
                                        ),
                                        NoResultsOverlay: () => (
                                            <Stack height="100%" alignItems="center" justifyContent="center">
                                                No se encontraron resultados que coincidan con tu búsqueda
                                            </Stack>
                                        )
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>

        );
    }
}
export default VacantesBajas;