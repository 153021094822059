import React from "react";
import { useRef } from 'react'
import { useState } from "react";
import logo from "../Assets/img/bancoaztecalogo.png";
import bcrypt from 'bcryptjs';
import axios from "axios";
import * as environment from "../Global";
import PortalVacantesContainer from "../PortalVacantesContainer";
import Router from "../Router";
import ReactDOM from "react-dom";
import {Redirect} from "react-router-dom";

const salt = bcrypt.genSaltSync(10);

function Login() {
    const [labelError, setLabelError] = useState(false);
    const [loader, setLoader] = useState(false);
    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const [passwordShown, setPasswordShown] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    function handleLoginForm() {
        setLoader(true);
        const email = emailInputRef.current.value;
        const password = passwordInputRef.current.value;

        //const hashedEmail = bcrypt.hashSync(email, salt);
        //const hashedPassword = bcrypt.hashSync(password, salt); // hash created previously created upon sign up
        /*fetch('https://api.sampleapis.com/beers/ale', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: hashedPassword,
          }),
        })*/
        //console.log("Enviamos el email " + email);
        //console.log("Enviamos el password " + password);
        if(email == "" || password == ""){
            setLabelError(true);
            setLoader(false);
        }
        else{
            axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/user-admin/login", {
                data: {
                    "userMail": email,
                    "userPass": password
                }

            }).then(response => {
                setLoader(false);
                console.log("Response login", response.data.data);
                if (response.data.data.error){
                    setLabelError(true);
                    setLoader(false);
                } else if (response.data.data[0]){
                    localStorage.setItem("username", response.data.data[0].userName);
                    localStorage.setItem("lastname", response.data.data[0].lastNameP);
                    setLabelError(false);
                    if (response.data.data[0].typeUser === "OPERADOR"){
                        console.log("Entro a operador");
                        window.location.href = "/solicitudes";
                        
                    }else{
                        window.location.href = "/vacantes"
                        
                    }
                    setLoader(false);
                }
    
            });
        }
        

    }

    return (
        <div className="container-fluid">
            <div className="row" >
                <div className="col-8 fondoLogin"></div>
                <div className="col-4 fondoLoginForm">
                    <form className="loginContainer">
                        <div className="mb-5">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="mb-3">
                            <p><b>¡Hola! Bienvenido</b></p>
                        </div>
                        <div className="mb-3">
                            <label  className="form-label-login">Usuario</label>
                            <input ref={emailInputRef} placeholder="Email" type="email" className="form-control bg-search-login" id="exampleInputEmail1" aria-describedby="emailHelp" />

                        </div>
                        <div className="mb-5">
                            <label  className="form-label-login">Password</label>
                            <div className="input-group">
                                <input ref={passwordInputRef} type={passwordShown ? "text" : "password"} placeholder="Contraseña" className="form-control bg-search-login" aria-label="Dollar amount (with dot and two decimal places)" />
                                <span className="input-group-text bg-search-login"><i className="fa fa-eye" onClick={togglePassword} aria-hidden="true" id="eyepassword"></i></span>
                            </div>
                            {labelError ? <label className="lblError">Correo o contraseña son incorrectos, favor de verificar.</label> : <label></label> }
                        </div>
                        {loader ?
                            <div className="margin-loader">
                                <i className="fa fa-spinner fa-pulse color-green-ba fa-3x fa-fw" />
                                <span className="sr-only">Loading...</span>
                            </div>
                        :<label></label>
                        }
                        <div className="d-grid gap-2 pt-5">
                            <button onClick={e => {
                                e.preventDefault()
                                handleLoginForm()
                            }} type="button" className="btn btn-ba-login">Entrar</button>
                            <hr className="hr-login"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default Login;