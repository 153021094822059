import './Entrantes.css';
import excel_logo from '../Assets/img/excel_icon.png'
import * as environment from "../Global";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import Usuario from "../ComponentesGenerales/Usuario";
import Swal from 'sweetalert2';
import {Stack, Tooltip} from "@mui/material";
import { ExportCSV } from '../ComponentesGenerales/ExportCSV';

/*const handleCellClick = (param, event) => {
    Swal.fire({
        position: 'center',
        title: "¿Quieres comenzar el reclutamiento de las vacantes de esta solicitud?",
        cancelButtonColor: "#4DA357",
        confirmButtonColor: "#A0AEC0",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'No',
        cancelButtonText: 'Si'
    })
};*/

/*const handleRowClick = (param, event) => {
    event.stopPropagation();
};*/
var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() - days);
    return res.setHours(0,0,0,0);
}




const fileName = "Solicitudes Entrantes";
const columns = [
    { field: 'folio', headerName: 'FOLIO' },
    { field: 'idSucursal', headerName: 'ID SUCURSAL' },
    { field: 'nombreSucursal', headerName: 'NOMBRE SUCURSAL', width: 250 },
    { field: 'numVacantes', headerName: '# DE VACANTES' },
    {
        field: 'fechaIngreso', headerName: 'FECHA TENTATIVA DE INGRESO', width: 160, renderCell: (cellValues) => {
            var fechaCellValue = cellValues.value.split("-");
            var fechaCellValueCompare = new Date(Date.parse(fechaCellValue[1] + "/" + fechaCellValue[0] + "/" + fechaCellValue[2]));
            var tmpDate = new Date(); // Today
            if ((new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 3)).getTime()) ||
                (new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 2)).getTime()) ||
                (new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 1)).getTime()) ||
                (new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 0)).getTime())) {

                return (
                    <Tooltip title="La fecha tentativa de ingreso está próxima a vencerse, toma esta solicitud a la brevedad.">
                        <i className="fa fa-exclamation-triangle color-warning m-over" aria-hidden="true">
                            <label className="text-label"> {cellValues.row.fechaIngreso}</label> </i>
                    </Tooltip>
                );

            } else if (fechaCellValueCompare.getTime() < new Date(addDaysToDate(tmpDate, 0)).getTime()) {
                //console.log("LA FECHA " + fechaCellValueCompare + " Es MENOR  a la fecha " + tmpDate);
                return (
                    <i className="fa fa-exclamation-triangle color-red m-over" aria-hidden="true"><label
                        className="text-label"> {cellValues.row.fechaIngreso}</label> </i>
                );
            }else {
                return (
                    <i className="fa fa-long-arrow-right color-white    " aria-hidden="true" ><label className="text-label"> {cellValues.row.fechaIngreso}</label> </i>
                );
            }
        }
    },
    {
        field: 'tomarSolicitud', headerName: '', width: 180, renderCell: (cellValues) => {
            return (
                <button
                    className="btn-table"
                    variant="contained"
                    color="success"
                    onClick={(event) => {
                        Swal.fire({
                            position: 'center',
                            title: "¿Quieres comenzar el reclutamiento de las vacantes de esta solicitud?",
                            cancelButtonColor: "#4DA357",
                            confirmButtonColor: "#A0AEC0",
                            showCancelButton: true,
                            showConfirmButton: true,
                            confirmButtonText: 'No',
                            cancelButtonText: 'Si'
                        }).then((result) => {
                            if (result.isConfirmed) {

                            }
                            else {
                                axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/updSolicit", {
                                    data: {
                                        "idSolicit": cellValues.row.idSolicit
                                    }
                                }).then(response => {
                                    console.log("Detalle tomada", response);
                                    window.location.href = "/solicitudes/detalleTomadas/" + cellValues.row.idSolicit + "/" + cellValues.row.idSuc;
                                });
                            }
                        })

                    }}
                >
                    Tomar solicitud
                </button>
            );
        }
    },
    {
        field: 'detail', headerName: '', renderCell: (cellValues) => {
            return (
                <i className="fa fa-eye m-over"
                    onClick={(event) => {
                        //handleCellClick(event, cellValues);
                        window.location.href = "/solicitudes/detalleEntrante/" + cellValues.row.idSolicit + "/" + cellValues.row.idSuc;
                    }} aria-hidden="true" />
            );
        },
    }
];

var digitos = 5;
var ceros_izq = (n, d) => { var e = 10 ** d; return n < e ? ((n + e) + "").slice(1) : n + "" }



class Entrantes extends Component {

    state = {
        solicitudesEntrantes: [],
        status: null,
        descargaExcel: [],
        loader: false,
        buscador: ''
    };
    
    excelEntrantes = () => {
        console.log("Entro a excel entrantes");
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/solicits/descargaExcel", {
            "data":
            {
                "idStatus": 1
    
            }
        }).then(response => {
            console.log("Respuesta excel");
            console.log(response.data.data);
            var arrayXLS = [];
            response.data.data.map((respuesta,index)=>{
                var insertXLS = {
                "Folio de la solicitud": respuesta.idSolicitud,
                "Estado de la solicitud": "Entrante",
                "ID de la sucursal": respuesta.idSucursal,
                "Nombre de la sucursal": respuesta.sucursalName,
                "Dirección de la sucursal": respuesta.addressSuc,
                "Nombre del responsable": respuesta.respName,
                "ID Vacante": respuesta.idusuarioVacantes,
                "Tipo de vacante": respuesta.tipoVacante,
                "Horario": respuesta.idHorario,
                "Temporalidad": respuesta.numeroSemanas,
                "Fecha tentativa de ingreso": respuesta.fechaTentativa,
                "Nombre del candidato": respuesta.userName,
                "Estatus del candidato": respuesta.idStatusUser
                }
                arrayXLS.push(insertXLS);
            });
            this.setState({
                descargaExcel: arrayXLS
            });
        });
    }

    componentDidMount() {
        this.getSolicitudesEntrantes();
    }

    getSolicitudesEntrantes = () => {
        this.setState({loader: true})
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/solicits/listSolicits", {
            data:
            {
                "pageNumber": 1,
                "pageSize": 1000

            }
        }).then(response => {
            console.log("data", response.data.data)
            this.setState({
                solicitudesEntrantes: response.data.data,
                status: 'success',
                loader: false
            });
            this.excelEntrantes();
        });
    }


    iniciarBuscador = (value) => {
        this.setState({...this.state, buscador: value});
        var entrantes = this.state.solicitudesEntrantes;

        if (value === "") {
            this.getSolicitudesEntrantes();
        } else {
            var filtro = entrantes.filter((entrante) => entrante['sucursalName'].includes(value.toUpperCase()));

            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => entrante['fcSucursal'].includes(value.toUpperCase()));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => entrante['fechaTentativa'].includes(value));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => ceros_izq(entrante['idSolicitud'], digitos).includes(value));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => (entrante['numVacnates'].toString()).includes(value));
            }
            this.setState({ solicitudesEntrantes: filtro });
        }

    }

    render() {
        var rows = [];

        const handleChange = e => {
            this.iniciarBuscador(e.target.value);
        };

        if (this.state.solicitudesEntrantes.length > 0) {
            for (let i = 0; i < this.state.solicitudesEntrantes.length; i++) {
                var element = {
                    id: i + 1,
                    folio: ceros_izq(this.state.solicitudesEntrantes[i].idSolicitud, digitos),
                    idSucursal: this.state.solicitudesEntrantes[i].fcSucursal,
                    nombreSucursal: this.state.solicitudesEntrantes[i].sucursalName,
                    numVacantes: this.state.solicitudesEntrantes[i].numVacnates,
                    fechaIngreso: this.state.solicitudesEntrantes[i].fechaTentativa,
                    idSuc: this.state.solicitudesEntrantes[i].idSucursal,
                    idSolicit: this.state.solicitudesEntrantes[i].idSolicitud
                }
                rows.push(element);
            }
        }
        return (
            <div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">
                        <div className="input-group mb-3">
                            <input type="text"
                                className="form-control bg-search"
                                placeholder="&#xf002; Buscar"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-1 offset-lg-4">
                        <Usuario />
                    </div>
                </div>
                <div className="FondoBlanco row mt-5">
                    <div className="row mt-5 p-3 mb-5" style={{height: "fit-content"}}>
                        <div className="col-10 btn-group btn-content-ba" role="group">
                            <Link to="/solicitudes"><button type="button" className="btn btn-left-ba btn-outline-ba btn-outline-ba-active">Solicitudes entrantes</button></Link>
                            <Link to="/solicitudes/tomadas"><button type="button" className="btn btn-outline-ba">Solicitudes tomadas</button></Link>
                            <Link to="/solicitudes/completas"><button type="button" className="btn btn-outline-ba">Solicitudes completas</button></Link>
                            <Link to="/solicitudes/finalizadas"><button type="button" className="btn btn-right-ba btn-outline-ba">Solicitudes finalizadas</button></Link>
                        </div>
                        <div className="col-2 div-btns-ent mt-5">
                            <button type="button" className="btn-olg-ba" onClick={this.getSolicitudesEntrantes}>
                                <i className="fa fa-repeat" aria-hidden="true"></i> Actualizar
                            </button>

                            {/*<img className="excel-icon" src={excel_logo} alt="excel icon" />*/}
                            <ExportCSV type={1} buscador={this.state.buscador} csvData={this.state.descargaExcel} fileName={fileName} />
                        </div>
                    </div>
                    <div className="col col-lg-12" style={{display: this.state.loader ? "none" : "block"}}>
                        <div style={{ height: 'auto', width: '100%', padding: '0 55px' }}>
                        <DataGrid
                            autoHeight={true}
                            disableSelectionOnClick
                            rows={rows}
                            columns={columns}
                            pageSize={25}
                            rowsPerPageOptions={[25]}
                            //loading={true}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                ),/*
                                      LoadingOverlay: () => (
                                          <Spinner animation={true} />
                                      )*/
                            }} />

                    </div>
                    </div>
                    <div className="col col-lg-12" style={{display: this.state.loader ? "block" : "none"}}>
                        <div className="margin-loader">
                            <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Entrantes;
