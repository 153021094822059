import '../Solicitudes/Entrantes.css';
import excel_logo from '../Assets/img/excel_icon.png'
import * as environment from "../Global";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import Usuario from "../ComponentesGenerales/Usuario";
import Swal from 'sweetalert2';
import {Button, Modal, Stack, Tooltip} from "@mui/material";
import { ExportCSV } from '../ComponentesGenerales/ExportCSV';
import Popup from "reactjs-popup";
import {render} from "react-dom";
import * as ReactDOM from "react-dom";
import {forEach} from "react-bootstrap/ElementChildren";

/*const handleCellClick = (param, event) => {
    Swal.fire({
        position: 'center',
        title: "¿Quieres comenzar el reclutamiento de las vacantes de esta solicitud?",
        cancelButtonColor: "#4DA357",
        confirmButtonColor: "#A0AEC0",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'No',
        cancelButtonText: 'Si'
    })
};*/

/*const handleRowClick = (param, event) => {
    event.stopPropagation();
};*/
var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() - days);
    return res.setHours(0,0,0,0);
}

let loaderModal = false;


const fileName = "Solicitudes Entrantes";
const columns = [
    { field: 'folio', headerName: 'FOLIO' },
    { field: 'idSucursal', headerName: 'ID SUCURSAL' },
    { field: 'nombreSucursal', headerName: 'NOMBRE SUCURSAL', width: 250 },
    { field: 'numBajas', headerName: 'NÚMEROS DE BAJAS', width: 160 },
    /*{
        field: 'detail', headerName: '', renderCell: (cellValues) => {
            return (
                <button type="button" onClick={this.showModal}>
                Open
            </button>)
        }
    },*/
    { field: 'fechaTentativa', headerName: 'FECHA BAJA', width: 160 },
    {
        field: 'detail', headerName: '', renderCell: (cellValues) => {
            return (
                <i className="fa fa-eye m-over"
                    onClick={(event) => {
                        Swal.showLoading();
                        console.log("EVENTOOOOO",cellValues.row);

                        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/confirm-low/getPromoters", {
                            data:
                                {
                                    "idSucursal": cellValues.row.sucursalid
                                }
                        }).then(response => {
                            if (response.data.data.promoters.length != 0){
                                // console.log("response promotores", response.data.data.promoters);
                                let checkNames = "";
                                let htmlText ="<table><thead>" +
                                    "                <tr>" +
                                    "                    <th scope='col'>ID</th>" +
                                    "                    <th scope='col'>NOMBRE</th>" +
                                    "                    <th scope='col'>SELECCIONAR</th>" +
                                    "                </tr>" +
                                    "            </thead><tbody>";

                                for (const responseKey of response.data.data.promoters) {
                                    console.log("responseKey",responseKey.name);
                                    htmlText += "<tr><td>"+responseKey.employeeNumber+"</td>" +
                                        "<td><label>" + responseKey.name +"</label></td>" +
                                        "<td><input class='test' type='checkbox' id='a"+ responseKey.userId+"' value='"+ responseKey.userId+"'></td></tr>";
                                }
                                htmlText+= "</tbody></table>";

                                var arrChecks = [];
                                var arrNameChecks = [];
                                var count = 0;

                                const disbaledPromotors = (checkboxes) => {
                                    for (const checkbox of checkboxes) {
                                        if (!checkbox.checked) {
                                            checkbox.disabled = true;
                                        }
                                    }
                                }

                                Swal.fire({
                                    title: 'Promotores',
                                    html: htmlText,
                                    confirmButtonText: 'Dar de baja',
                                    showCancelButton: false,
                                    cancelButtonText: 'Cancelar',
                                    preConfirm: (resultPreconfirm) => {
                                        for (const responseKey of response.data.data.promoters) {
                                            if (Swal.getPopup().querySelector('#a' + responseKey.userId).checked){
                                                count++;
                                                //console.log("Id select", responseKey.userId)
                                                arrNameChecks += "<label>"+responseKey.name+"</label><br>";
                                                arrChecks.push({"idUser": responseKey.userId});
                                                console.log("resultado prevonfitm", Swal.getPopup().querySelector('#a' + responseKey.userId).checked);
                                            }
                                        }
                                    },
                                    didOpen: (popup) => {
                                        Swal.disableButtons();
                                        let checkboxes = [];
                                        for (const responseKey of response.data.data.promoters) {
                                            checkboxes.push(document.getElementById('a' + responseKey.userId));
                                        }
                                        let promotorsSelected = []
                                        for (const promotor of checkboxes) {
                                            promotor.addEventListener('change', () => {
                                                promotorsSelected = Array.from(checkboxes) // Convert checkboxes to an array to use filter and map.
                                                        .filter(i => i.checked) // Use Array.filter to remove unchecked checkboxes.
                                                        .map(i => i.value) // Use Array.map to extract only the checkbox values from the array of objects.

                                                if (promotorsSelected.length === cellValues.row.numBajas) {
                                                    disbaledPromotors(checkboxes);
                                                } else {
                                                    for (const c of checkboxes) {
                                                        c.disabled = false;
                                                    }
                                                }

                                                if (promotorsSelected.length > 0) {
                                                    Swal.enableButtons();
                                                } else {
                                                    Swal.disableButtons();
                                                }
                                            });
                                        }
                                    }
                                }).then((result) => {
                                    console.log("Nuum bajas ", count)
                                    console.log("Nuum bajas cell ", cellValues.row.numBajas)
                                    if (result.isConfirmed){
                                        if (count == cellValues.row.numBajas){
                                            Swal.fire({
                                                title: "¿Quieres dar de baja a estos promotores?",
                                                html: arrNameChecks,
                                                showCancelButton: true,
                                                cancelButtonText: "No",
                                                confirmButtonText: "Si"
                                            }).then((resultConfirm) => {
                                                if (resultConfirm.isConfirmed){
                                                    arrChecks.map((check) => {
                                                        console.log({"idUser": check.value});
                                                    })
                                                    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + "/confirm-low/removePromoters", {
                                                        data:
                                                            {
                                                                "promoters" : arrChecks,
                                                                "idSolicit": cellValues.row.idSolicitud
                                                            }
                                                    }).then(response => {
                                                        window.location.href = "/bajapromotores/confirmadas/";
                                                        console.log("Response remove", response)
                                                    });
                                                }
                                            });
                                        }else{
                                            Swal.fire({
                                                position: 'top',
                                                icon: "error",
                                                text: "El número de promotores seleccionados debe ser igual al número de bajas de la solicitud.",
                                                timer: 4000,
                                                confirmButtonText: "Ok"
                                            });
                                        }
                                    }
                                });
                            } else {
                                Swal.fire({
                                    position: 'top',
                                    icon: "error",
                                    text: "No se encontraron promotores activos, favor de verificar.",
                                    timer: 4000,
                                    confirmButtonText: "Ok"
                                });
                            }

                        });


                    }} aria-hidden="true" />
            );
        },
    }
];

var digitos = 5;
var ceros_izq = (n, d) => { var e = 10 ** d; return n < e ? ((n + e) + "").slice(1) : n + "" }



class BajaPromotoresEntrantes extends Component {

    state = {
        solicitudesEntrantes: [],
        status: null,
        descargaExcel: [],
        loader: false,
        buscador: '',
        modalIsOpen: false,
        showModal: false
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };


    excelEntrantes = () => {
        console.log("Entro a excel entrantes");
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/solicits/descargaExcel", {
            "data":
            {
                "idStatus": 1

            }
        }).then(response => {
            console.log("Respuesta excel");
            console.log(response.data.data);
            var arrayXLS = [];
            response.data.data.map((respuesta,index)=>{
                var insertXLS = {
                "Folio de la solicitud": respuesta.idSolicitud,
                "Estado de la solicitud": "Entrante",
                "ID de la sucursal": respuesta.idSucursal,
                "Nombre de la sucursal": respuesta.sucursalName,
                "Dirección de la sucursal": respuesta.addressSuc,
                "Nombre del responsable": respuesta.respName,
                "ID Vacante": respuesta.idusuarioVacantes,
                "Tipo de vacante": respuesta.tipoVacante,
                "Horario": respuesta.idHorario,
                "Temporalidad": respuesta.numeroSemanas,
                "Fecha tentativa de ingreso": respuesta.fechaTentativa,
                "Nombre del candidato": respuesta.userName,
                "Estatus del candidato": respuesta.idStatusUser
                }
                arrayXLS.push(insertXLS);
            });
            this.setState({
                descargaExcel: arrayXLS
            });
        });
    }

    componentDidMount() {
        this.getBajasEntrantes();
    }

    getBajasEntrantes = () => {
        this.setState({loader: true})
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/loss-promoters/operativoBajas", {
            "data":
            {
                "pageNumber": 1,
                "pageSize": 25

            }
        }).then(response => {
            console.log(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/loss-promoters/operativoBajas");
            console.log(response);
            console.log("data", response.data.data.listaBajas)
            this.setState({
                solicitudesEntrantes: response.data.data.listaBajas,
                status: 'success',
                loader: false
            });
            //this.excelEntrantes();
        });
    }


    iniciarBuscador = (value) => {
        this.setState({...this.state, buscador: value});
        var entrantes = this.state.solicitudesEntrantes;

        if (value === "") {
            this.getBajasEntrantes();
        } else {
            var filtro = entrantes.filter((entrante) => entrante['sucursalName'].includes(value.toUpperCase()));

            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => entrante['idSucursal'].includes(value.toUpperCase()));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => entrante['fechaTentativa'].includes(value));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => ceros_izq(entrante['idSolicitud'], digitos).includes(value));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => (entrante['numBajas'].toString()).includes(value));
            }
            this.setState({ solicitudesEntrantes: filtro });
        }

    }

    render() {
        var rows = [];

        const handleChange = e => {
            this.iniciarBuscador(e.target.value);
        };


        if (this.state.solicitudesEntrantes.length > 0) {
            for (let i = 0; i < this.state.solicitudesEntrantes.length; i++) {
                var element = {
                    folio: ceros_izq(this.state.solicitudesEntrantes[i].idSolicitud, digitos),
                    nombreSucursal: this.state.solicitudesEntrantes[i].sucursalName,
                    numBajas: this.state.solicitudesEntrantes[i].numBajas,
                    fechaTentativa: this.state.solicitudesEntrantes[i].fechaTentativa,
                    idSucursal: this.state.solicitudesEntrantes[i].idSucursal,
                    idSolicitud: this.state.solicitudesEntrantes[i].idSolicitud,
                    sucursalid: this.state.solicitudesEntrantes[i].sucursalid
                }
                rows.push(element);
            }
        }
        return (
            <div>

                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">
                        <div className="input-group mb-3">
                            <input type="text"
                                className="form-control bg-search"
                                placeholder="&#xf002; Buscar"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-1 offset-lg-4">
                        <Usuario />
                    </div>
                </div>
                <div className="FondoBlanco row mt-5">
                    <div className="row mt-5 p-3 mb-5" style={{height: "fit-content"}}>
                        <div className="col-10 btn-group btn-content-ba" role="group">
                            <Link to="/bajapromotores/entrantes/"><button type="button" className="btn btn-left-ba btn-outline-ba btn-outline-ba-active">Bajas entrantes</button></Link>
                            <Link to="/bajapromotores/confirmadas/"><button type="button" className="btn btn-right-ba btn-outline-ba">Bajas confirmadas</button></Link>
                        </div>
                        <div className="col-2 div-btns-ent mt-5">
                            <button type="button" className="btn-olg-ba" onClick={this.getBajasEntrantes}>
                                <i className="fa fa-repeat" aria-hidden="true"></i> Actualizar
                            </button>

                            {/*<img className="excel-icon" src={excel_logo} alt="excel icon" />*/}
                            <ExportCSV type={27} buscador={this.state.buscador} csvData={this.state.descargaExcel} fileName={fileName} />

                        </div>
                    </div>
                    <div className="col col-lg-12" style={{display: this.state.loader ? "none" : "block"}}>
                        <div style={{ height: 'auto', width: '100%', padding: '0 55px' }}>
                        <DataGrid
                            autoHeight={true}
                            getRowId={(row) => row.idSolicitud}
                            disableSelectionOnClick
                            rows={rows}
                            columns={columns}
                            pageSize={25}
                            rowsPerPageOptions={[25]}
                            //loading={true}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                ),/*
                                      LoadingOverlay: () => (
                                          <Spinner animation={true} />
                                      )*/
                            }} />

                    </div>
                    </div>
                    <div className="col col-lg-12" style={{display: this.state.loader ? "block" : "none"}}>
                        <div className="margin-loader">
                            <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
               {/*<div className={this.showHideClassName}>
                    <section className="modal-main">
                        <button type="button" onClick={this.hideModal()}>
                            Close
                        </button>
                    </section>
                </div>*/}
            </div>
        );
    }
}

export default BajaPromotoresEntrantes;
