import {Redirect, Route} from 'react-router-dom';
import Login from "./ComponentesGenerales/Login";

export default function PrivateRoute({component: Component, ...rest}) {

    let user = false;
    if (localStorage.getItem("username") != "" && localStorage.getItem("lastname")!= ""){
        user = true;
    } else {
        user = false;
    }
    return(
        <div>
            <Route >
                {user ?
                    <Component props={rest.computedMatch.params} />
                    :
                    window.location.href = "/login"
                }
            </Route>
        </div>
    )
}