import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import * as environment from "../Global";
import Swal from 'sweetalert2';
import { toBePartiallyChecked } from "@testing-library/jest-dom/dist/matchers";

class DetalleCompletas extends Component {
    state = {
        info: [],
        loader: false,
        numVacFijas: 0,
        numVacTemp:0,
        disableInputId: true
    }

    componentDidMount() {
        this.obtenerinfodetalle();
    }

    obtenerinfodetalle = () => {
        this.setState({ loader: true })

        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/completeDetail", {
            'data':
            {
                "idSolicit": this.props.props.idSolicit,
                "idSucursal": this.props.props.idSucursal
            }
        }).then(response => {
            console.log("Detalle completa", response.data.data.complet_solicit[0]);
            this.setState({
                info: response.data.data.complet_solicit[0],
                loader: false
            });
            for (const vacante of this.state.info.detailUser) {
                if (vacante.typeVacante === "FIJO"){
                    this.setState({
                        numVacFijas: this.state.numVacFijas + 1,
                    });
                    //console.log("VACANTES FIJAAAAAS", this.state.numVacFijas)
                } else {
                    this.setState({
                        numVacTemp: this.state.numVacTemp + 1,
                    });
                }
            }

        });
    }

    guardarCompletas = () => {
        //console.log("guardar completas");
        var userModified = [];
        var cambiarEstado = true;

        this.state.info.detailUser && this.state.info.detailUser.map((vacante, index) => {
            if (vacante.typeVacante === "FIJO") {

                if (document.getElementById("selectVF-" + index).value !== "Ingreso" || (document.getElementById("inputIDF-" + index).value === "")) {
                    cambiarEstado = false;
                }

                var idstatus = 0;
                switch (document.getElementById("selectVF-" + index).value) {
                    case "Capacitación":
                        idstatus = 1;
                        break;
                    case "Certificación":
                        idstatus = 2;
                        break;
                    case "Ingreso":
                        idstatus = 3;
                        break;
                }
                userModified.push({
                    "idStatus": idstatus,
                    "idUser": vacante.idUser,
                    "fcUser": document.getElementById("inputIDF-" + index).value
                });
            }
        })
        this.state.info.detailUser && this.state.info.detailUser.map((vacante, index) => {
            if (vacante.typeVacante === "TEMPORAL") {

                if (document.getElementById("selectVT-" + index).value !== "Ingreso" || (document.getElementById("inputIDT-" + index).value === "")) {
                    cambiarEstado = false;
                }
                var idstatus = 0;
                switch (document.getElementById("selectVT-" + index).value) {
                    case "Capacitación":
                        idstatus = 1;
                        break;
                    case "Certificación":
                        idstatus = 2;
                        break;
                    case "Ingreso":
                        idstatus = 3;
                        break;
                }
                userModified.push({
                    "idStatus": idstatus,
                    "idUser": vacante.idUser,
                    "fcUser": document.getElementById("inputIDT-" + index).value
                });
            }
        })
        var jsonOutput = {
            "data": {
                "userModified": userModified,
                "idSolicit": this.props.props.idSolicit,
                "idSucursal": this.props.props.idSucursal
            }
        }
        //console.log(jsonOutput);
        /* Axios para cambiar estatus*/
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/changeStatusUser",
            jsonOutput
        ).then(response => {
            //console.log(response);
            Swal.fire({
                position: 'center',
                title: "Los datos se guardaron correctamente ",
                confirmButtonColor: "#4DA357",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'Ok'

            }).then(response => {
                //console.log(response);
                if (cambiarEstado === true) {
                    //alert("CAmbiamos de estado a finalizado");
                    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/solicits/finalStatus", {
                        data:
                            {

                                "id_solicit": parseInt(this.props.props.idSolicit)

                            }
                    }).then(response => {
                        console.log("responseeee finalstatus", response);
                        window.location.href = "/solicitudes/finalizadas";
                    });
                }
            });
        });
    }

    filtro = (event) => {
        console.log("Tecla",event);
        var tecla = event.key;
        if (['.','e','+','-', 'E'].includes(tecla))
            event.preventDefault()
    }

    render() {
        return (
            this.state.loader ?
                <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
                :
                <div>
                    <div className="row">
                        <div className="col col-lg-6 offset-lg-1">

                        </div>
                        <div className="col col-lg-3 offset-lg-2">

                        </div>
                    </div>
                    <div className="row">
                        <div className="FondoBlanco mt-3 p-5">
                            <div className="row mt-3">
                                <div className="col col-lg-12">
                                    <Link to="/solicitudes/completas">
                                        <button type="button" className="btn btn-outline-success btn-rounded btn-icon">
                                            <i className="fa fa-arrow-left" aria-hidden="true" />
                                        </button>
                                    </Link>
                                    <b className="verdeTitle">{this.state.info.economicNumber}</b><b className="negroTitle">-{this.state.info.nameSuc}</b>

                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col col-lg-10'>
                                    <p className="pFade"><b className="pFadeB">Territorio:</b> {this.state.info.territory} <b className="pFadeB">Zona - Región</b> {this.state.info.region} <b className="pFadeB">Dirección:</b> {this.state.info.address}</p>
                                    <p className="pFade"><b className="pFadeB">Municipio:</b> {this.state.info.municipality} <b className="pFadeB">Estado:</b> {this.state.info.state}</p>
                                </div>
                                <div className='col col-lg-2'>
                                    <button onClick={this.guardarCompletas} type="button" className="btn btn-outline-ba-active">Guardar</button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col col-lg-10'>
                                    <p className="pNormal"><b className="bNormal">Responsable de la solicitud:</b> {this.state.info.nameUser}</p>
                                    <p className="pNormal"><b className="bNormal">Fecha de solicitud:</b> {this.state.info.dateSolicit}</p>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col col-lg-3'>
                                    <p><b className="verdeTitle">Número de Vacantes Disponibles</b></p>
                                    <p className="pNormal">{ }</p>
                                </div>
                                <div className='col col-lg-7'>
                                    <p><b className="verdeTitle">Fecha tentativa de ingreso</b></p>
                                    <p className="pNormal">{this.state.info.tentativeDate}</p>
                                </div>
                            </div>


                            <div className='row mt-3'>
                                <div className='col col-lg-3'>
                                    <p className="pNormal"><b className="bNormal">Vacantes fijas: </b>{this.state.numVacFijas}</p>
                                </div>
                            </div>

                            {/*inicia el loopeo */
                                this.state.info.detailUser && this.state.info.detailUser.map((vacante, index) => {
                                   //console.log(vacante);

                                    if (vacante.typeVacante == "FIJO") {
                                        return (
                                            <div className='row mt-3 border-dashed' key={index}>
                                                <div className='col col-lg-3'>
                                                    <p className="pNormal"><b className="bNormal">Vacante fija <label className="numberV">{vacante.nameUser + " " + vacante.lastName}</label>: </b></p>
                                                    <div className='col col-lg-7'>
                                                        <p className="pNormal"><b className="bNormal">Horario:</b> {vacante.shedule}</p>
                                                    </div>
                                                </div>
                                                <div className='col col-lg-4'>
                                                    <select className="form-control" value={vacante.status} id={"selectVF-" + index} disabled onChange={() => {
                                                        var valorselect = 0;
                                                        let valoridTextF = null;
                                                        switch (document.getElementById("selectVF-" + index).value) {
                                                            case "Capacitación":
                                                                valorselect = 1;
                                                                break;
                                                            case "Certificación":
                                                                valorselect = 2;
                                                                break;
                                                            case "Ingreso":
                                                                valorselect = 3;
                                                                valoridTextF = document.getElementById("inputIDF-" + index).value;
                                                                break;
                                                        }

                                                        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/changeStatusUser", {
                                                            'data':
                                                            {
                                                                "userModified": [
                                                                    {
                                                                        "idStatus": valorselect,
                                                                        "idUser": parseInt(vacante.idUser),
                                                                        "fcUser": valoridTextF
                                                                    }
                                                                ],
                                                                "idSolicit": parseInt(this.props.props.idSolicit),
                                                                "idSucursal": parseInt(this.props.props.idSucursal)

                                                            }
                                                        }).then(response => {
                                                            //console.log(response);
                                                            window.location.reload(false);
                                                        });
                                                    }}>
                                                        <option value="Capacitación">Capacitación</option>
                                                        <option value="Certificación">Certificación</option>
                                                        <option value="Ingreso">Ingreso</option>
                                                    </select>
                                                </div>
                                                <div className='col col-lg-2' id={"divFID-" + index} style={{ display: vacante.status == "Ingreso" ? "block" : "none" }}>
                                                    <input id={"inputIDF-" + index} maxLength={15} minLength={3}
                                                           onKeyDown={(e) => this.filtro(e)}
                                                           disabled={true}
                                                           type="number" className="form-control" defaultValue={vacante.fcUser} placeholder="Ingresa un ID"/>
                                                </div>

                                                <div className='col col-lg-1'>
                                                    <i className="fa fa-pencil checknombres m-over" aria-hidden="true" onClick={() => {
                                                        Swal.fire({
                                                            position: 'center',
                                                            title: "¿Quieres cambiar de estatus al candidato? ",
                                                            confirmButtonColor: "#4DA357",
                                                            showCancelButton: true,
                                                            showConfirmButton: true,
                                                            confirmButtonText: 'Si',
                                                            cancelButtonText: 'No'

                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                //this.setState({disableInputId: false})
                                                                document.getElementById("selectVF-" + index).disabled = false;
                                                                document.getElementById("inputIDF-" + index).disabled = false;
                                                            }
                                                        });

                                                    }} />
                                                    <i onClick={() => {
                                                        Swal.fire({
                                                            position: 'center',
                                                            title: "¿Deseas cancelar el proceso de este candidato? ",
                                                            confirmButtonColor: "#4DA357",
                                                            showCancelButton: true,
                                                            showConfirmButton: true,
                                                            confirmButtonText: 'Si',
                                                            cancelButtonText: 'No'

                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/saveUser", {
                                                                    data:  {
                                                                        userModified: [{
                                                                            name:vacante.nameUser,
                                                                            lastName:vacante.lastName,
                                                                            idUser: vacante.idUser,
                                                                            action: 3 //1 PARA GUARDAR // 2 EDITAR //3 DELETE
                                                                        }],
                                                                        idSolicit: parseInt(this.props.props.idSolicit)
                                                                    }
                                                                }).then(response => {
                                                                    console.log("Response elimina", response);
                                                                    window.location.href = "/solicitudes/tomadas";
                                                                })
                                                            }
                                                        });

                                                    }} className="fa fa-trash-o checknombres m-over " aria-hidden="true" />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }

                            <div className='row mt-3 pt-5'>
                                <div className='col col-lg-3'>
                                    <p className="pNormal"><b className="bNormal">Vacantes temporales: </b> { this.state.numVacTemp }</p>
                                </div>
                            </div>

                            {/*inicia el loopeo */
                                this.state.info.detailUser && this.state.info.detailUser.map((vacante, index) => {
                                    //console.log(vacante);
                                    if (vacante.typeVacante === "TEMPORAL") {
                                        return (
                                            <div className='row mt-3 border-dashed' key={index}>
                                                <div className='col col-lg-3'>
                                                    <p className="pNormal"><b className="bNormal">Vacante temporal <label className="numberV">{vacante.nameUser + " " + vacante.lastName}</label>: </b></p>
                                                    <p className="pNormal"><b className="bNormal">Número de semanas: </b>{vacante.numWeek} semanas</p>
                                                    <div className='col col-lg-7'>
                                                        <p className="pNormal"><b className="bNormal">Horario:</b> {vacante.shedule}</p>
                                                    </div>
                                                </div>
                                                <div className='col col-lg-4'>
                                                    <select className="form-control" value={vacante.status}
                                                            id={"selectVT-" + index} disabled onChange={() => {
                                                        let valorselect = 0;
                                                        let valoridTextT = null;
                                                        switch (document.getElementById("selectVT-" + index).value) {
                                                            case "Capacitación":
                                                                valorselect = 1;
                                                                break;
                                                            case "Certificación":
                                                                valorselect = 2;
                                                                break;
                                                            case "Ingreso":
                                                                valorselect = 3;
                                                                valoridTextT = document.getElementById("inputIDT-" + index).value;
                                                                break;
                                                        }

                                                        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/changeStatusUser", {

                                                            'data':
                                                                {
                                                                    "userModified": [
                                                                        {
                                                                            "idStatus": valorselect,
                                                                            "idUser": parseInt(vacante.idUser),
                                                                            "fcUser": valoridTextT
                                                                        }
                                                                    ],
                                                                    "idSolicit": parseInt(this.props.props.idSolicit),
                                                                    "idSucursal": parseInt(this.props.props.idSucursal)

                                                                }
                                                        }).then(response => {
                                                            //console.log(response);
                                                            window.location.reload(false);
                                                        });
                                                    }}>
                                                        <option value="Capacitación">Capacitación</option>
                                                        <option value="Certificación">Certificación</option>
                                                        <option value="Ingreso">Ingreso</option>
                                                    </select>
                                                </div>
                                                <div className='col col-lg-2' id={"divTID-" + index}
                                                     style={{display: vacante.status == "Ingreso" ? "block" : "none"}}>
                                                    <input id={"inputIDT-" + index} maxLength={15} minLength={3}
                                                           onKeyDown={(e) => this.filtro(e)}
                                                           type="number"  className="form-control"
                                                           disabled={true}
                                                           defaultValue={vacante.fcUser} placeholder="Ingresa un ID"/>
                                                </div>
                                                <div className='col col-lg-1'>
                                                    <i className="fa fa-pencil checknombres m-over" aria-hidden="true"
                                                       onClick={() => {
                                                           Swal.fire({
                                                               position: 'center',
                                                               title: "¿Quieres cambiar de estatus al candidato? ",
                                                               confirmButtonColor: "#4DA357",
                                                               showCancelButton: true,
                                                               showConfirmButton: true,
                                                               confirmButtonText: 'Si',
                                                               cancelButtonText: 'No'

                                                           }).then((result) => {
                                                               if (result.isConfirmed) {
                                                                   //this.setState({disableInputId: false})
                                                                   document.getElementById("selectVT-" + index).disabled = false;
                                                                   document.getElementById("inputIDT-" + index).disabled = false;
                                                               }
                                                           });
                                                       }}/>
                                                    <i onClick={() => {
                                                        Swal.fire({
                                                            position: 'center',
                                                            title: "¿Deseas cancelar el proceso de este candidato?",
                                                            confirmButtonColor: "#4DA357",
                                                            showCancelButton: true,
                                                            showConfirmButton: true,
                                                            confirmButtonText: 'Si',
                                                            cancelButtonText: 'No'

                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/saveUser", {
                                                                    data:  {
                                                                        userModified: [{
                                                                            name:vacante.nameUser,
                                                                            lastName:vacante.lastName,
                                                                            idUser: vacante.idUser,
                                                                            action: 3 //1 PARA GUARDAR // 2 EDITAR //3 DELETE
                                                                        }],
                                                                        idSolicit: parseInt(this.props.props.idSolicit)
                                                                    }
                                                                }).then(response => {
                                                                    console.log("Response elimina", response);
                                                                    window.location.href = "/solicitudes/tomadas";
                                                                })
                                                            }
                                                        });

                                                    }} className="fa fa-trash-o checknombres m-over" aria-hidden="true"/>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
        );
    }
}

export default DetalleCompletas;