import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import * as environment from "../Global";
import Swal from 'sweetalert2';

class DetalleFinalizadas extends Component {
    state = {
        info: [],
        loader: false,
        numVacFijas: 0,
        numVacTemp:0
    }

    componentDidMount() {
        this.obtenerinfodetalle();
    }

    obtenerinfodetalle = () => {
        this.setState({ loader: true})

        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/finalySolicit", {
            'data':
                {
                    "idSolicit": this.props.props.idSolicit,
                    "idSucursal": this.props.props.idSucursal
                }
        }).then(response => {
            console.log("Response finalizada", response);
            this.setState({
                info: response.data.data.complet_solicit[0],
                loader: false

            });
            console.log("DetalleFinalizada", this.state.info);
            for (const vacante of this.state.info.detailUser) {
                console.log("EStoy en fooooooor", vacante)
                if (vacante.type === "FIJO"){
                    this.setState({
                        numVacFijas: this.state.numVacFijas + 1,
                    });
                    //console.log("VACANTES FIJAAAAAS", this.state.numVacFijas)
                } else {
                    this.setState({
                        numVacTemp: this.state.numVacTemp + 1,
                    });
                }
            }
        });
    }

    render() {
        return (
            this.state.loader ?
                <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
                :
                <div>
                    <div className="row">
                        <div className="col col-lg-6 offset-lg-1">

                        </div>
                        <div className="col col-lg-3 offset-lg-2">

                        </div>
                    </div>
                    <div className="row">
                        <div className="FondoBlanco mt-3 p-5">
                            <div className="row mt-3">
                                <div className="col col-lg-12">
                                    <Link to="/solicitudes/finalizadas">
                                        <button type="button" className="btn btn-outline-success btn-rounded btn-icon">
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        </button>
                                    </Link>
                                    <b className="verdeTitle">{this.state.info.economicNumber}</b><b className="negroTitle">-{this.state.info.nameSuc}</b>

                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col col-lg-12'>
                                    <p className="pFade"><b className="pFadeB">Territorio:</b> {this.state.info.territory} <b className="pFadeB">Zona - Región</b> {this.state.info.region} <b className="pFadeB">Dirección:</b> {this.state.info.address}</p>
                                    <p className="pFade"><b className="pFadeB">Municipio:</b> {this.state.info.municipality} <b className="pFadeB">Estado:</b> {this.state.info.state}</p>
                                </div>

                            </div>
                            <div className='row mt-3'>
                                <div className='col col-lg-10'>
                                    {/*}<p className="pNormal"><b className="bNormal">Responsable de la solicitud:</b> {this.state.info.nameUser}</p>*/}
                                    <p className="pNormal"><b className="bNormal">Fecha de solicitud:</b> {this.state.info.dateSolicit}</p>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col col-lg-3'>
                                    <p><b className="verdeTitle">Número de Vacantes:</b></p>
                                    <p className="pNormal">{ this.state.numVacFijas + this.state.numVacTemp }</p>
                                </div>
                                <div className='col col-lg-7'>
                                    <p><b className="verdeTitle">Fecha tentativa de ingreso</b></p>
                                    <p className="pNormal">{this.state.info.tentativeDate}</p>
                                </div>
                            </div>


                            <div className='row mt-3'>
                                <div className='col col-lg-3'>
                                    <p className="pNormal"><b className="bNormal">Vacantes fijos: </b>{ this.state.numVacFijas }</p>
                                </div>
                            </div>

                            {/*inicia el loopeo */
                                this.state.info.detailUser && this.state.info.detailUser.map((vacante, indexFijo) => {
                                    console.log("Vacanteeeeeeeee", vacante);
                                    if (vacante.typeVacante === 3 || vacante.typeVacante === 1) {
                                        return (
                                            <div className='row mt-3' key={indexFijo}>
                                                <div className='col col-lg-8 border-dashed'>
                                                    <p className="pNormal"><b className="bNormal">Vacante fijo: <label className="numberV">{vacante.nameUser + " " + vacante.lastName}</label></b></p>
                                                    <div className='col col-lg-7'>
                                                        <p className="pNormal"><b className="bNormal">Horario:</b> {vacante.shedule}</p>
                                                    </div>
                                                    <div className="row">
                                                        {/*<div className="col-6"><label className="numberV">{vacante.nameUser + " " + vacante.lastName}</label></div>*/}
                                                        <div className="col-6"><b className="bNormal">Fecha de ingreso:</b> {vacante.tentativeDate}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            }



                            <div className='row mt-3 pt-5'>
                                <div className='col col-lg-3'>
                                    <p className="pNormal"><b className="bNormal">Vacantes temporales: </b> { this.state.numVacTemp }</p>
                                </div>
                            </div>

                            {/*inicia el loopeo */
                                this.state.info.detailUser && this.state.info.detailUser.map((vacante, index) => {
                                    if (vacante.typeVacante === 2 || vacante.typeVacante === 4) {
                                        return (
                                            <div className='row mt-3' key={index}>
                                                <div className='col col-lg-8 border-dashed'>
                                                    <p className="pNormal"><b className="bNormal">Vacante temporal: <label className="numberV">{vacante.nameUser + " " + vacante.lastName}</label> </b></p>
                                                    <p className="pNormal"><b className="bNormal">Número de semanas: </b>{vacante.numWeek} semanas</p>
                                                    <p className="pNormal"><b className="bNormal">Horario:</b> {vacante.shedule}</p>
                                                    <div className="col-6"><b className="bNormal">Fecha de ingreso:</b> {vacante.tentativeDate}</div>
                                                    {/*<p className="pNormal"><b className="bNormal">Vacante temporal {index + 1}: </b></p>
                                                    <p className="pNormal"><b className="bNormal">Número de semanas: </b>{vacante.numWeek} semanas</p>
                                                    <div className="row">
                                                        <div className="col-6"><label className="numberV">{vacante.nameUser + " " + vacante.lastName}</label></div>
                                                        <div className="col-6"><b className="bNormal">Fecha de ingreso:</b> {vacante.tentativeDate}</div>
                                                    </div>*/}
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
        );
    }
}

export default DetalleFinalizadas;