import '../Solicitudes/Entrantes.css';
import excel_logo from '../Assets/img/excel_icon.png'
import * as environment from "../Global";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import Usuario from "../ComponentesGenerales/Usuario";
import Swal from 'sweetalert2';
import {Stack, Tooltip} from "@mui/material";
import { ExportCSV } from '../ComponentesGenerales/ExportCSV';

/*const handleCellClick = (param, event) => {
    Swal.fire({
        position: 'center',
        title: "¿Quieres comenzar el reclutamiento de las vacantes de esta solicitud?",
        cancelButtonColor: "#4DA357",
        confirmButtonColor: "#A0AEC0",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'No',
        cancelButtonText: 'Si'
    })
};*/

/*const handleRowClick = (param, event) => {
    event.stopPropagation();
};*/
var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() - days);
    return res.setHours(0,0,0,0);
}




const fileName = "Solicitudes Entrantes";
const columns = [
    { field: 'folio', headerName: 'FOLIO' },
    { field: 'idSucursal', headerName: 'ID SUCURSAL' },
    { field: 'nombreSucursal', headerName: 'NOMBRE SUCURSAL', width: 250 },
    { field: 'numBajas', headerName: 'NÚMEROS DE BAJAS', width: 160 },
    { field: 'fechaTentativa', headerName: 'FECHA BAJA', width: 160 },
    {
        field: 'descripcion', headerName: 'ESTATUS', width: 250, renderCell: (cellValues) => {
            return (
                <div style={{textAlign: "right"}} >
                    <button className="btn-status">{cellValues.value}</button>
                </div>
            );
        },
    }
];

var digitos = 5;
var ceros_izq = (n, d) => { var e = 10 ** d; return n < e ? ((n + e) + "").slice(1) : n + "" }



class BajaPromotoresConfirmadas extends Component {

    state = {
        solicitudesEntrantes: [],
        status: null,
        descargaExcel: [],
        buscador: ''
    };
    
    excelEntrantes = () => {
        console.log("Entro a excel entrantes");
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/solicits/descargaExcel", {
            "data":
            {
                "idStatus": 1
    
            }
        }).then(response => {
            console.log("Respuesta excel");
            console.log(response.data.data);
            var arrayXLS = [];
            response.data.data.map((respuesta,index)=>{
                var insertXLS = {
                "Folio de la solicitud": respuesta.idSolicitud,
                "Estado de la solicitud": "Entrante",
                "ID de la sucursal": respuesta.idSucursal,
                "Nombre de la sucursal": respuesta.sucursalName,
                "Dirección de la sucursal": respuesta.addressSuc,
                "Nombre del responsable": respuesta.respName,
                "ID Vacante": respuesta.idusuarioVacantes,
                "Tipo de vacante": respuesta.tipoVacante,
                "Horario": respuesta.idHorario,
                "Temporalidad": respuesta.numeroSemanas,
                "Fecha tentativa de ingreso": respuesta.fechaTentativa,
                "Nombre del candidato": respuesta.userName,
                "Estatus del candidato": respuesta.idStatusUser
                }
                arrayXLS.push(insertXLS);
            });
            this.setState({
                descargaExcel: arrayXLS
            });
        });
    }

    componentDidMount() {
        this.getBajasEntrantes();
    }

    getBajasEntrantes = () => {
        //axios.post(Global.url_qa + "/solicits/listSolicits")
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/loss-promoters/bajasConfirmadas", {
            "data":
            {
                "pageNumber": 1,
                "pageSize": 25

            }
        }).then(response => {
            console.log(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/loss-promoters/bajasConfirmadas");
            console.log(response);
            console.log("data", response.data.data.listaBajas)
            this.setState({
                solicitudesEntrantes: response.data.data.listaBajas,
                status: 'success'
            });
            //this.excelEntrantes();
        });
    }


    iniciarBuscador = (value) => {
        this.setState({...this.state, buscador: value});
        var entrantes = this.state.solicitudesEntrantes;

        if (value === "") {
            this.getBajasEntrantes();
        } else {
            var filtro = entrantes.filter((entrante) => entrante['sucursalName'].includes(value.toUpperCase()));

            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => entrante['fcSucursal'].includes(value.toUpperCase()));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => entrante['fechaTentativa'].includes(value));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => ceros_izq(entrante['idSolicitud'], digitos).includes(value));
            }
            if (filtro.length === 0) {
                filtro = entrantes.filter((entrante) => (entrante['numVacnates'].toString()).includes(value));
            }
            this.setState({ solicitudesEntrantes: filtro });
        }

    }

    render() {
        var rows = [];

        const handleChange = e => {
            this.iniciarBuscador(e.target.value);
        };

        if (this.state.solicitudesEntrantes.length > 0) {
            for (let i = 0; i < this.state.solicitudesEntrantes.length; i++) {
                var element = {
                    folio: ceros_izq(this.state.solicitudesEntrantes[i].idSolicitud, digitos),
                    //idSucursal: this.state.solicitudesEntrantes[i].fcSucursal,
                    nombreSucursal: this.state.solicitudesEntrantes[i].sucursalName,
                    numBajas: this.state.solicitudesEntrantes[i].numBajas,
                    fechaTentativa: this.state.solicitudesEntrantes[i].fechaTentativa,
                    idSucursal: this.state.solicitudesEntrantes[i].idSucursal,
                    idSolicitud: this.state.solicitudesEntrantes[i].idSolicitud,
                    descripcion: this.state.solicitudesEntrantes[i].descripcion
                }
                rows.push(element);
            }
        }
        return (
            <div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">
                        <div className="input-group mb-3">
                            <input type="text"
                                className="form-control bg-search"
                                placeholder="&#xf002; Buscar"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-1 offset-lg-4">
                        <Usuario />
                    </div>
                </div>
                <div className="FondoBlanco row mt-5">
                    <div className="row mt-5 p-3 mb-5" style={{height: "fit-content"}}>
                        <div className="col-10 btn-group btn-content-ba" role="group">
                            <Link to="/bajapromotores/entrantes/"><button type="button" className="btn btn-left-ba btn-outline-ba ">Bajas entrantes</button></Link>
                            <Link to="/bajapromotores/confirmadas/"><button type="button" className="btn btn-right-ba btn-outline-ba btn-outline-ba-active">Bajas confirmadas</button></Link>
                        </div>
                        <div className="col-2 div-btns-ent mt-5">
                            <button type="button" className="btn-olg-ba" onClick={this.getBajasEntrantes}>
                                <i className="fa fa-repeat" aria-hidden="true"></i> Actualizar
                            </button>

                            {/*<img className="excel-icon" src={excel_logo} alt="excel icon" />*/}
                            <ExportCSV type={28} buscador={this.state.buscador} csvData={this.state.descargaExcel} fileName={fileName} />
                        </div>
                    </div>
                    <div style={{ height: 'auto', width: '100%', padding: '0 55px' }}>
                        <DataGrid
                            autoHeight={true}
                            getRowId={(row) => row.idSolicitud}
                            disableSelectionOnClick
                            rows={rows}
                            columns={columns}
                            pageSize={25}
                            rowsPerPageOptions={[25]}
                            //loading={true}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                ),/*
                                      LoadingOverlay: () => (
                                          <Spinner animation={true} />
                                      )*/
                            }} />

                    </div>
                </div>

            </div>
        );
    }
}

export default BajaPromotoresConfirmadas;
