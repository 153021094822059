import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ListadoSucursales from './Dashboard/ListadoSucursales';
import VacanteNuevaSolicitud from "./NuevaVacante/VacanteNuevaSolicitud";
import Sidebar from './ComponentesGenerales/Sidebar';
import logo from "./Assets/img/bancoaztecalogo.png";
import Error from "./Error";
import Entrantes from "./Solicitudes/Entrantes";
import Tomadas from './Solicitudes/Tomadas';
import DetalleTomadas from './Solicitudes/DetalleTomadas';
import DetalleEntrante from "./Solicitudes/DetalleEntrante";
import DetalleCompletas from './Solicitudes/DetalleCompletas';
import Completas from './Solicitudes/Completas';
import DetalleSucursal from './Dashboard/DetalleSucursal';
import Finalizadas from "./Solicitudes/Finalizadas";
import DetalleFinalizadas from "./Solicitudes/DetalleFinalizadas";
import HistorialVacantes from './Solicitudes/HistorialVacantes';
import Canceladas from './Solicitudes/Canceladas';
import Login from './ComponentesGenerales/Login';
import Promotores from './Dashboard/Promotores';
import NuevaBajaPromotores from "./Bajas/NuevaBaja";
import ListadoBajas from "./Bajas/ListadoBajas";
import VacantesBajas from './Dashboard/VacantesBajas';
import VacantesIncidentes from './Dashboard/VacantesIncidentes';
import BajaPromotoresEntrantes from './BajaPromotores/BajaPromotoresEntrantes';
import BajaPromotoresConfirmadas from './BajaPromotores/BajaPromotoresConfirmadas';
import DetalleBajas from './Bajas/DetalleBajas';
import ListadoPromotoresBajas from './Bajas/ListadoPromotoresBajas';
import ListadoBajasBajas from './Bajas/ListadoBajasBajas';
import PrivateRoute from "./PrivateRoute";

class Router extends Component {

    render() {
        if (window.location.pathname.includes("/login") || window.location.pathname == "/") {
            return (
                <BrowserRouter>
                    {/*inicia contenedor de coponente */}
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route exact path='/login' component={Login} />
                        <Route component={Error} />
                    </Switch>
                    {/*fin contenedor de coponente */}

                </BrowserRouter>
            );
        } else {
            return (
                <BrowserRouter>

                    <div className="container-fluid">
                        <div className="row mt-3">
                            <div className="col col-lg-2">
                                <div className="row">
                                    <img src={logo} alt="logo" />
                                </div>
                                <div className="row mt-5">
                                    <Sidebar />
                                </div>
                            </div>
                            <div className=" containerPrincipal  col col-lg-10">
                                {/*inicia contenedor de coponente */}
                                <Switch>

                                    <PrivateRoute exact path='/vacantes/nueva' component={VacanteNuevaSolicitud} />
                                    <PrivateRoute exact path='/vacantes' component={ListadoSucursales} />
                                    <PrivateRoute exact path='/solicitudes' component={Entrantes} />
                                    <PrivateRoute exact path='/solicitudes/tomadas' component={Tomadas} />
                                    <PrivateRoute exact path='/solicitudes/completas' component={Completas} />
                                    <PrivateRoute exact path='/solicitudes/finalizadas' component={Finalizadas} />
                                    <PrivateRoute exact path='/solicitudes/detalleTomadas/:idSolicit/:idSucursal' component={DetalleTomadas} />
                                    <PrivateRoute exact path='/solicitudes/detalleEntrante/:idSolicit/:idSucursal' component={DetalleEntrante} />
                                    <PrivateRoute exact path='/solicitudes/detallecompletas/:idSolicit/:idSucursal' component={DetalleCompletas} />
                                    <PrivateRoute exact path='/solicitudes/detalleFinalizadas/:idSolicit/:idSucursal' component={DetalleFinalizadas} />
                                    <PrivateRoute exact path='/vacantes/detalleSucursal/:fcSucursal/:idSucursal' component={DetalleSucursal} />
                                    <PrivateRoute exact path='/vacantes/historialvacante/:idUser' component={HistorialVacantes} />
                                    <PrivateRoute exact path='/bajaPromotores' component={ListadoBajas} />
                                    <PrivateRoute exact path='/bajaPromotores/nuevaBaja' component={NuevaBajaPromotores} />
                                    <PrivateRoute exact path='/cancelaciones' component={Canceladas} />
                                    <PrivateRoute exact path='/vacantes/promotores/:fcSucursal/:idSucursal' component={Promotores} />
                                    <PrivateRoute exact path='/vacantes/bajas/:fcSucursal/:idSucursal' component={VacantesBajas} />
                                    <PrivateRoute exact path='/vacantes/incidentes/:fcSucursal/:idSucursal' component={VacantesIncidentes} />
                                    <PrivateRoute exact path='/bajapromotores/entrantes/' component={BajaPromotoresEntrantes} />
                                    <PrivateRoute exact path='/bajapromotores/confirmadas/' component={BajaPromotoresConfirmadas} />
                                    <PrivateRoute exact path='/bajaPromotores/detallebajas/:fcSucursal/:idSucursal' component={DetalleBajas} />
                                    <PrivateRoute exact path='/bajaPromotores/bajasbajas/:fcSucursal/:idSucursal' component={ListadoBajasBajas} />
                                    <PrivateRoute exact path='/bajaPromotores/promotoresbajas/:fcSucursal/:idSucursal' component={ListadoPromotoresBajas} />
                                    <PrivateRoute component={Error} />
                                </Switch>
                                {/*fin contenedor de coponente */}
                            </div>
                        </div>
                    </div>
                </BrowserRouter>
            );
        }


    }
}
export default Router;