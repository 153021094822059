import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import * as environment from "../Global";

var ceros_izq = (n) => {return('00000'+n).slice(-5)}

/*var suffix = hours >= 12 ? "PM": "AM";
var horas = ((horas + 11) % 12 + 1) + suffix*/
 
class HistorialVacantes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historico: [],
            loader: false
        };
    }
    obtenerInformacion = () => {
        this.setState({ loader: true})
        console.log(this.props.props.idUser);
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/solicits/detailUserHistory", {
            data:
            {
                "id_user": this.props.props.idUser
            }
        }).then(response => {
            console.log("Respuesta historico", response);
            this.setState({
                historico: response.data.data.DataHistoryUser,
                nombre: response.data.data.DataHistoryUser[0].userName,
                fechaSolicitud: response.data.data.DataHistoryUser[0].fechaCreacion,
                fechaTentativa: response.data.data.DataHistoryUser[0].fechaTentativa,
                entrada: response.data.data.DataHistoryUser[0].idEntrada,
                salida: response.data.data.DataHistoryUser[0].idSalida,
                loader: false
            });
        });
    }
    componentDidMount() {
        this.obtenerInformacion();
    }

    back(){
        window.history.back();
    }
    render() {
        var horas = ((this.state.salida + 11) % 12 + 1)

        return (
            this.state.loader ?
                <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
                :
            <div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">

                    </div>
                    <div className="col col-lg-3 offset-lg-2">

                    </div>
                </div>
                <div className="row">
                    <div className="FondoBlanco mt-3 p-5">
                        <div className="row mt-3">
                            <div>
                                <div className="row">
                                    <div className="col col-lg-10">

                                            <button type="button" className="btn btn-outline-success btn-rounded btn-icon" onClick={this.back}>
                                                <i className="fa fa-arrow-left" aria-hidden="true" />
                                            </button>

                                        <span className="textoSuperior">Historial de vacante</span>
                                        <div className='row mt-3'>
                                            <div className='col col-lg-10'>
                                                <b className="grisTitle">{ceros_izq(this.props.props.idUser)} -</b><b className="negroTitle"> {this.state.nombre} </b>
                                                <p className="pFade mt-3"><b className="pFadeB">Fecha de solicitud: </b> {this.state.fechaSolicitud}</p>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col col-lg-10'>
                                                <span className="lblHistorico">Estatus</span><br/><br/>
                                                
                                                
                                                {this.state.historico && this.state.historico.map((h, index) => {
                                                    var estilo = "";
                                                    switch (h.detalleUsuario) {
                                                        case "Solicitud":
                                                            estilo = "historialSolicitud";
                                                            break;
                                                        case "En reclutamiento":
                                                            estilo = "historialReclutamiento";
                                                            break;
                                                        case "Capacitación":
                                                            estilo = "historialCapacitacion";
                                                            break;
                                                        case "Certificación":
                                                            estilo = "historialCertificacion";
                                                            break;
                                                        case "Ingreso":
                                                            estilo = "historialIngreso";
                                                            break;
                                                    }
                                                    return (
                                                        <div key={index}><span className={estilo}>{h.detalleUsuario}:</span> <span className="pFade">{h.fechaCambio}</span><br /></div>
                                                    )

                                                })}
                                                
                                            </div>
                                        </div>
                                        <div className='row mt-5'>
                                            <div className='col col-lg-10'>
                                                <span className="lblHistorico">Fecha tentativa de ingreso:</span>
                                                <p className="lblHistoricoHoras">{this.state.fechaTentativa}</p>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col col-lg-10'>
                                                <span className="lblHistorico">Horario:</span>
                                                <p className="lblHistoricoHoras">{this.state.entrada} am - {this.state.salida} pm</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }



}
export default HistorialVacantes;