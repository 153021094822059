import React, {Component} from "react";


import CreatableSelect from 'react-select/creatable';
import axios from "axios";
import * as environment from "../Global";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es'
import DatePicker from '@mui/lab/DatePicker';
import Swal from "sweetalert2";



const valueInput = "";

var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() + days);
    return res;
}

class NuevaBajaPromotores extends Component {

    state = {
        idSucursal: "",
        numPromotores: "",
        numBajas: "",
        optionsList: [],
        infoSucursal: [],
        showSucursalInfo: false,
        fechaBaja: addDaysToDate(new Date(), 7),
        disableBtnGuardar: true,
        loader: false,
        showPrincipal: true,
        showResumen: false,
        //fechaBajaService: new Date().getDate() + "/" + (new Date().getMonth()+1) + "/" + new Date().getFullYear()
        fechaBajaService: addDaysToDate(new Date(), 7).getDate() + "/" + (addDaysToDate(new Date(), 7).getMonth() + 1) + "/" + addDaysToDate(new Date(), 7).getFullYear()
    }



    back(){
        window.history.back();
    }

    handleChange = (value) => {
        this.setState({
            loader: true,
            idSucursal: value.value
        });
        //console.group('Value Changed', value.value);
        var solicitud = { data: { id_sucursal: value.value } };
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/loss-promoters/solicituBajas', solicitud)
            .then(response => {
                console.log("respuesta sucursalData", response.data.data.SolicitudBajas.length)
                if (response.data.data.SolicitudBajas.length > 0){
                    this.setState({
                        infoSucursal: response.data.data.SolicitudBajas[0],
                        showSucursalInfo: true,
                        loader: false,
                        numBajas: ""
                    });
                } else {
                    this.setState({
                        loader: false,
                        //showPrincipal: false
                    });
                    Swal.fire({
                        position: 'top',
                        icon: 'warning',
                        text: "No se encontraron coincidencias con tu búsqueda.",
                        confirmButtonColor: "#4DA357",
                        showConfirmButton: true
                    })
                }

            })
        //console.groupEnd();
    };

    handleInputChange = (inputValue) => {
        //inputValue.preventDefault();
        if (inputValue.length >= 3){
            this.getIdSucursales(inputValue.toUpperCase())
        }
    };

    getIdSucursales = (valueInput) => {
        if (valueInput == ""){
            valueInput = "O71";
        }
        var sucursalIdList = []
        var solicitud = { data: { sucursalId: valueInput } };
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/solicits/sucursalId', solicitud)
             .then(response => {
                 //console.log("RESPONSE SUC", response)
                 response = response.data.data.sucursals;
                 for (const responseKey of response) {
                     sucursalIdList.push({value: responseKey.idSucursal, label: responseKey.idSucursal});
                 }
                this.setState({
                    optionsList: sucursalIdList
                })
            })
    }

    validarBajas = (event) => {
        //console.log(event.target.value)
        if (event.target.value > this.state.infoSucursal.activos){
            Swal.fire({
                position: 'top',
                icon: 'warning',
                text: "Promotores insuficientes para tu número de bajas.",
                confirmButtonColor: "#4DA357",
                showConfirmButton: true
            })
            this.setState({disableBtnGuardar: true})
        } else if (event.target.value == 0){
            this.setState({disableBtnGuardar: true})
        } else {
            this.setState({numBajas: event.target.value})
            this.setState({disableBtnGuardar: false})
        }
    }

    componentWillMount() {
        this.getIdSucursales(valueInput)
    }

    mostrarResumen = () => {
        if (this.state.fechaBaja > addDaysToDate(new Date(), 6)){
            this.setState({showResumen: true, showPrincipal: false})
        } else {
            Swal.fire({
                position: 'top',
                icon: 'warning',
                title: "La fecha de baja debe ser 7 días posterior al día de hoy",
                confirmButtonColor: "#4DA357",
                showConfirmButton: true
            })
        }

    }

    editar = () => {
        this.setState({showResumen: false, showPrincipal: true})
    }

    aceptarBajas = () => {
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/loss-promoters/registroBajas', {
            'data':
                {
                    "idSucursal": this.state.infoSucursal.idSucursal,
                    "numBajas": parseInt(this.state.numBajas),
                    "fechaBaja": this.state.fechaBajaService,
                    responsable: localStorage.getItem("username") + " " + localStorage.getItem("lastname")
                }
            })
            .then(response => {
                if (response.data.data.Error){
                    //alert(response.data.data.Error);
                    Swal.fire({
                        position: 'top',
                        icon: "error",
                        text: response.data.data.Error,
                        timer: 4000,
                        confirmButtonText: "Aceptar"
                    });
                } else {

                    Swal.fire({
                        position: 'top',
                        imageUrl: "https://i.ibb.co/Hg9yL5V/aplauso.png",
                        imageWidth: 70,
                        imageHeight: 70,
                        title: "De acuerdo",
                        text: "Solicitaste " + this.state.numBajas + " bajas nuevas",
                        showConfirmButton: false,
                        timer: 4000
                    }).then(() => {
                         window.location.href = "/bajaPromotores";
                    });
                }
            });
    }

    render() {
        return (
            <div >
                <div className="row" style={{display: this.state.showPrincipal ? "block" : "none"}}>
                    <div className="FondoBlanco mt-3 p-5">
                        <div className="row mt-3">
                            <div>
                                <div className="row">
                                    <div className="col col-lg-10">
                                        <button type="button" className="btn btn-outline-success btn-rounded btn-icon" onClick={this.back}>
                                            <i className="fa fa-arrow-left" aria-hidden="true" />
                                        </button>
                                        <span className="textoSuperior">Crear nueva baja</span>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col col-lg-4">
                                            <div className="mb-3">
                                                <label htmlFor="buscarSucursal" className="form-label">Sucursal:</label>
                                                <CreatableSelect
                                                    isClearable
                                                    onChange={this.handleChange}
                                                    onInputChange={this.handleInputChange}
                                                    options={this.state.optionsList}
                                                    placeholder = "Número de sucursal*"
                                                />
                                            </div>
                                        </div>
                                        <div className="col col-lg-7 offset-1" style={{display: this.state.loader ? "block":"none"}}>
                                            <div className="margin-loader">
                                                <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div className="col col-lg-7 offset-1" style={{display: this.state.loader ? "none":"block"}}>
                                            {this.state.showSucursalInfo ? (<div className="mb-3">
                                                <span className="span-title">{this.state.infoSucursal.nameSucursal}</span>
                                                <p className="pNormal mt-3"><b>Territorio:</b>  {this.state.infoSucursal.territorio}</p>
                                                <p className="pNormal"><b>Zona - Región: </b>{this.state.infoSucursal.zona} - {this.state.infoSucursal.region}</p>
                                                <p className="pNormal"><b>Dirección:</b> {this.state.infoSucursal.direccion_suc} <b className="m-2">CP:</b> {this.state.infoSucursal.cp_suc} </p>
                                                <p className="pNormal"><b>Municipio:</b> {this.state.infoSucursal.municipio} <b className="m-2">Estado:</b> {this.state.infoSucursal.estado}</p>
                                        </div>) : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5" >
                            <div className="col col-lg-12">
                                {this.state.showSucursalInfo ? (<div className="mb-3">
                                    <span className="span-title">Actualmente tienes: <b>{this.state.infoSucursal.activos} promotores activos.</b></span>

                                </div>) : null}
                            </div>
                            <div className="col col-lg-4 mt-4">
                                {this.state.showSucursalInfo ? (<div className="mb-3">
                                    <span className="span-title">¿Cuántas bajas quieres hacer?</span>
                                    <div className="col-lg-12 mt-2">
                                        <input type="number"
                                               defaultValue={this.state.numBajas}
                                               onBlur={(e) => { this.validarBajas(e); }}
                                               className="form-control"
                                               placeholder="Ingresa el número"/>
                                    </div>
                                </div>) : null}
                            </div>
                            <div className="col col-lg-7 offset-1 mt-4">
                                {this.state.showSucursalInfo ? (<div>
                                    <span className="span-title">Fecha de baja</span>
                                    <div className="col-lg-7 mt-2">
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={this.state.fechaBaja}
                                                minDate={addDaysToDate(new Date(), 7)}
                                                onChange={(newValue) => {

                                                    this.setState({
                                                        fechaBajaService: new Date(newValue).getDate() + "/" + (new Date(newValue).getMonth()+1) + "/" + new Date(newValue).getFullYear()
                                                    });
                                                    //console.log("Cambio calendariooo", newValue)
                                                    this.setState({
                                                        fechaBaja: newValue,
                                                        //disableBtnGuardar: false
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>) : null}
                            </div>
                            <div className="col col-lg-12 mt-4">
                                {this.state.showSucursalInfo ? (<div className="row mb-3">
                                    <div className="col-lg-4">
                                        <button type="button" className="btn btn-ba mt-2" onClick={this.mostrarResumen} disabled={this.state.disableBtnGuardar}>Guardar</button>
                                    </div>
                                </div>) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{display: this.state.showResumen ? "block" : "none"}}>
                    <div className="FondoBlanco mt-3 p-5">
                        <div className="row mt-3">
                            <div className="row mb-4">
                                <div className="col col-lg-8">
                                    <span className="textoSuperior">Nueva baja</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-10">
                                    <span className="textoSuperior">Hola, esta es la baja que vas a crear</span>
                                    <p className="pFade mt-2">Revisa que todos los datos sean correctos y al final da clic en <b style={{color: "black"}}>aceptar</b></p>
                                </div>
                                <div className="col col-lg-2">
                                        <button onClick={this.editar} type="button" className="btn btn-outline-success">
                                            <i className="fa fa-pencil-square-o espaciador" aria-hidden="true" />Editar
                                        </button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col col-lg-12">
                                    <div className="mb-3">
                                        <p><b className="verdeTitle">{this.state.idSucursal}</b><b className="negroTitle">-{this.state.infoSucursal.nameSucursal}</b></p>
                                        <p className="pFade"><b className="pFadeB">Territorio:</b> {this.state.infoSucursal.territorio} <b className="pFadeB">Zona - Región</b> {this.state.infoSucursal.zona} - {this.state.infoSucursal.region} <b className="pFadeB">Dirección:</b> {this.state.infoSucursal.direccion_suc}<b className="m-2">CP:</b> {this.state.infoSucursal.cp_suc} </p>
                                        <p className="pFade"><b className="pFadeB">Municipio:</b> {this.state.infoSucursal.municipio} <b className="pFadeB">Estado:</b> {this.state.infoSucursal.estado}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col col-lg-12">
                                    <div className="mb-3">
                                        <p><b className="negroTitle">Actualmente tienes</b></p>
                                        <p className="pNormal"><b className="bNormal">Promotores activos: </b> {this.state.infoSucursal.activos}</p>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col col-lg-10'>
                                            <p><b className="verdeTitle">¿Cuántas bajas quieres hacer?:</b></p>
                                            <p className="pNormal">{this.state.numBajas}</p>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <p><b className="negroTitle">Fecha de baja</b></p>
                                        <p className="pNormal"><i className="fa fa-calendar color-green-ba fa-fw" /> {this.state.fechaBajaService}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-5">
                            <button type="button" onClick={this.aceptarBajas} className="btn btn-lg btn-ba mt-2" >Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export default NuevaBajaPromotores;