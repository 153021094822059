import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Usuario from "../ComponentesGenerales/Usuario";
import './Entrantes.css';
import {DataGrid} from "@mui/x-data-grid";
import axios from "axios";
import * as environment from "../Global";
import {Stack, Tooltip} from "@mui/material";
import { ExportCSV } from '../ComponentesGenerales/ExportCSV';
const fileName = "Solicitudes Tomadas";

/*const handleCellClick = (param, event) => {
    console.log("Dio click", param);
    Swal.fire({
        position: 'center',
        title: "¿Quieres comenzar el reclutamiento de las vacantes de esta solicitud?",
        cancelButtonColor: "#4DA357",
        confirmButtonColor: "#A0AEC0",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'No',
        cancelButtonText: 'Si'
    })
};*/

/*const handleRowClick = (param, event) => {
    event.stopPropagation();
};*/
var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() - days);
    return res.setHours(0,0,0,0);
}

const columns = [
    { field: 'folio', headerName: 'FOLIO' },
    { field: 'idSucursal', headerName: 'ID SUCURSAL', width: 130 },
    { field: 'nombreSucursal', headerName: 'NOMBRE SUCURSAL', width: 250 },
    { field: 'vacantesDisp', headerName: 'VACANTES DISPONIBLES', width: 130 },
    { field: 'vacantesOcup', headerName: 'VACANTES OCUPADAS', width: 130 },
    {
        field: 'fechaIngreso', headerName: 'FECHA TENTATIVA DE INGRESO', width: 160, renderCell: (cellValues) => {
            var fechaCellValue = cellValues.value.split("-");
            var fechaCellValueCompare = new Date(Date.parse(fechaCellValue[1] + "/" + fechaCellValue[0] + "/" + fechaCellValue[2]));
            var tmpDate = new Date(); // Today
            if ((new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 3)).getTime()) ||
                (new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 2)).getTime()) ||
                (new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 1)).getTime()) ||
                (new Date(addDaysToDate(tmpDate, 0)).getTime() === new Date(addDaysToDate(fechaCellValueCompare, 0)).getTime())) {

                return (
                    <Tooltip title="La fecha tentativa de ingreso está próxima a vencerse, completa esta solicitud a la brevedad.">
                        <i className="fa fa-exclamation-triangle color-warning m-over" aria-hidden="true">
                            <label className="text-label"> {cellValues.row.fechaIngreso}</label> </i>
                    </Tooltip>
                );

            } else if (fechaCellValueCompare.getTime() < new Date(addDaysToDate(tmpDate, 0)).getTime()) {
                //console.log("LA FECHA " + fechaCellValueCompare + " Es MENOR  a la fecha " + tmpDate);
                return (
                    <i className="fa fa-exclamation-triangle color-red m-over" aria-hidden="true"><label
                        className="text-label"> {cellValues.row.fechaIngreso}</label> </i>
                );
            }else {
                return (
                    <i className="fa fa-long-arrow-right color-white    " aria-hidden="true" ><label className="text-label"> {cellValues.row.fechaIngreso}</label> </i>
                );
            }
        }
    },
    {
        field: 'detail', headerName: '', width: 50, renderCell: (cellValues) => {
            return (
                <i className="fa fa-eye m-over"
                   onClick={(event) => {
                       //handleCellClick(event, cellValues);
                       console.log("IdSucursal: ", cellValues.row.idSuc);
                       console.log("IdSoolicit: ", cellValues.row.idSolicit);
                       window.location.href= "/solicitudes/detalleTomadas/" + cellValues.row.idSolicit +"/"+ cellValues.row.idSuc ;
                   }}aria-hidden="true"/>
            );
        },
    }
];

var digitos = 5;
var ceros_izq = (n, d) => { var e = 10 ** d; return n < e ? ((n + e) + "").slice(1) : n + "" }



class Tomadas extends Component {
    state = {
        solicitudesTomadas: [],
        status: null,
        descargaExcel: [],
        loader: false,
        buscador: ''
    };

    excelTomadas = () => {
        console.log("Entro a excel entrantes");
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/solicits/descargaExcel", {
            "data":
            {
                "idStatus": 2
    
            }
        }).then(response => {
            console.log("Respuesta excel");
            console.log(response.data.data);
            var arrayXLS = [];
            response.data.data.map((respuesta,index)=>{
                var insertXLS = {
                    "Folio de la solicitud": respuesta.idSolicitud,
                    "Estado de la solicitud": "Tomadas",
                    "ID de la sucursal": respuesta.idSucursal,
                    "Nombre de la sucursal": respuesta.sucursalName,
                    "Dirección de la sucursal": respuesta.addressSuc,
                    "Nombre del responsable": respuesta.respName,
                    "ID Vacante": respuesta.idusuarioVacantes,
                    "Tipo de vacante": respuesta.tipoVacante,
                    "Horario": respuesta.idHorario,
                    "Temporalidad": respuesta.numeroSemanas,
                    "Fecha tentativa de ingreso": respuesta.fechaTentativa,
                    "Nombre del candidato": respuesta.userName,
                    "Estatus del candidato": respuesta.idStatusUser
                }
                arrayXLS.push(insertXLS);
            });
            this.setState({
                descargaExcel: arrayXLS
            });
        });
    }

    componentDidMount() {
        this.getSolicitudesTomadas();
        this.excelTomadas();
    }

    getSolicitudesTomadas = () => {
        this.setState({loader: true})
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + "/operating-user/listRequestTaken", {
            data:
                {
                    "pageNumber": 1,
                    "pageSize": 1000
                }
        }).then(response => {
            console.log("data", response.data.data.requestTaken)
            this.setState({
                solicitudesTomadas: response.data.data.requestTaken,
                status: 'success',
                loader: false
            });
        });
    }

    iniciarBuscador = (value) => {
        this.setState({...this.state, buscador: value});
        var tomadas = this.state.solicitudesTomadas;

        if (value === "") {
            this.getSolicitudesTomadas();
        } else {
            var filtro = tomadas.filter((tomada) => tomada['sucursalName'].includes(value.toUpperCase()));

            if (filtro.length === 0) {
                filtro = tomadas.filter((tomada) => tomada['fcSucursal'].includes(value.toUpperCase()));
            }
            if (filtro.length === 0) {
                filtro = tomadas.filter((tomada) => tomada['fechaTentativa'].includes(value));
            }
            if (filtro.length === 0) {
                filtro = tomadas.filter((tomada) => ceros_izq(tomada['idSolicitud'], digitos).includes(value));
            }
            if (filtro.length === 0) {
                filtro = tomadas.filter((tomada) => (tomada['numVacantes'].toString()).includes(value));
            }
            this.setState({ solicitudesTomadas: filtro });
        }
    }

    render() {
        var rows = [];

        const handleChange = e => {
            this.iniciarBuscador(e.target.value);
        };

        if (this.state.solicitudesTomadas.length > 0) {
            for (let i = 0; i < this.state.solicitudesTomadas.length; i++) {
                //console.log("DATA:" + i + " : ", this.state.solicitudesTomadas[i]);
                var element = {
                    id: i + 1,
                    folio: ceros_izq(this.state.solicitudesTomadas[i].idSolicitud, digitos),
                    idSucursal: this.state.solicitudesTomadas[i].fcSucursal,
                    nombreSucursal: this.state.solicitudesTomadas[i].sucursalName,
                    vacantesDisp: this.state.solicitudesTomadas[i].numVacantes,
                    vacantesOcup: this.state.solicitudesTomadas[i].numOccupied,
                    fechaIngreso: this.state.solicitudesTomadas[i].fechaTentativa,
                    idSuc: this.state.solicitudesTomadas[i].idSucursal,
                    idSolicit: this.state.solicitudesTomadas[i].idSolicitud
                }
                rows.push(element);
                //console.log('Element: ', element);
            }
        }


        return (
            <div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">
                        <div className="input-group mb-3">
                            <input type="text"
                                className="form-control bg-search"
                                placeholder="&#xf002; Buscar"
                                onChange = {handleChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-1 offset-lg-4">
                        <Usuario />
                    </div>
                </div>
                <div className="FondoBlanco row mt-5">
                    <div className="row mt-5 p-3 mb-5" style={{height: "fit-content"}}>
                        <div className="col-10 btn-group btn-content-ba" role="group">
                            <Link to="/solicitudes"><button type="button" className="btn btn-left-ba btn-outline-ba">Solicitudes entrantes</button></Link>
                            <Link to="/solicitudes/tomadas"><button type="button" className="btn btn-outline-ba-active">Solicitudes tomadas</button></Link>
                            <Link to="/solicitudes/completas"><button type="button" className="btn btn-outline-ba">Solicitudes completas</button></Link>
                            <Link to="/solicitudes/finalizadas"><button type="button" className="btn btn-right-ba btn-outline-ba">Solicitudes finalizadas</button></Link>
                        </div>
                        <div className="col-2 div-btns-ent mt-5">
                            <button type="button" className="btn-olg-ba" >
                                <i className="fa fa-repeat" aria-hidden="true" onClick={this.getSolicitudesTomadas}></i> Actualizar
                            </button>
                            <ExportCSV type={2} buscador={this.state.buscador} csvData={this.state.descargaExcel} fileName={fileName} />
                        </div>
                    </div>
                    <div className="col col-lg-12" style={{display: this.state.loader ? "none" : "block"}}>
                        <div style={{ height: 'auto', width: '100%', padding: '0 55px' }}>
                        <DataGrid
                            autoHeight={true}
                            disableSelectionOnClick
                            rows={rows}
                                  columns={columns}
                                  pageSize={25}
                                  rowsPerPageOptions={[25]}
                                  disableColumnSelector
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No se encontraron resultados que coincidan con tu búsqueda
                                    </Stack>
                                )
                            }}/>
                    </div>
                    </div>
                    <div className="col col-lg-12" style={{display: this.state.loader ? "block" : "none"}}>
                        <div className="margin-loader">
                            <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Tomadas;
