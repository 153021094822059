// import fotoUsuario from '../Assets/img/fotoUsuario.png';
import Swal from "sweetalert2";

function Usuario() {
    const user = localStorage.getItem("username");
    const lastname = localStorage.getItem("lastname");
    return (


        <div className="Usuario">
            {/*<img src={fotoUsuario} alt="foto de perfil" />
            <span className="textoUsuario"> {user} {lastname}  </span>
            <i className="fa fa-chevron-down flechaAbajo" aria-hidden="true"></i>*/}

        <nav className="navbar navbar-expand-lg ">
            <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
                <ul className="navbar-nav">
                    <li className="nav-item dropdown textoUsuario">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink"
                           role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="textoUsuario"> {user} {lastname}  </span>
                        </a>
                        <ul className="dropdown-menu "
                            aria-labelledby="navbarDarkDropdownMenuLink btn-outline-success">
                            <li onClick = {() => {
                                Swal.fire({
                                    titleText: '¿Quieres cerrar sesión?',
                                    confirmButtonText: "Aceptar",
                                    cancelButtonText: "Cancelar",
                                    showCancelButton: true
                                }).then( response => {
                                    if (response.isConfirmed){
                                        localStorage.setItem("username", "")
                                        localStorage.setItem("lastname", "")
                                        window.location.href = "/login";
                                    }

                                })
                            }}><a className="dropdown-item" > <i className="fa fa-sign-out" aria-hidden="true"></i> Cerrar sesión</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    );
}
export default Usuario;