import React from 'react';
import 'moment/locale/es';
import axios from 'axios';
import Usuario from '../ComponentesGenerales/Usuario';
import * as environment from "../Global";
import { Link } from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import {Stack} from "@mui/material";
import { ExportCSV } from '../ComponentesGenerales/ExportCSV';

const viewers = [
    {Identif:1, name: "hugo A"},
    {Identif:2, name: "obed"},
    {Identif:3, name: "oscar"},
    {Identif:4, name: "berenice"}
];
const fileName = "usuarios excel";

const columns = [
  { field: 'folio', headerName: 'FOLIO'},
  { field: 'idSucursal', headerName: 'ID SUCURSAL', width: 130 },
  { field: 'nombreSucursal', headerName: 'NOMBRE DE LA SUCURSAL', width: 300 },
  { field: 'solicitudesCancel', headerName: 'SOLICITUDES CANCELADAS', width: 150 },
  { field: 'fechaCancelacion', headerName: 'FECHA DE CANCELACION', width: 150 },
  {
    field: 'estatus', headerName: 'ESTATUS', renderCell: (cellValues) => {
      return (
          <label className="lblCancel">Cancelada</label>
      );
    },
  },
  {
    field: 'detail', headerName: '', renderCell: (cellValues) => {
      return (
          <i className="fa fa-eye m-over" aria-hidden="true" onClick={(e)=>{
            //console.log("Cellvalues", cellValues.row);
            //window.location.href = "/vacantes/detalleSucursal/" + cellValues.row.fcSucursal + "/" + cellValues.row.idSucursal;
          }} />
      );
    },
  }
];

class Canceladas extends React.Component {
  state = {
    listadoSucursalesJson: [],
    status: null,
    buscador: ''
  };

  iniciarBuscador = (e) => {

    var sucursales = this.state.listadoSucursalesJson;

    if(e.target.value === ""){
      this.obtenerListadoSucursales();
    }
    else{
      var filtro = sucursales.filter((sucursal)=> sucursal['nombreSucursal'].includes(e.target.value.toUpperCase()));
      if(filtro.length === 0){
        filtro = sucursales.filter((sucursal)=> sucursal['fcSucursal'].includes(e.target.value.toUpperCase()));
      }
      if(filtro.length === 0){
        filtro = sucursales.filter((sucursal)=> sucursal['fechaIngreso'].includes(e.target.value));
      }
      // if(filtro.length == 0){
      //   filtro = sucursales.filter((sucursal)=> sucursal['vacantes'].includes(e.target.value));
      // }
      // if(filtro.length == 0){
      //   filtro = sucursales.filter((sucursal)=> sucursal['promotoresActivos'].includes(parseInt(e.target.value)));
      // }
      console.log(filtro);
      this.setState({listadoSucursalesJson: filtro});
    }
    
  }

  descargarListado = () => {
    //alert('click a descargar listado');
  }

  obtenerListadoSucursales = () => {
    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/solicits/listSucursal',{
      data:
          {
            "pageNumber": 1,
            "pageSize": 200

          }
    })
      .then(response => {
        console.log("respuesta datos", response.data);
        this.setState({
          listadoSucursalesJson: response.data.data,
          status: 'success'

        });
      });
  }

  componentDidMount() {
    //this.obtenerListadoSucursales();
  }

  render() {

    var rows = [];

    var elementDumy = {
      id: 1,
      folio: "01012201",
      idSucursal: "00001",
      nombreSucursal: "Alamedas",
      solicitudesCancel: 1,
      fechaCancelacion: "01/12/22 ",
      estatus: "Cancelada"
    }
    rows.push(elementDumy);

    if (this.state.listadoSucursalesJson.length > 0) {
      for (let i = 0; i < this.state.listadoSucursalesJson.length; i++) {
        //console.log("DATA:" + i + " : ", this.state.solicitudesEntrantes[i]);
        var element = {
          id: i + 1,
          numSucursal: this.state.listadoSucursalesJson[i].fcSucursal,
          nombreSucursal: this.state.listadoSucursalesJson[i].nombreSucursal,
          vacantes: this.state.listadoSucursalesJson[i].vacatesSucursal,
          fechaIngreso: this.state.listadoSucursalesJson[i].fechaIngreso,
          activos: this.state.listadoSucursalesJson[i].promotoresActivos,
          fcSucursal: this.state.listadoSucursalesJson[i].fcSucursal,
          idSucursal: this.state.listadoSucursalesJson[i].idSucursal
        }
        rows.push(element);
        //console.log('Element: ', element);
      }
    }

    return (<div>
      <div className="row">
        <div className="col col-lg-7">
          <div className="input-group mb-3">
           <input type="text"
              className="form-control bg-search"
              placeholder="&#xf002; Buscar"
              onChange={this.iniciarBuscador}
            />
          </div>
        </div>
        <div className="col col-lg-1 offset-lg-4">
          <Usuario />
        </div>
      </div>
      <div className="row">
        <div className="FondoBlanco mt-3 p-5">
          <div className="row mt-3">
            <div>
              <div className="row">
                <div className="col col-lg-2 offset-10 div-btns">
                  <button id="btnDescargar" type="button" className="btn btn-outline-success" ><i className="fa fa-refresh" aria-hidden="true" /> Actualizar</button>
                  <ExportCSV type={0} buscador={this.state.buscador} csvData={viewers} fileName={fileName} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col col-lg-12">
                  <div style={{ height: 'auto', width: 'auto', padding: '0 45px' }}>
                    <DataGrid
                        autoHeight={true}
                        disableSelectionOnClick
                        rows={rows}
                        columns={columns}
                        pageSize={25}
                        rowsPerPageOptions={[10]}
                        components={{
                          NoRowsOverlay: () => (
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                No se encontraron resultados que coincidan con tu búsqueda
                              </Stack>
                          ),
                          NoResultsOverlay: () => (
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                No se encontraron resultados que coincidan con tu búsqueda
                              </Stack>
                          )
                        }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
export default Canceladas;
