import React, { createRef, useRef, useState } from 'react';
import 'moment/locale/es';
import axios from 'axios';
import * as environment from "../Global";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Stack, Tooltip } from "@mui/material";
import excel_logo from '../Assets/img/excel_icon.png';

import alerta from "../Assets/img/alerta.png";
import aplauso from "../Assets/img/aplauso.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { Workbook } from 'exceljs';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as Locales from 'react-date-range/dist/locale';
import Usuario from "../ComponentesGenerales/Usuario";
import { addDays } from "date-fns";



registerLocale("es", es);
const columns = [
    { field: 'numSucursal', headerName: 'NÚMERO DE SUCURSAL', width: 160 },
    { field: 'nombreSucursal', headerName: 'NOMBRE DE LA SUCURSAL', width: 300 },
    { field: 'activos', headerName: 'PROMOTORES ACTIVOS', width: 160 },
    { field: 'vacantes', headerName: 'VACANTES EN PROCESO', width: 130 },
    { field: 'bajasProceso', headerName: 'BAJAS EN PROCESO', width: 160 },

    {
        field: 'detail', headerName: '', renderCell: (cellValues) => {
            return (
                <i className="fa fa-eye m-over" aria-hidden="true" onClick={(e) => {
                    //console.log("Cellvalues", cellValues.row);
                    window.location.href = "/vacantes/detalleSucursal/" + cellValues.row.fcSucursal + "/" + cellValues.row.idSucursal;
                }} />
            );
        },
    }
];
const fileInputRef = React.createRef();


class ListadoSucursales extends React.Component {

    dateRangeInit = [
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: '#4da357'
        }
    ];

    state = {
        minDate: new Date('01-01-2021'),
        maxDate: new Date(),
        listadoSucursalesJson: [],
        status: null,
        btnSolicitudesMasivas: false,
        file: [],
        listadoExcel: [],
        popPanel: true,
        fechaInicial: new Date(),
        fechaFinal: null,
        buscador: '',
        showProgress: false,
        loader: false,
        pageSize: 25,
        page: 0,
        totalRows: 0,
        loaderExcel: false,
        maxRangeDate: new Date(),
        calendarRange: this.dateRangeInit,
        disbleBtn: true,
        flag: false,
    };

    columnasExcel = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
    headersExcel = [
        { header: 'Folio de la solicitud', key: 'idSolicitud', width: 19 },
        { header: 'Estado de la solicitud', key: 'statusSolicitud', width: 20 },
        { header: 'ID de la sucursal', key: 'idSucursal', width: 16 },
        { header: 'Nombre de la sucursal', key: 'sucursalName', width: 25 },
        { header: 'Dirección de la sucursal', key: 'direccion_suc', width: 35 },
        { header: 'Nombre del responsable', key: 'responsable', width: 25 },
        { header: 'ID Vacante', key: 'idUsuarioVacante', width: 13 },
        { header: 'Tipo de vacante', key: 'tipoVacante', width: 15 },
        { header: 'Horario', key: 'horario', width: 15 },
        { header: 'Temporalidad', key: 'temporalidad', width: 15 },
        { header: 'Fecha tentativa de ingreso', key: 'fechaTentativa', width: 24 },
        { header: 'ID de ingreso', key: 'numEmpleado', width: 15 },
        { header: 'Nombre del candidato', key: 'nombreUsuario', width: 23 },
        { header: 'Estatus del candidato', key: 'statusUsuario', width: 20 }
    ];


    onChange = (fechas) => {
        console.log('-----------', fechas);
        const [start, end] = fechas;
        this.setState({
            fechaInicial: start,
            fechaFinal: end
        }, () => {
            //console.log("inicio: " + moment(this.state.fechaInicial).format('DD/MM/YYYY') + " fecha final: " + moment(this.state.fechaFinal).format('DD/MM/YYYY'));

        });

    }
    generarReporte = () => {
        this.setState({ loaderExcel: true });
        const initDate = this.state.calendarRange[0].startDate;
        const endDate = this.state.calendarRange[0].endDate;
        let data = {
            fecha_inicio: moment(initDate).format('DD/MM/YYYY') + ' 00:00:00',
            fecha_final: moment(endDate).format('DD/MM/YYYY') + ' 23:59:00'
        }
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + '/loss-promoters/excelVacantesGral', { data })
            .then(response => {
                let workbook = new Workbook();
                let worksheet = workbook.addWorksheet('Vacantes');
                let cols = [...this.columnasExcel];
                worksheet.columns = [...this.headersExcel];
                response.data.data.DescargaGral.map((respuesta) => {
                    respuesta.horario = respuesta.horario ? respuesta.horario.split(' ').join(' - ') : '';
                    worksheet.addRow({ ...respuesta }, 'n');
                });

                for (let i = 0; i < cols.length; i++) {
                    worksheet.getCell(cols[i] + 1).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '4da357' },
                    };
                }

                workbook.xlsx.writeBuffer().then((data) => {
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    let blob = new Blob([data], { type: fileType });
                    const key = `Reporte General ${moment(initDate).format('DD-MM-YYYY')} al ${moment(endDate).format('DD-MM-YYYY')}.xlsx`;
                    FileSaver.saveAs(blob, key);
                    this.setState({ loaderExcel: false });
                });
            });
    }

    iniciarBuscador = (e) => {
        this.setState({ buscador: e.target.value });
        var sucursales = this.state.listadoSucursalesJson;

        if (e.target.value === "") {
            this.obtenerListadoSucursales(this.state.page + 1);
        } else {
            var filtro = sucursales.filter((sucursal) => sucursal['nombreSucursal'].includes(e.target.value.toUpperCase()));
            if (filtro.length === 0) {
                filtro = sucursales.filter((sucursal) => sucursal['fcSucursal'].includes(e.target.value.toUpperCase()));
            }
            //if(filtro.length === 0){
            //filtro = sucursales.filter((sucursal)=> sucursal['fechaIngreso'].includes(e.target.value));
            //}
            // if(filtro.length == 0){
            //   filtro = sucursales.filter((sucursal)=> sucursal['vacantes'].includes(e.target.value));
            // }
            // if(filtro.length == 0){
            //   filtro = sucursales.filter((sucursal)=> sucursal['promotoresActivos'].includes(parseInt(e.target.value)));
            // }
            this.setState({ listadoSucursalesJson: filtro });

        }

    }

    descargarListado = () => {
        //alert('click a descargar listado');
    }

    obtenerListadoSucursales = (page) => {
        this.setState({ loader: true });
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/solicits/listSucursal', {
            data:
            {
                pageNumber: page,
                pageSize: this.state.pageSize

            }
        })
            .then(response => {
                this.setState({
                    listadoSucursalesJson: response.data.data.sucursales,
                    status: 'success',
                    loader: false,
                    totalRows: response.data.data.total_users
                });
            }, (error)=> {
                Swal.fire({
                    position: 'top',
                    imageUrl: alerta,
                    imageWidth: 70,
                    imageHeight: 70,
                    title: "¡Aviso!",
                    text: "Servicio temporalmente no disponible, verifica tu conexión a internet e intenta más tarde.",
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                })
            });
    }

    componentDidMount() {
        this.obtenerListadoSucursales(this.state.page + 1);
    }

    showSolicitudesMasivas = () => {
        if (this.state.btnSolicitudesMasivas) {
            this.setState({ btnSolicitudesMasivas: false });
        } else {
            this.setState({ btnSolicitudesMasivas: true });
        }
    }

    uploadExcel = async (file) => {

        this.setState({ loaderExcel: true });
        if(file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type == 'application/vnd.ms-excel' || file.type == '.csv'){
            try {
                this.setState({ showProgress: true });
                // Leemos excel
                const workbook = new Workbook();
                await workbook.xlsx.load(file);
                let jsonExcel = [];
                workbook.worksheets.forEach((sheet) => {
                    let firstRow = sheet.getRow(1);
                    if (!firstRow.cellCount) return;
                    let keys = 'ABCDEFG';
                    sheet.eachRow((row, rowNumber) => {
                        if (+rowNumber === 1) return;
                        let values = row.values;
                        let obj = {};
                        for (let i = 0; i < keys.length; i++) {
                            obj[keys[i]] = values[i + 1];
                        }
                        jsonExcel.push(obj);
                    });
                })
                // fin Leemos excel
                console.log('jsonExcel = ', jsonExcel);
    
                let errorVacantes = [];
                let aceptedVacantes = [];
                let dates = [];
                let sucursales = [];
    
                let vacantesSucursales = [];
                let vacantesFechas = [];
    
                if (jsonExcel.length > 0) {
                    this.setState({ file: jsonExcel });
                    let rowNumber = 1;
                    for (const row of jsonExcel) {
                        let tipoIncorrecto = '';
                        let hasError = false;
                        if ((!row.A || row.A === '') && (!row.B || row.B === '') && (!row.C || row.C === '') &&
                            (!row.D || row.D === '') && (!row.E || row.E === '') && (!row.F || row.F === '') &&
                            (!row.G || row.G === '')) {
                            break;
                        }
    
                        let rowCopy = {
                            A: row.A ? row.A : '',
                            B: row.B ? row.B : '',
                            C: row.C ? (moment.utc(row.C).format('HH:mm') || '') : '',
                            D: row.D ? row.D : '',
                            E: row.E ? (moment.utc(row.E).format('HH:mm') || '') : '',
                            F: row.F ? row.F : '',
                            G: row.G ? moment.utc(row.G).format('DD/MM/YYYY') : '',
                            row: rowNumber
                        }
    
    
                        // INICIA AGRUPACION DE SUCURSALES
                        if (sucursales.length === 0 && row.A && row.A !== '' && !isNaN(row.A)) { // sucursal obligatoria
                            //console.log("Sucursal", isNaN(row.A))
                            sucursales.push(row.A);
                        } else if (!row.A || row.A == '' || isNaN(row.A)) {
                            console.log('ERROR ROW A')
                            hasError = true;
                        }
    
                        for (var i = 0; i < sucursales.length; i++) { //  mismas sucursales
                            if (row.A && row.A !== '') {
                                if (sucursales.findIndex((s) => s == row.A) === -1) {
                                    sucursales.push(row.A);
                                    break;
                                }
                            }
                        }
                        // TERMINA AGRUPACION DE SUCURSALES
    
                        // INICIA AGRUPACIÓN DE FECHAS
                        if (dates.length === 0 && row.G && row.G !== '') { // fecha obligatoria
                            dates.push(moment.utc(row.G).format('DD/MM/YYYY'))
                        } else if (!row.G || row.G == '') {
                            //console.log('ERROR ROW G')
                            hasError = true;
                        }
    
                        for (var i = 0; i < dates.length; i++) { //  mismas fechas
                            if (row.G && row.G !== '') {
                                if (!dates.find((d) => d === moment.utc(row.G).format('DD/MM/YYYY'))) {
                                    dates.push(moment.utc(row.G).format('DD/MM/YYYY'));
                                    break;
                                }
                            }
                        }
                        // TERMINA AGRUPACIÓN DE FECHAS
    
                        // Validamos que si viene sucursal y fecha hay que validar que al menos venga temporal y/o fijo
                        if (row.A && row.A !== '') {
                            if (!row.G || row.G === '') { // en cualquier caso si no viene  fecha ya es error
                                hasError = true;
                            } else {
                                let errorF = false;
                                let errorT = false;
                                // Fijas
                                if (row.B && row.B !== '' && (!row.C || row.C == '')) {
                                    //console.log('ERROR C')
                                    errorF = true;
                                } else if ((!row.B || row.B == '') && (!row.C || row.C == '')) {
                                    //console.log('ERROR B y C');
                                    errorF = true;
                                }
                                // Temporal
                                if (row.D && row.D !== '' && (!row.E || row.E == '' || !row.F || row.F == '')) {
                                    //console.log('ERROR E o F')
                                    errorT = true;
                                } else if ((!row.D || row.D == '') && (!row.E || row.E == '')) {
                                    // console.log('ERROR D Y E');
                                    errorT = true;
                                }
                                if (errorT && errorF) {
                                    hasError = true;
                                }
                            }
                        }
    
                        console.log('HAS ERROR = ', hasError)
                        if (hasError) {
                            errorVacantes.push(rowCopy);
                        } else {
                            aceptedVacantes.push(rowCopy);
                        }
                        rowNumber++;
                    }
                    // TERMINA VACANTES TEMP
                    console.log('VACANTE ERROR = ', errorVacantes);
                    console.log('VACANTE SUCCESS = ', aceptedVacantes);
                }
    
                // agupamos por sucursal sólo los aceptados
                console.log('\n\nSUC = ', sucursales);
                for (let i = 0; i < sucursales.length; i++) {
                    let u = aceptedVacantes.filter((aV) => aV.A === sucursales[i]);
                    console.log(u)
                    if (u.length > 0) {
                        vacantesSucursales.push(u); // es una matriz que agrupa por sucursal
                    }
                }
                console.log('vacantesSucursales = ', vacantesSucursales)
                // Agrupamos por fecha los ya agrupados poor sucursal...
                for (let s = 0; s < vacantesSucursales.length; s++) {
                    for (let f = 0; f < dates.length; f++) {
                        let mismaFechas = vacantesSucursales[s].filter((vS) => vS.G === dates[f]);
                        if (mismaFechas.length > 0) {
                            vacantesFechas.push(mismaFechas);
                        }
                    }
                }
    
                console.log('vacantesFechas = ', vacantesFechas);
                let totalAceptados = 0;
                // en cada posicion de vacantes fechas debe ir misma sucursal con misma fecha por lo que ya se manda a servicio
                for (let soli = 0; soli < vacantesFechas.length; soli++) {
                    if (vacantesFechas[i]?.length > 20) {
                        errorVacantes = [...errorVacantes, vacantesFechas[soli]]; // si excede de 20 se agrega a errores
                        continue;
                    }
                    let vacanteData = [];
                    for (let vf = 0; vf < vacantesFechas[soli].length; vf++) {
                        let v = {};
                        if (vacantesFechas[soli][vf].B !== '' && vacantesFechas[soli][vf].C !== '') {
                            v = {
                                idTipoVacante: process.env.REACT_APP_ENVIRONMEN === 'dev' ? 3 : 1,
                                totalVacantes: parseInt(vacantesFechas[soli][vf].B),
                                idHorario: this.getHourId(vacantesFechas[soli][vf].C),
                                numWeek: vacantesFechas[soli][vf].F !== '' ? vacantesFechas[soli][vf].F : null
                            }
                        }
                        if (v.idTipoVacante && v.idHorario > 0) {
                            vacanteData.push({ ...v });
                        } else { //el horario está mal
                            errorVacantes.push(vacantesFechas[soli][vf])
                        }
                        v = {};
                        if (vacantesFechas[soli][vf].D !== '' && vacantesFechas[soli][vf].E !== '' && vacantesFechas[soli][vf].F) {
                            v = {
                                idTipoVacante: process.env.REACT_APP_ENVIRONMEN === 'dev' ? 4 : 2,
                                totalVacantes: parseInt(vacantesFechas[soli][vf].D),
                                idHorario: this.getHourId(vacantesFechas[soli][vf].E),
                                numWeek: vacantesFechas[soli][vf].F
                            }
                        }
                        if (v.idTipoVacante && v.idHorario > 0) { // horario no está en catalogo
                            vacanteData.push({ ...v });
                        } else { //el horario está mal
                            errorVacantes.push(vacantesFechas[soli][vf])
                        }
                    }
    
                    let data = {
                        tipoVancantes: vacanteData,
                        idsucursal: vacantesFechas[soli][0].A.toString(),
                        fechaTentativa: vacantesFechas[soli][0].G,
                        responsableId: localStorage.getItem("username") + " " + localStorage.getItem("lastname") // validar de DONDE OBTENER
                    }
    
                    console.log('\n\n\n\n REQUEST = ', data);
    
                    var arrErrores = [];
                    let vacanteFija = 0;
                    let vacanteTemp = 0;
    
                    // falta agregar los modales de exito una vez que se haya completado todo este for
                    await axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/confirm-low/bulk-load', { data })
                        .then(response => {
                            console.log("Resp save data", response)
                            if (response.data.data == 'true') {
                                totalAceptados = totalAceptados + vacantesFechas[soli].length;
                            } else {
    
                                console.log("Data error", data)
                                var solicitudError = {
                                    A: data.idsucursal,
                                    B: (data?.tipoVancantes[0]?.idTipoVacante == 1 || data?.tipoVancantes[0]?.idTipoVacante == 3) ? data?.tipoVancantes[0]?.totalVacantes : '',
                                    C: (data?.tipoVancantes[0]?.idTipoVacante == 1 || data?.tipoVancantes[0]?.idTipoVacante == 3) ? data?.tipoVancantes[0]?.idHorario : '',
                                    D: (data?.tipoVancantes[0]?.idTipoVacante == 2 || data?.tipoVancantes[0]?.idTipoVacante == 4) ? data?.tipoVancantes[0]?.totalVacantes : '',
                                    E: (data?.tipoVancantes[0]?.idTipoVacante == 2 || data?.tipoVancantes[0]?.idTipoVacante == 4) ? data?.tipoVancantes[0]?.idHorario : '',
                                    F: (data?.tipoVancantes[0]?.idTipoVacante == 2 || data?.tipoVancantes[0]?.idTipoVacante == 4) ? data?.tipoVancantes[0]?.numWeek : '',
                                    G: data?.fechaTentativa
    
                                };
                                console.log("Solicitud error", solicitudError);
                                errorVacantes.push(solicitudError);
                                this.setState({ showProgress: false });
                                /*
        
                                this.setState({showProgress: false});
                                Swal.fire({
                                    position: 'top',
                                    imageUrl: aplauso,
                                    imageWidth: 70,
                                    imageHeight: 70,
                                    titleText: "Ha finalizado la carga del archivo",
                                    text: "Se agregaron " + contSolAcept + " vacantes de " + (contSolAcept + contSolError) + " que contenia el archivo.",
                                    showConfirmButton: false,
                                    timer: 9000
                                }).then(() => {
                                    console.log("Estos son los errores", arrErrores);
                                    this.layoutErrorCargaMasiva(arrErrores);
                                    //window.location.href = "/vacantes";
                                    this.obtenerListadoSucursales(this.state.page + 1);
                                });*/
    
                            }
    
                        }, (error) => {
                            this.setState({flag: true});
                            Swal.fire({
                                position: 'top',
                                imageUrl: alerta,
                                imageWidth: 70,
                                imageHeight: 70,
                                title: "¡Aviso!",
                                text: "Servicio temporalmente no disponible, verifica tu conexión a internet e intenta más tarde.",
                                showConfirmButton: true,
                                confirmButtonText: 'Aceptar'
                            })
                        });
                }
                if(!this.state.flag){
                    if (errorVacantes.length > 0) {
                        // mostrar modal que hubo errores
                        Swal.fire({
                            position: 'top',
                            imageUrl: alerta,
                            imageWidth: 70,
                            imageHeight: 70,
                            title: "¡Aviso!",
                            text: "Lo sentimos algunos datos no son correctos.",
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        })
                        this.layoutErrorCargaMasiva(errorVacantes);
                    } else {
                        //aqui mostrrar que no hubo errores
        
                        Swal.fire({
                            position: 'top',
                            imageUrl: aplauso,
                            imageWidth: 70,
                            imageHeight: 70,
                            title: "¡Éxito!",
                            text: "Tus datos se cargaron exitosamente",
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar'
                        })
                    }
                    this.setState({ flag: false});
                    this.setState({ showProgress: false });
                    this.obtenerListadoSucursales(this.state.page + 1);
                    
                }
    
    
    
            } catch (error) {
                console.log(error);
                Swal.fire({
                    position: 'top',
                    imageUrl: alerta,
                    imageWidth: 70,
                    imageHeight: 70,
                    title: "¡Error!",
                    text: "Formato incompatible. Intenta de nuevo.",
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar'
                })
            }
        } else {
            Swal.fire({
                position: 'top',
                imageUrl: alerta,
                imageWidth: 70,
                imageHeight: 70,
                title: "¡Error!",
                text: "Formato incompatible. Intenta de nuevo.",
                showConfirmButton: true,
                confirmButtonText: 'Aceptar'
            }) 
        }
        
    }

    getHourId = (hour) => {
        let format = 'HH:mm';
        let hourId = 0;
        hour = moment.utc(hour, format);
        console.log(hour);
        console.log(moment.utc('09:00', format))
        if (hour.isSame(moment.utc('09:00', format))) {
            hourId = 1
        } else if (hour.isSame(moment.utc('11:00', format))) {
            hourId = 2
        } else if (hour.isSame(moment.utc('10:00', format))) {
            hourId = 3
        } else if (hour.isSame(moment.utc('12:00', format))) {
            hourId = 4
        }
        console.log('*****hourId = ', hourId);
        return hourId;
    }


    readExcel = (file) => {
        console.log('THE FILE => ', file);
        var arrErrores = [];
        this.setState({ showProgress: true });
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = async (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { raw: true });


                this.setState({ file: data });
                console.log("FILE", data);

                let idtipovacanteFijo = 0;//cambiar dependiendo el ambiente 3 dev, 2 qa

                if (process.env.REACT_APP_ENVIRONMEN === 'dev') {
                    idtipovacanteFijo = 3;
                } else {
                    idtipovacanteFijo = 1;
                }

                let idtipovacanteTemp = 0;//cambiar dependiendo el ambiente 4 dev, 2 qa

                if (process.env.REACT_APP_ENVIRONMEN === 'dev') {
                    idtipovacanteTemp = 4;
                } else {
                    idtipovacanteTemp = 2;
                }
                let contSolAcept = 0;
                let contSolError = 0;
                for (const dataExcelKey of data) {
                    /*console.log("idSucursal", dataExcelKey["ID SUCURSAL"]);
                    console.log("vacantesFijas", dataExcelKey["VACANTES FIJAS"]);
                    console.log("vacantesTemporales", dataExcelKey["VACANTES TEMPORALES"]);
                    console.log("temporalidad", dataExcelKey["TEMPORALIDAD"]);
                    console.log("fechaIngreso", dataExcelKey["FECHA DE INGRESO"]);
                    //console.log("fechaIngreso", new Date(dataExcelKey["FECHA DE INGRESO"]).toDateString());
                    console.log("horaEntrada", dataExcelKey["HORA DE ENTRADA"]);
                    console.log("horaENTRADA", dataExcelKey["HORA DE ENTRADA"]);*/
                    var temporalidad = null;
                    var numVacantes = null;

                    if (dataExcelKey["TEMPORALIDAD"] != undefined) {
                        temporalidad = dataExcelKey["TEMPORALIDAD"];
                        numVacantes = dataExcelKey["VACANTES TEMPORALES"];
                    } else {
                        numVacantes = dataExcelKey["VACANTES FIJAS"];
                    }


                    var solicitud = {
                        data: {
                            tipoVancantes: [
                                {
                                    idTipoVacante: idtipovacanteFijo,
                                    totalVacantes: numVacantes,
                                    idHorario: 1,
                                    numWeek: temporalidad
                                }
                            ],
                            idsucursal: "" + dataExcelKey["ID SUCURSAL"],
                            fechaTentativa: '22/06/2022', //dataExcelKey["FECHA DE INGRESO"],
                            //fechaTentativa: moment(this.state.fecha).format('DD/MM/YYYY'),
                            responsableId: localStorage.getItem("username") + " " + localStorage.getItem("lastname")
                        }
                    };

                    console.log('SOLICITUD => ', solicitud);
                    await axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/confirm-low/bulk-load', solicitud)
                        .then(response => {
                            if (response.data.data == 'true') {
                                contSolAcept++;
                            } else {
                                var solicitudError = {
                                    idsucursal: dataExcelKey["ID SUCURSAL"],
                                    vacantesFijas: dataExcelKey["VACANTES FIJAS"],
                                    horaEntradaFija: dataExcelKey["HORA DE ENTRADA"],
                                    vacantesTemporales: dataExcelKey["TEMPORALIDAD"],
                                    horaEntradaTemp: dataExcelKey["HORA DE ENTRADA"],
                                    temporalidad: dataExcelKey["TEMPORALIDAD"],
                                    fechaTentativa: dataExcelKey["FECHA DE INGRESO"]

                                };
                                //console.log("Solicitud error", solicitudError);
                                arrErrores.push(solicitudError);
                                //console.log("Entro a errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrror", arrErrores)
                                contSolError++;
                            }
                            this.setState({ showProgress: false });

                            Swal.fire({
                                position: 'top',
                                imageUrl: aplauso,
                                imageWidth: 70,
                                imageHeight: 70,
                                titleText: "Ha finalizado la carga del archivo",
                                text: "Se agregaron " + contSolAcept + " vacantes de " + (contSolAcept + contSolError) + " que contenia el archivo.",
                                showConfirmButton: false,
                                timer: 9000
                            }).then(() => {
                                console.log("Estos son los errores", arrErrores);
                                this.layoutErrorCargaMasiva(arrErrores);
                                //window.location.href = "/vacantes";
                                this.obtenerListadoSucursales(this.state.page + 1);
                            });
                        }, (error) => {
                            Swal.fire({
                                position: 'top',
                                imageUrl: alerta,
                                imageWidth: 70,
                                imageHeight: 70,
                                title: "¡Aviso!",
                                text: "Servicio temporalmente no disponible, verifica tu conexión a internet e intenta más tarde.",
                                showConfirmButton: true,
                                confirmButtonText: 'Aceptar'
                            })
                        });
                    //console.log(":::::::::.::::::::::::::::::::::::::::::::" + "contSolAcept " + contSolAcept + "contSolError " + contSolError)
                }
                //console.log(":::::::::.::::::::::::::::::::::::::::::::" + "contSolAcept " + contSolAcept + "contSolError " + arrErrores)
            };


            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((dataExcel) => {
            console.log("EXCEL", dataExcel);
        });
    };

    descargarInformeXLS = async () => {
        this.setState({ loaderExcel: true });
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Visto en pantalla');
        let cols = [...this.columnasExcel];
        worksheet.columns = [...this.headersExcel];
        if (!this.state.buscador || this.state.buscador === '') {
            console.log("está vacío");
            try {
                let data = {
                    pageNumber: this.state.page + 1,
                    pageSize: this.state.pageSize
                }
                let response = await axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/report/exelVacante', { data });
                response.data.data.map((respuesta) => {
                    respuesta.horario = respuesta.horario ? respuesta.horario.split(' ').join(' - ') : '';
                    worksheet.addRow({ ...respuesta }, 'n');
                });
            } catch (e) {
                console.error(e);
            }
        } else {
            console.log("está lleno");
            try {
                let data = {
                    cadena: this.state.buscador,
                };
                let response = await axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/report/exelVacantePantalla', { data });
                console.log('REPONSE = ', response)
                response.data.data.Busqueda.map((respuesta) => {
                    worksheet.addRow({ ...respuesta }, 'n');
                });
            } catch (e) {
                console.error(e);
            }
        }

        for (let i = 0; i < cols.length; i++) {
            worksheet.getCell(cols[i] + 1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '4da357' },
            };
        }

        workbook.xlsx.writeBuffer().then((data) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let blob = new Blob([data], { type: fileType });
            const key = `Reporte Vacantes.xlsx`;
            FileSaver.saveAs(blob, key);
            this.setState({ loaderExcel: false });
        });
    }


    layoutErrorCargaMasiva = async (registros) => {
        console.log("[Layout Errores]", registros);
        let registrosCopy = {...registros[0]};
        console.log(registrosCopy);
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Errores');
        let cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
        worksheet.columns = [
            { header: 'ID SUCURSAL', key: 'A', width: 15 },
            { header: 'VACANTES FIJAS', key: 'B', width: 15 },
            { header: 'HORA DE ENTRADA', key: 'C', width: 20 },
            { header: 'VACANTES TEMPORALES', key: 'D', width: 26 },
            { header: 'HORA DE ENTRADA', key: 'E', width: 20 },
            { header: 'TEMPORALIDAD', key: 'F', width: 15 },
            { header: 'FECHA DE INGRESO', key: 'G', width: 20 },
        ];
        worksheet.addRow({ ...registros[0] }, 'n');
        for (let i = 0; i < cols.length; i++) {
            if(registrosCopy[cols[i]] == 'Invalid date'  || registrosCopy[cols[i]] == ''){
                worksheet.getCell(cols[i] + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'f44336' },
                }; 
            } else{
                worksheet.getCell(cols[i] + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '4da357' },
                };

            }

        }
        

        workbook.xlsx.writeBuffer().then((data) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let blob = new Blob([data], { type: fileType });
            const key = `Layout Errores.xlsx`;
            FileSaver.saveAs(blob, key);
            this.setState({ loaderExcel: false });
        });
    }

    render() {
        let rows = [];

        if (this.state.listadoSucursalesJson.length > 0) {
            for (let i = 0; i < this.state.listadoSucursalesJson.length; i++) {
                //console.log("DATA:" + i + " : ", this.state.solicitudesEntrantes[i]);
                let element = {
                    id: i + 1,
                    numSucursal: this.state.listadoSucursalesJson[i].fcSucursal,
                    nombreSucursal: this.state.listadoSucursalesJson[i].nombreSucursal,
                    vacantes: this.state.listadoSucursalesJson[i].vacantesProceso,
                    fechaIngreso: this.state.listadoSucursalesJson[i].fechaIngreso,
                    activos: this.state.listadoSucursalesJson[i].promotoresActivos,
                    fcSucursal: this.state.listadoSucursalesJson[i].fcSucursal,
                    idSucursal: this.state.listadoSucursalesJson[i].idSucursal,
                    bajasProceso: this.state.listadoSucursalesJson[i].bajasProceso
                }
                rows.push(element);
                //console.log('Element: ', element);
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-1">
                        <div className="input-group mb-3">
                            <input type="text"
                                className="form-control bg-search"
                                placeholder="&#xf002; Buscar"
                                onChange={this.iniciarBuscador}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-1 offset-lg-4">
                        <Usuario />
                    </div>
                </div>
                <div className="row">
                    <div className="FondoBlanco mt-3 p-5">
                        <div className="row mt-3">
                            <div>
                                <div className="row">
                                    <div className="col col-lg-6">
                                        <h3>Sucursales</h3>
                                    </div>
                                    <div className="col col-lg-6 div-btns text-end">
                                        <Link to="/vacantes/nueva">
                                            <button id="bntCrearSolicitud" className="btn btn-success">Crear Solicitud</button>
                                        </Link>
                                        <button type="button" onClick={this.showSolicitudesMasivas}
                                            className="btn btn-outline-success xls_logo_img"><i
                                                className="fa fa-files-o espaciador" aria-hidden="true" />Solicitudes masivas
                                        </button>
                                        <Popup trigger={<img src={excel_logo} alt="" className='xls_logo_img m-over' />} closeOnDocumentClick={false} modal nested>
                                            {close => (
                                                <div>
                                                    <div className="col col-lg-12" style={{ display: this.state.loaderExcel ? "block" : "none" }}>
                                                        <div className="margin-loader">
                                                            <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                    <div className='mt-4' style={{ display: this.state.loaderExcel ? "none" : "block" }}>
                                                        {this.state.popPanel ? <div>
                                                            <div className="row">
                                                                <div className="col text-center">
                                                                    <p className='titulopopreporte text-justify'>Elige lo que quieras
                                                                        descargar</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="botonContenedorL m-over" onClick={() => {
                                                                        this.descargarInformeXLS();
                                                                    }}>
                                                                        <i className="fa fa-desktop popDesktop" aria-hidden="true"></i><br />
                                                                        <p className="texticopop">Visto en<br />pantalla</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="botonContenedorR m-over" onClick={() => {
                                                                        this.setState({ popPanel: false });
                                                                    }}>
                                                                        <i className="fa fa-calendar popDesktop" aria-hidden="true"></i><br />
                                                                        <p className="texticopop">Reporte<br />General</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 mb-2">
                                                                <div className="col text-center">
                                                                    <button type="button" className="btn btn-outline-success" onClick={() => {
                                                                        this.setState({ ...this.state, calendarRange: this.dateRangeInit })
                                                                        this.setState({ popPanel: true });
                                                                        close();
                                                                    }}>Cancelar
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div> : <div></div>}
                                                        {this.state.popPanel ? <div></div> : <div>
                                                            <div className="row">
                                                                <div className="col text-center">
                                                                    <p className='titulopopreporte text-justify'>Elige el rango de fechas para
                                                                        tu descarga (hasta 7 días)</p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="botonContenedorCal d-flex justify-content-center">
                                                                        <DateRange
                                                                            onChange={item => {
                                                                                console.log('ITEM = ', item);
                                                                                this.setState({ ...this.state, calendarRange: [item.selection] });
                                                                                this.setState({ disableBtn: false });
                                                                                this.setState({ minDate: new Date(addDays(item.selection.startDate, -6)) })

                                                                                if ((item.selection.startDate).toLocaleDateString() === (addDays(new Date(), 0)).toLocaleDateString() ||
                                                                                    (item.selection.startDate).toLocaleDateString() === (addDays(new Date(), -1)).toLocaleDateString() ||
                                                                                    (item.selection.startDate).toLocaleDateString() === (addDays(new Date(), -2)).toLocaleDateString() ||
                                                                                    (item.selection.startDate).toLocaleDateString() === (addDays(new Date(), -3)).toLocaleDateString() ||
                                                                                    (item.selection.startDate).toLocaleDateString() === (addDays(new Date(), -4)).toLocaleDateString() ||
                                                                                    (item.selection.startDate).toLocaleDateString() === (addDays(new Date(), -5)).toLocaleDateString() ||
                                                                                    (item.selection.startDate).toLocaleDateString() === (addDays(new Date(), -6)).toLocaleDateString()) {
                                                                                    this.setState({ maxDate: new Date() })
                                                                                } else {
                                                                                    this.setState({ maxDate: new Date(addDays(item.selection.startDate, 6)) })
                                                                                    //setMaxCalendarRange(new Date(addDays(item.selection.startDate, 6)))
                                                                                }

                                                                                /*console.log(this.state.calendarRange);
                                                                                let add = addDays(item.selection.startDate, 6);
                                                                                console.log('add = ', add)
                                                                                let endDate = moment(add);
                                                                                let now = moment(new Date());
                                                                                let diff = endDate.diff(now, 'days');
                                                                                console.log('DIFF = ', diff);
                                                                                this.setState({...this.state, maxRangeDate: add}); */
                                                                            }}
                                                                            moveRangeOnFirstSelection={false}
                                                                            ranges={this.state.calendarRange}
                                                                            minDate={this.state.minDate}
                                                                            maxDate={this.state.maxDate}
                                                                            //maxDate={new Date(addDays(this.state.calendarRange[0].startDate, 6))}
                                                                            locale={Locales.es}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 mb-3">
                                                                <div className="col"></div>
                                                                <div className="col d-flex flex-row-reverse">
                                                                    <button type="button" className="btn btn-outline-success" onClick={() => {
                                                                        this.setState({ ...this.state, calendarRange: this.dateRangeInit })
                                                                        this.setState({ popPanel: true });
                                                                    }}>Cancelar
                                                                    </button>
                                                                </div>
                                                                <div className="col text-center">
                                                                    <button type="button" className="btn btn-outline-success" onClick={() => {
                                                                        //this.setState({...this.state, calendarRange: this.dateRangeInit, disableBtn: true, maxRangeDate: new Date()});
                                                                        this.setState({ minDate: new Date('01-01-2021') })
                                                                        this.setState({ maxDate: new Date() })
                                                                        this.setState({ calendarRange: this.dateRangeInit })
                                                                    }}>Limpiar
                                                                    </button>
                                                                </div>
                                                                <div className="col d-flex flex-row">
                                                                    <button type="button" className="btn btn-outline-success" onClick={() => {
                                                                        this.generarReporte();
                                                                    }}>Aceptar
                                                                    </button>
                                                                </div>
                                                                <div className="col"></div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                        <div className="col-12 mt-3"
                                            style={{ display: this.state.btnSolicitudesMasivas ? "block" : "none" }}>
                                            <a href="https://s3.amazonaws.com/dev.trader.vacantes.files/layouts/Layout_vacantes.xlsx"
                                                download="Layout vacantes">
                                                <button className="btn btn-ba">Descargar formato</button>
                                            </a>
                                            <button onClick={() => fileInputRef.current.click()}
                                                className="btn btn-success" style={{ marginLeft: 5 }}>Subir archivo
                                            </button>
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    const archivo = e.target.files[0];
                                                    this.uploadExcel(archivo);
                                                    // this.readExcel(archivo);
                                                }}
                                                ref={fileInputRef}
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                hidden
                                            />
                                            <Tooltip
                                                title="Descarga el formato, ingresa los campos solicitados y posteriormente sube el archivo.">
                                                <button className="btn btn-gray btn-question"><i
                                                    className="fa fa-question fa-lg" /></button>
                                            </Tooltip>


                                            {/*<div className="col-12 mt-3" style={{ display: this.state.btnSolicitudesMasivas ? "block" : "none" }}>
                        <a href="https://s3.amazonaws.com/dev.traderinc.files/vacantes/Layout_vacantes.xlsx" download="Layout vacantes"><button className="btn btn-ba" >Descargar formato</button></a>
                        <button className="btn btn-success" style={{ marginLeft: 5 }} >Subir archivo</button>

                      </div>*/}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col col-lg-12" style={{ display: this.state.loader ? "none" : "block" }}>
                                            <div style={{ height: 'auto', width: 'auto', padding: '0 45px' }}>
                                                <DataGrid
                                                    autoHeight={true}
                                                    disableSelectionOnClick
                                                    rows={rows}
                                                    rowCount={this.state.totalRows}
                                                    columns={columns}
                                                    page={this.state.page}
                                                    onPageChange={(newPage) => {
                                                        const param = newPage
                                                        this.setState({ ...this.state, page: param });
                                                        this.obtenerListadoSucursales(param + 1);
                                                    }}
                                                    paginationMode="server"
                                                    pageSize={this.state.pageSize}
                                                    onPageSizeChange={(newPageSize) => {
                                                        this.setState({ ...this.state, pageSize: newPageSize });
                                                    }}
                                                    rowsPerPageOptions={[this.state.pageSize]}
                                                    components={{
                                                        NoRowsOverlay: () => (
                                                            <Stack height="100%" alignItems="center"
                                                                justifyContent="center">
                                                                No se encontraron resultados que coincidan con tu
                                                                búsqueda
                                                            </Stack>
                                                        ),
                                                        NoResultsOverlay: () => (
                                                            <Stack height="100%" alignItems="center"
                                                                justifyContent="center">
                                                                No se encontraron resultados que coincidan con tu
                                                                búsqueda
                                                            </Stack>
                                                        )
                                                    }} />
                                                <div className="offset-8 col-4" style={{ display: this.state.showProgress ? "block" : "none" }}>
                                                    <div className="progress text-end">
                                                        <div className="progress-bar bg-success" role="progressbar"
                                                            style={{ width: "50%" }} aria-valuenow="25" aria-valuemin="0"
                                                            aria-valuemax="100"> </div>
                                                    </div>
                                                    <div className="text-end">
                                                        <label className="lbl-pb">Subiendo archivo 50%</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col col-lg-12" style={{ display: this.state.loader ? "block" : "none" }}>
                                            <div className="margin-loader">
                                                <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoSucursales;
