export const dev = {
    url: 'https://4q4fia1b28.execute-api.us-east-1.amazonaws.com/dev',
    url_operativo: 'https://f0g8vges32.execute-api.us-east-1.amazonaws.com/dev'
};

export const qa = {
    url: 'https://igg9ncp5pl.execute-api.us-east-1.amazonaws.com/v1',
    url_operativo: 'https://pkzpazs9wc.execute-api.us-east-1.amazonaws.com/v1'
};

export const prod = {
    url: 'https://api.vacantes.cliente.trader.inc',
    url_operativo: 'https://api.vacantes.operacion.trader.inc',
    //url: 'https://c61owi1onl.execute-api.us-east-1.amazonaws.com/v1',
    //url_operativo: 'https://bcgni5vst3.execute-api.us-east-1.amazonaws.com/v1'
};

export default dev;