import React, { useState } from "react";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import excel_logo from '../Assets/img/excel_icon.png'
import {DateRange} from "react-date-range";
import * as Locales from "react-date-range/dist/locale";
import Popup from "reactjs-popup";
import {Workbook} from "exceljs";
import axios from "axios";
import * as environment from "../Global";
import moment from "moment";
import {addDays} from "date-fns";

export const ExportCSV = ({type, buscador, csvData, fileName}) => {
    const dateRangeInit = [
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: '#4da357',
            showDateDisplay: false
        }
    ];

    const columnasExcelBajas = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    const headersExcelBajas = [
        { header: 'Folio de la solicitud', key: 'idSolicitud', width: 19},
        { header: 'Estado de la solicitud', key: 'statusSolicitud', width: 20},
        { header: 'ID de la sucursal', key: 'idSucursal', width: 16},
        { header: 'Nombre de la sucursal', key: 'sucursalName', width: 22},
        { header: 'Dirección de la sucursal', key: 'direccion_suc', width: 30},
        { header: 'Nombre del responsable', key: 'responsable', width: 25},
        { header: 'Número de bajas', key: 'numBajas', width: 15},
        { header: 'Fecha tentativa de baja', key: 'fechaTentativa', width: 24},
        { header: 'ID del Promotor', key: 'idUsuarioVacante', width: 15},
        { header: 'Nombre del promotor', key: 'nombreUsuario', width: 23}
    ];

    const columnasExcel = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
    const headersExcel = [
        { header: 'Folio de la solicitud', key: 'idSolicitud', width: 19},
        { header: 'Estado de la solicitud', key: 'statusSolicitud', width: 20},
        { header: 'ID de la sucursal', key: 'idSucursal', width: 16},
        { header: 'Nombre de la sucursal', key: 'sucursalName', width: 25},
        { header: 'Dirección de la sucursal', key: 'direccion_suc', width: 35},
        { header: 'Nombre del responsable', key: 'responsable', width: 25},
        { header: 'ID Vacante', key: 'idUsuarioVacante', width: 13},
        { header: 'Tipo de vacante', key: 'tipoVacante', width: 15},
        { header: 'Horario', key: 'horario', width: 15},
        { header: 'Temporalidad', key: 'temporalidad', width: 15},
        { header: 'Fecha tentativa de ingreso', key: 'fechaTentativa', width: 24},
        { header: 'ID de ingreso', key: 'numEmpleado', width: 15},
        { header: 'Nombre del candidato', key: 'nombreUsuario', width: 23},
        { header: 'Estatus del candidato', key: 'statusUsuario', width: 20}
    ];

    const [loaderExcel, setLoaderExcel] = useState(false);
    const [popPanel, setPopPanel] = useState(true);
    const [calendarRange, setCalendarRange] = useState(dateRangeInit);
    const [minDate, setminDate] = useState(new Date('01-01-2021'));
    const [maxCalendarRange, setMaxCalendarRange] = useState(new Date());

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let filePath = 'Reporte';
    let sheetName = '';

    const createFileName = () => {
        switch (type) {
            case 1:
                filePath = 'Reporte de solicitudes entrantes' + fileExtension;
                sheetName = 'Solicitudes entrantes'
                break;
            case 2:
                filePath = 'Reporte de solicitudes tomadas' + fileExtension;
                sheetName = 'solicitudes tomadas';
                break;
            case 3:
                filePath = 'Reporte de solicitudes completas' + fileExtension;
                sheetName = 'Solicitudes completas';
                break;
            case 4:
                filePath = 'Reporte de solicitudes finalizadas' + fileExtension;
                sheetName = 'Solicitudes finalizadas';
                break;
            case 27:
                filePath = 'Reporte de bajas entrantes' + fileExtension;
                sheetName = 'Bajas entrantes';
                break;
            case 28:
                filePath = 'Reporte de bajas confirmadas' + fileExtension;
                sheetName = 'Bajas confirmadas'
                break;
        }
    }

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Solicitudes': ws }, SheetNames: ['Solicitudes'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const descargarInformeXLS = async () => {
        setLoaderExcel(true);
        createFileName();
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet(sheetName);
        let cols = [];

        if(type === 27 || type === 28) {
            cols = [...columnasExcelBajas];
            worksheet.columns = headersExcelBajas
        } else {
            cols = [...columnasExcel];
            worksheet.columns = headersExcel;
        }

        if (!buscador || buscador === '') {
            try {
                let data = {}
                let key = 'data';
                let url = environment[process.env.REACT_APP_ENVIRONMEN].url_operativo;
                if(type === 27 || type === 28) {
                    url += '/loss-promoters/excelBajas';
                    key = 'DescargaGral';
                    data = {
                        id_status: type
                    }
                } else {
                    url += '/solicits/descargaExcel';
                    data = {
                        idStatus: type
                    }
                }

                let response =  await axios.post(url, {data});
                if(type === 27 || type === 28) {
                    response.data.data[key].map((respuesta) => {
                        respuesta.horario = respuesta.horario ? respuesta.horario.split(' ').join(' - ') : '';
                        worksheet.addRow({...respuesta}, 'n');
                    });
                } else {
                    response.data[key].map((respuesta) => {
                        respuesta.horario = respuesta.horario ? respuesta.horario.split(' ').join(' - ') : '';
                        worksheet.addRow({...respuesta}, 'n');
                    });
                }

            } catch (e) {
                console.error(e);
            }
        } else {
            try {
                let data = {
                    cadena: buscador,
                    id_status: type
                };

                let url = environment[process.env.REACT_APP_ENVIRONMEN].url_operativo;
                if(type === 27 || type === 28) {
                    url += '/loss-promoters/excelPantallaBajas';
                } else {
                    url += '/loss-promoters/excelPantallaVacantes';
                }

                let response = await axios.post(url, {data});
                console.log('response = ', response);
                response.data.data.DescargaGral.map((respuesta) => {
                    respuesta.horario = respuesta.horario ? respuesta.horario.split(' ').join(' - ') : '';
                    worksheet.addRow({...respuesta}, 'n');
                });
            } catch (e) {
                console.error(e);
            }
        }

        for (let i = 0; i < cols.length; i++) {
            worksheet.getCell(cols[i] + 1).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'4da357'},
            };
        }

        workbook.xlsx.writeBuffer().then((data) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let blob = new Blob([data], { type: fileType });
            FileSaver.saveAs(blob, filePath);
            setLoaderExcel(false);
        });
    }

    const generarReporte = () => {
        setLoaderExcel(true);
        const initDate = calendarRange[0].startDate;
        const endDate = calendarRange[0].endDate;
        let data = {
            fecha_inicio: moment(initDate).format('DD/MM/YYYY') + ' 00:00:00',
            fecha_final: moment(endDate).format('DD/MM/YYYY') + ' 23:59:00',
            id_status: type
        }
        let url = environment[process.env.REACT_APP_ENVIRONMEN].url;
        let key = 'data';
        if(type === 27 || type === 28) {
            url += '/report/exelfechasbajas';
            key = 'DescargaGral';
        } else {
            url += '/report/exelfechasvacante';
            key = 'ExcelFechas'
        }
        axios.post(url, {data})
            .then(response => {
                let workbook = new Workbook();
                let worksheet = workbook.addWorksheet('Reporte General');
                let cols = [];
                let nameFile = 'Reporte General';
                if(type === 27 || type === 28) {
                    cols = [...columnasExcelBajas];
                    worksheet.columns = headersExcelBajas
                    nameFile = nameFile + ' Bajas';
                } else {
                    cols = [...columnasExcel];
                    worksheet.columns = headersExcel;
                }

                response.data.data[key].map((respuesta) => {
                    respuesta.horario = respuesta.horario ? respuesta.horario.split(' ').join(' - ') : '';
                    worksheet.addRow({...respuesta}, 'n');
                });

                for (let i = 0; i < cols.length; i++) {
                    worksheet.getCell(cols[i] + 1).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'4da357'},
                    };
                }

                workbook.xlsx.writeBuffer().then((data) => {
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    let blob = new Blob([data], { type: fileType });
                    const key = `${nameFile} ${moment(initDate).format('DD-MM-YYYY')} al ${moment(endDate).format('DD-MM-YYYY')}.xlsx`;
                    FileSaver.saveAs(blob, key);
                    setLoaderExcel(false);
                });
            });
    }

    return (
        <div>
            {type !== 0 ?
                <Popup trigger={<img src={excel_logo} alt="" className='xls_logo_img m-over'/>} closeOnDocumentClick={false}
                       modal nested>
                    {close => (
                        <div>
                            <div className="col col-lg-12" style={{display: loaderExcel ? "block" : "none"}}>
                                <div className="margin-loader">
                                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw"/>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div className='mt-4' style={{display: loaderExcel ? "none" : "block"}}>
                                {popPanel ? <div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <p className='titulopopreporte text-justify'>Elige lo que quieras
                                                descargar</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="botonContenedorL m-over" onClick={() => {
                                                descargarInformeXLS();
                                            }}>
                                                <i className="fa fa-desktop popDesktop" aria-hidden="true"></i><br/>
                                                <p className="texticopop">Visto en<br/>pantalla</p>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="botonContenedorR m-over" onClick={() => {
                                                setPopPanel(false);
                                            }}>
                                                <i className="fa fa-calendar popDesktop" aria-hidden="true"></i><br/>
                                                <p className="texticopop">Reporte<br/>General</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-2">
                                        <div className="col text-center">
                                            <button type="button" className="btn btn-outline-success" onClick={() => {
                                                setCalendarRange(dateRangeInit)
                                                setminDate(new Date('01-01-2021'));
                                                setMaxCalendarRange(new Date())
                                                setPopPanel(true);
                                                close()
                                            }}>Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div> : <div></div>}
                                {popPanel ? <div></div> : <div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <p className='titulopopreporte text-justify'>Elige el rango de fechas para
                                                tu descarga (hasta 7 días)</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="botonContenedorCal d-flex justify-content-center">
                                                <DateRange

                                                    onChange={item => {
                                                        //console.log("iteeeeeem",item)
                                                        setCalendarRange([item.selection]);
                                                        setminDate(new Date(addDays(item.selection.startDate, -6)))

                                                        if ((item.selection.startDate).toLocaleDateString() === (addDays(new Date(),0)).toLocaleDateString() ||
                                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-1)).toLocaleDateString() ||
                                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-2)).toLocaleDateString() ||
                                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-3)).toLocaleDateString() ||
                                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-4)).toLocaleDateString() ||
                                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-5)).toLocaleDateString() ||
                                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-6)).toLocaleDateString()){
                                                            setMaxCalendarRange(new Date())
                                                        }else{
                                                            setMaxCalendarRange(new Date(addDays(item.selection.startDate, 6)))
                                                        }
                                                        //console.log(new Date(addDays(calendarRange[0].startDate, 0)));
                                                    }}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={calendarRange}
                                                    minDate={minDate}
                                                    maxDate={maxCalendarRange}
                                                    locale={Locales.es}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col"></div>
                                        <div className="col d-flex flex-row-reverse">
                                            <button type="button" className="btn btn-outline-success" onClick={() => {
                                                setCalendarRange(dateRangeInit);
                                                setPopPanel(true);
                                            }}>Cancelar
                                            </button>
                                        </div>
                                        <div className="col text-center">
                                            <button type="button" className="btn btn-outline-success" onClick={() => {
                                                setCalendarRange(dateRangeInit);
                                                setminDate(new Date('01-01-2021'))
                                                setMaxCalendarRange(new Date());
                                            }}>Limpiar
                                            </button>
                                        </div>
                                        <div className="col d-flex flex-row">
                                            <button type="button" disabled={calendarRange[0].endDate ? false : true} className="btn btn-outline-success" onClick={() => {
                                                generarReporte();
                                            }}>Aceptar
                                            </button>
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    )}
                </Popup>
                :
                <img onClick={(e) => exportToCSV(csvData, fileName)} className="excel-icon m-over" src={excel_logo}
                     alt="excel icon"/>
            }
        </div>
    )
}
