import React, { Component } from 'react';
import Router from "./Router";
import Login from './ComponentesGenerales/Login';
import './App.css';
import './Assets/css/color.css';

class PortalVacantesContainer extends Component {
    render() {
        return (
           
            <Router />
        );
    }
}
export default PortalVacantesContainer;