
import React from 'react';
import { NavLink } from "react-router-dom";


class Sidebar extends React.Component {
    render() {
        console.log("Ruta: ", window.location.pathname);
        if (window.location.pathname.includes("/vacantes") || window.location.pathname.includes("/bajaPromotores")) {
            return (
                <div className="d-grid gap-2">
                    <ul className="nav nav-pills flex-column mb-auto">


                        <NavLink to="/vacantes" className="nav-link" >
                            <i className="fa fa-user-plus espaciador" aria-hidden="true" />Vacantes
                        </NavLink>
                        <br />
                        <NavLink to="/bajaPromotores" className="nav-link">
                            <i className="fa fa-users espaciador" aria-hidden="true" />Baja promotores
                        </NavLink>

                        {/*<NavLink to="/cancelaciones" className="nav-link">
                        <i className="fa fa-user-plus espaciador" aria-hidden="true"></i>Cancelaciones
                    </NavLink>*/}
                        <br />
                    </ul>
                </div>
            );
        }
        else {
            return (
                <div className="d-grid gap-2">
                    <ul className="nav nav-pills flex-column mb-auto">

                        <NavLink to="/solicitudes" className="nav-link">
                            <i className="fa fa-user-plus espaciador" aria-hidden="true" />Solicitudes
                        </NavLink>
                        <br />{/*
                        <NavLink to="/cancelaciones" className="nav-link">
                            <i className="fa fa-window-close espaciador" aria-hidden="true" />Cancelaciones
                        </NavLink>
                        <br />*/}
                        <NavLink to="/bajapromotores/entrantes/" className="nav-link">
                            <i className="fa fa-users espaciador" aria-hidden="true" />Baja de promotores
                        </NavLink>
                        <br />
                    </ul>
                </div>
            );

        }

    }
}

export default Sidebar;