import React from 'react';
import 'moment/locale/es';
import axios from 'axios';
import Usuario from '../ComponentesGenerales/Usuario';
import { Link } from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import {Stack} from "@mui/material";
import excel_logo from '../Assets/img/excel_icon.png';
import {DateRange} from "react-date-range";
import * as Locales from "react-date-range/dist/locale";
import Popup from "reactjs-popup";
import { Workbook } from "exceljs";
import * as environment from "../Global";
import * as FileSaver from 'file-saver';
import moment from "moment";
import {addDays} from "date-fns";

const columns = [
  { field: 'numSucursal', headerName: 'NÚMERO DE SUCURSAL', width: 140 },
  { field: 'nombreSucursal', headerName: 'NOMBRE DE LA SUCURSAL', width: 250 },
  { field: 'activos', headerName: 'PROMOTORES ACTIVOS', width: 160 },
  { field: 'vacantes', headerName: 'VACANTES EN PROCESO', width: 140 },
  { field: 'bajasProceso', headerName: 'BAJAS EN PROCESO', width: 140 },
  
  {
    field: 'detail', headerName: '', renderCell: (cellValues) => {
      return (
          <i className="fa fa-eye m-over" aria-hidden="true" onClick={(e)=>{
            //console.log("Cellvalues", cellValues.row);
            window.location.href = "/bajaPromotores/bajasbajas/" + cellValues.row.fcSucursal + "/" + cellValues.row.idSucursal;
          }} />
      );
    },
  }
];

class ListadoBajas extends React.Component {

  dateRangeInit = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#4da357'
    }
  ];

  state = {
    listadoSucursalesJson: [],
    status: null,
    loader: false,
    disableBtn: true,
    popPanel: true,
    buscador: '',
    showProgress: false,
    pageSize: 25,
    page: 0,
    totalRows: 0,
    loaderExcel: false,
    maxRangeDate: new Date(),
    calendarRange: this.dateRangeInit,
    minDate: new Date('01-01-2021'),
    maxDate: new Date()
  };

  columnasExcel = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  headersEcel = [
    { header: 'Folio de la solicitud', key: 'idSolicitud', width: 19},
    { header: 'Estado de la solicitud', key: 'statusSolicitud', width: 20},
    { header: 'ID de la sucursal', key: 'idSucursal', width: 16},
    { header: 'Nombre de la sucursal', key: 'sucursalName', width: 25},
    { header: 'Dirección de la sucursal', key: 'direccion_suc', width: 30},
    { header: 'Nombre del responsable', key: 'responsable', width: 25},
    { header: 'Número de bajas', key: 'numBajas', width: 15},
    { header: 'Fecha tentativa de baja', key: 'fechaTentativa', width: 24},
    { header: 'ID del promotor', key: 'idUsuarioVacante', width: 15},
    { header: 'Nombre del promotor', key: 'nombreUsuario', width: 23}
  ];

  iniciarBuscador = (e) => {
    this.setState({...this.state, buscador: e.target.value});
    var sucursales = this.state.listadoSucursalesJson;

    if(e.target.value === ""){
      this.obtenerListadoSucursales();
    }
    else{
      var filtro = sucursales.filter((sucursal)=> sucursal['nameSucursal'].includes(e.target.value.toUpperCase()));
      if(filtro.length === 0){
        filtro = sucursales.filter((sucursal)=> sucursal['numEconomico'].includes(e.target.value.toUpperCase()));
      }
      //if(filtro.length === 0){
        //filtro = sucursales.filter((sucursal)=> sucursal['fechaIngreso'].includes(e.target.value));
      //}
      // if(filtro.length == 0){
      //   filtro = sucursales.filter((sucursal)=> sucursal['vacantes'].includes(e.target.value));
      // }
      // if(filtro.length == 0){
      //   filtro = sucursales.filter((sucursal)=> sucursal['promotoresActivos'].includes(parseInt(e.target.value)));
      // }
      console.log(filtro);
      this.setState({listadoSucursalesJson: filtro});
      console.log("Largo" + this.state.listadoSucursalesJson.length);
    }
    
  }

  descargarListado = () => {
    //alert('click a descargar listado');
  }

  descargarInformeXLS = async () => {
    this.setState({loaderExcel: true});
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Visto en pantalla');
    let cols = [...this.columnasExcel];
    worksheet.columns = [...this.headersEcel];
    if (!this.state.buscador || this.state.buscador === '') {
      console.log("está vacío");
      try {
        let data = {
          pageNumber: this.state.page + 1,
          pageSize: this.state.pageSize
        }
        let response =  await axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/report/exelBajasCliente', {data});
        response.data.data.map((respuesta) => {
          worksheet.addRow({...respuesta}, 'n');
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      console.log("está lleno");
      try {
        let data = {
          cadena: this.state.buscador
        };
        let response = await axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/report/exelBajasPantalla', {data});
        console.log(response)
        response.data.data.Busqueda.map((respuesta) => {
          worksheet.addRow({...respuesta}, 'n');
        });
      } catch (e) {
        console.error(e);
      }
    }

    for (let i = 0; i < cols.length; i++) {
      worksheet.getCell(cols[i] + 1).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'4da357'},
      };
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let blob = new Blob([data], { type: fileType });
      const key = `Reporte baja promotores.xlsx`;
      FileSaver.saveAs(blob, key);
      this.setState({loaderExcel: false});
    });
  }

  generarReporte = () => {
    this.setState({loaderExcel: true});
    const initDate = this.state.calendarRange[0].startDate;
    const endDate = this.state.calendarRange[0].endDate;
    let data = {
      fecha_inicio: moment(initDate).format('DD/MM/YYYY') + ' 00:00:00',
      fecha_final: moment(endDate).format('DD/MM/YYYY') + ' 23:59:00'
    }
    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url_operativo + '/loss-promoters/excelBajasGral', {data})
        .then(response => {
          console.log('DATOS RANGO FECHA = ', response);
          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet('Bajas promotores');
          let cols = [...this.columnasExcel];
          worksheet.columns = [...this.headersEcel];
          response.data.data.DescargaGral.map((respuesta) => {
            respuesta.horario = respuesta.horario ? respuesta.horario.split(' ').join(' - ') : '';
            worksheet.addRow({...respuesta}, 'n');
          });

          for (let i = 0; i < cols.length; i++) {
            worksheet.getCell(cols[i] + 1).fill = {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'4da357'},
            };
          }

          workbook.xlsx.writeBuffer().then((data) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let blob = new Blob([data], { type: fileType });
            const key = `Reporte General Bajas ${moment(initDate).format('DD-MM-YYYY')} al ${moment(endDate).format('DD-MM-YYYY')}.xlsx`;
            FileSaver.saveAs(blob, key);
            this.setState({loaderExcel: false});
          });
        });
  }

  obtenerListadoSucursales = () => {
    this.setState({loader: true})
    axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/loss-promoters/listaBajasProceso',{
      data:
          {
            "pageNumber": 1,
            "pageSize": 25

          }
    })
      .then(response => {
        console.log("respuesta datos", response.data);
        this.setState({
          listadoSucursalesJson: response.data.data,
          status: 'success',
          loader: false
        });
      });
  }

  componentDidMount() {
    this.obtenerListadoSucursales();
  }

  render() {

    var rows = [];

    if (this.state.listadoSucursalesJson.length > 0) {
      for (let i = 0; i < this.state.listadoSucursalesJson.length; i++) {
        //console.log("DATA:" + i + " : ", this.state.solicitudesEntrantes[i]);
        var element = {
          id: i + 1,
          numSucursal: this.state.listadoSucursalesJson[i].numEconomico,
          nombreSucursal: this.state.listadoSucursalesJson[i].nameSucursal,
          vacantes: this.state.listadoSucursalesJson[i].nunVacantes,
          fechaIngreso: this.state.listadoSucursalesJson[i].fechaIngreso,
          activos: this.state.listadoSucursalesJson[i].promActivos,
          fcSucursal: this.state.listadoSucursalesJson[i].numEconomico,
          idSucursal: this.state.listadoSucursalesJson[i].idSucursal,
          bajasProceso: this.state.listadoSucursalesJson[i].numBajas
        }
        rows.push(element);
        //console.log('Element: ', element);
      }
    }
    console.log("Rows lenght " + rows.length);
    return (<div>
      <div className="row">
        <div className="col col-lg-6 offset-lg-1">
          <div className="input-group mb-3">
            <input type="text"
              className="form-control bg-search"
              placeholder="&#xf002; Buscar"
              onChange={this.iniciarBuscador}
            />
          </div>
        </div>
        <div className="col col-lg-1 offset-lg-4">
          <Usuario />
        </div>
      </div>
      <div className="row">
        <div className="FondoBlanco mt-3 p-5">
          <div className="row mt-3">
            <div>
              <div className="row">
                <div className="col col-lg-6">
                  <h3>Sucursales</h3>
                </div>
                <div className="col col-lg-4 offset-lg-2 div-btns">
                  {/*<button id="btnDescargar" type="button" className="btn btn-outline-success" onClick={this.descargarListado}><i className="fa fa-arrow-down espaciador" aria-hidden="true"></i>Descargar</button>*/}
                  <Link to="/bajaPromotores/nuevaBaja"><button id="bntCrearSolicitud" className="btn btn-success" >Crear Solicitud</button></Link>

                  <Popup trigger={<img src={excel_logo} alt="" className='xls_logo_img m-over'/>} closeOnDocumentClick={false} modal nested>
                    {close => (
                      <div>
                        <div className="col col-lg-12" style={{display: this.state.loaderExcel ? "block" : "none"}}>
                          <div className="margin-loader">
                            <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className='mt-4' style={{display: this.state.loaderExcel ? "none" : "block"}}>
                          {this.state.popPanel ? <div>
                            <div className="row">
                              <div className="col text-center">
                                <p className='titulopopreporte text-justify'>Elige lo que quieras
                                  descargar</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="botonContenedorL m-over" onClick={() => {
                                  this.descargarInformeXLS();
                                }}>
                                  <i className="fa fa-desktop popDesktop" aria-hidden="true"></i><br/>
                                  <p className="texticopop">Visto en<br/>pantalla</p>
                                </div>
                              </div>
                              <div className="col">
                                <div className="botonContenedorR m-over" onClick={() => {
                                  this.setState({popPanel: false});
                                }}>
                                  <i className="fa fa-calendar popDesktop" aria-hidden="true"></i><br/>
                                  <p className="texticopop">Reporte<br/>General</p>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4 mb-2">
                              <div className="col text-center">
                                <button type="button" className="btn btn-outline-success" onClick={() => {
                                  this.setState({...this.state, calendarRange: this.dateRangeInit})
                                  this.setState({popPanel: true});
                                  close()
                                }}>Cancelar
                                </button>
                              </div>
                            </div>
                          </div> : <div></div>}
                          {this.state.popPanel ? <div></div> : <div>
                            <div className="row">
                              <div className="col text-center">
                                <p className='titulopopreporte text-justify'>Elige el rango de fechas para
                                  tu descarga (hasta 7 días)</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="botonContenedorCal d-flex justify-content-center">
                                  <DateRange
                                      onChange={item => {
                                        this.setState({...this.state, calendarRange: [item.selection]});
                                        console.log(this.state.calendarRange);
                                        this.setState({minDate: new Date(addDays(item.selection.startDate, -6))})
                                        if ((item.selection.startDate).toLocaleDateString() === (addDays(new Date(),0)).toLocaleDateString() ||
                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-1)).toLocaleDateString() ||
                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-2)).toLocaleDateString() ||
                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-3)).toLocaleDateString() ||
                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-4)).toLocaleDateString() ||
                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-5)).toLocaleDateString() ||
                                            (item.selection.startDate).toLocaleDateString() === (addDays(new Date(),-6)).toLocaleDateString()){
                                          this.setState({maxDate: new Date()})
                                        }else{
                                          this.setState({maxDate: new Date(addDays(item.selection.startDate, 6)) })
                                          //setMaxCalendarRange(new Date(addDays(item.selection.startDate, 6)))
                                        }
                                        /* let initDate = new Date(this.state.calendarRange[0].startDate);
                                        const ini = new Date();
                                        let newEndDate = new Date();
                                        newEndDate = ini.setDate(initDate.getDate() + 7);
                                        this.setState({...this.state, maxRangeDate: newEndDate}); */
                                      }}
                                      moveRangeOnFirstSelection={false}
                                      ranges={this.state.calendarRange}
                                      minDate={this.state.minDate}
                                      maxDate={this.state.maxDate}
                                      //maxDate={new Date(addDays(this.state.calendarRange[0].startDate, 6))}
                                      locale={Locales.es}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col"></div>
                              <div className="col d-flex flex-row-reverse">
                                <button type="button" className="btn btn-outline-success" onClick={() => {
                                  this.setState({...this.state, calendarRange: this.dateRangeInit})
                                  this.setState({popPanel: true});
                                }}>Cancelar
                                </button>
                              </div>
                              <div className="col text-center">
                                <button type="button" className="btn btn-outline-success" onClick={() => {
                                  //this.setState({...this.state, calendarRange: this.dateRangeInit, disableBtn: true, maxRangeDate: new Date()});
                                  this.setState({minDate: new Date('01-01-2021')})
                                  this.setState({maxDate: new Date()})
                                  this.setState({calendarRange: this.dateRangeInit})
                                }}>Limpiar
                                </button>
                              </div>
                              <div className="col d-flex flex-row">
                                <button type="button" className="btn btn-outline-success" onClick={() => {
                                  this.generarReporte();
                                }}>Aceptar
                                </button>
                              </div>
                              <div className="col"></div>
                            </div>
                          </div>}
                        </div>
                      </div>
                    )}
                  </Popup>

                </div>
              </div>
              <div className="row mt-3">
                <div className="col col-lg-12" style={{display: this.state.loader ? "none" : "block"}}>
                  <div style={{ height: 'auto', width: 'auto', padding: '0 45px' }}>
                    <DataGrid
                        autoHeight={true}
                        disableSelectionOnClick
                        rows={rows}
                        columns={columns}
                        pageSize={25}
                        rowsPerPageOptions={[10]}
                        components={{
                          NoRowsOverlay: () => (
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                No se encontraron resultados que coincidan con tu búsqueda
                              </Stack>
                          ),
                          NoResultsOverlay: () => (
                              <Stack height="100%" alignItems="center" justifyContent="center">
                                No se encontraron resultados que coincidan con tu búsqueda
                              </Stack>
                          )
                        }} />
                  </div>
                </div>

                <div className="col col-lg-12" style={{display: this.state.loader ? "block" : "none"}}>
                  <div className="margin-loader">
                    <i className="fa fa-spinner fa-pulse color-green-ba fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
export default ListadoBajas;
