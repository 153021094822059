import React from 'react';
//import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as environment from "../Global";
import Swal from 'sweetalert2';
import Usuario from "../ComponentesGenerales/Usuario";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

//registerLocale("es", es);

var addDaysToDate = (date, days) => {
    var res = new Date(date);
    res.setDate(res.getDate() + days);
    return res;
}

class VacanteNuevaSolicitud extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            divDetalleSucursal: false,
            divFormularioNuevaSolicitud: false,
            divMostrarResumen: false,
            fechaHoy: new Date(),
            fecha: addDaysToDate(new Date(), 0),
            fechaView: addDaysToDate(new Date(), 7).getDate() + "/" + (addDaysToDate(new Date(), 7).getMonth() + 1) + "/" + addDaysToDate(new Date(), 7).getFullYear(),
            responsable: localStorage.getItem("username") + " " + localStorage.getItem("lastname"),
            sucursales: [],
            sucursalBuscada: "",
            suc_cp_suc: 0,
            suc_direccion_suc: "",
            suc_estado: "",
            suc_idSucursal: "",
            suc_municipio: "",
            suc_nameSucursal: "",
            suc_region: "",
            suc_territorio: "",
            suc_zona: "",
            numerovacantes: 0,
            vacantesfijos: 0,
            vacantestemporales: 0,
            horarionueve: 0,
            horarioonce: 0,
            horariodiez: 0,
            horariodoce: 0,
            horarionueveTemp: 0,
            horarioonceTemp: 0,
            horariodiezTemp: 0,
            horariodoceTemp: 0,
            selectsemanas: 0,
            mostrarAutocomplete: true,
            calendarioActivo: false,
            botonGuardarActivo: true
        }
    }
    sucursalObj = {
        idsucursal: 1001,
        nombre: 'La Luna',
        territorio: 'Riu Metro Sur',
        zonaregion: 'Ru Xochimilco',
        direccion: 'Periférico Sur 4000 Fuentes del Pedregal CP. 44000',
        municipio: 'Tlalpan',
        estado: 'CDMX'
    };

    /*Referencias */
    numVacantesFijoRef = React.createRef();
    horarioNueveRef = React.createRef();
    horarioOnceRef = React.createRef();
    horarioDiezRef = React.createRef();
    horarioDoceRef = React.createRef();
    buscarSucursal = React.createRef();
    numVacantesTemporalesRef = React.createRef();
    horarioNueveTempRef = React.createRef();
    horarioOnceTempRef = React.createRef();
    horarioDiezTempRef = React.createRef();
    horarioDoceTempRef = React.createRef();



    guardarNuevaSolicitud = (e) => {

        if (this.horarioNueveRef.current.value === "") {
            this.horarioNueveRef.current.value = 0;
        }
        if (this.horarioOnceRef.current.value === "") {
            this.horarioOnceRef.current.value = 0;
        }
        if (this.horarioDiezRef.current.value === "") {
            this.horarioDiezRef.current.value = 0;
        }
        if (this.horarioDoceRef.current.value === "") {
            this.horarioDoceRef.current.value = 0;
        }
        if (this.horarioNueveTempRef.current.value === "") {
            this.horarioNueveTempRef.current.value = 0;
        }
        if (this.horarioOnceTempRef.current.value === "") {
            this.horarioOnceTempRef.current.value = 0;
        }
        if (this.horarioDiezTempRef.current.value === "") {
            this.horarioDiezTempRef.current.value = 0;
        }
        if (this.horarioDoceTempRef.current.value === "") {
            this.horarioDoceTempRef.current.value = 0;
        }

        var fechaHoy = new Date();
        if (this.state.fecha < fechaHoy) {

            Swal.fire({
                position: 'top',
                icon: 'warning',
                title: "La fecha debe ser posterior al día de hoy",
                confirmButtonColor: "#4DA357",
                showConfirmButton: true
            })
        }
        else {

            var totalSumaVacantesFijo = parseInt(this.horarioNueveRef.current.value) + parseInt(this.horarioOnceRef.current.value) + parseInt(this.horarioDiezRef.current.value) + parseInt(this.horarioDoceRef.current.value);
            var totalSumaVacantesTemporal = parseInt(this.horarioNueveTempRef.current.value) + parseInt(this.horarioOnceTempRef.current.value) + parseInt(this.horarioDiezTempRef.current.value) + parseInt(this.horarioDoceTempRef.current.value);


            if ((totalSumaVacantesTemporal + totalSumaVacantesFijo) === 0) {

                Swal.fire({
                    position: 'top',
                    icon: 'warning',
                    title: "Necesitas añadir más vacantes para seguir seleccionando horarios",
                    confirmButtonColor: "#4DA357",
                    showConfirmButton: true
                });




            }
            else {
                //console.log("coincide con el total");

                //console.log('entré a cambiar state');

                if ((totalSumaVacantesTemporal + totalSumaVacantesFijo) > 20) {
                    Swal.fire({
                        position: 'top',
                        icon: 'warning',
                        title: "Máximo 20 vacantes",
                        confirmButtonColor: "#4DA357",
                        showConfirmButton: true
                    });
                } else {
                    if (this.state.selectsemanas === 0 && totalSumaVacantesTemporal > 0) {
                        Swal.fire({
                            position: 'top',
                            icon: 'warning',
                            title: "Las vacantes temporales requieren una duración de por lo menos una semana.",
                            confirmButtonColor: "#4DA357",
                            showConfirmButton: true
                        });

                    } else {
                        if(this.state.selectsemanas > 0 && totalSumaVacantesTemporal === 0){
                            
                            this.setState({
                                vacantesfijos: parseInt(this.numVacantesFijoRef.current.value),
                                horarionueve: parseInt(this.horarioNueveRef.current.value),
                                horarioonce: parseInt(this.horarioOnceRef.current.value),
                                horariodiez: parseInt(this.horarioDiezRef.current.value),
                                horariodoce: parseInt(this.horarioDoceRef.current.value),
                                vacantestemporales: parseInt(this.numVacantesTemporalesRef.current.value),
                                horarionueveTemp: parseInt(this.horarioNueveTempRef.current.value),
                                horarioonceTemp: parseInt(this.horarioOnceTempRef.current.value),
                                horariodiezTemp: parseInt(this.horarioDiezTempRef.current.value),
                                horariodoceTemp: parseInt(this.horarioDoceTempRef.current.value),
                                sucursal: this.props.sucursal,
                                divDetalleSucursal: false,              
                                divFormularioNuevaSolicitud: false,
                                divMostrarResumen: true,
                                mostrarAutocomplete: false,
                                selectsemanas: 0
    
                            });
                        }else{
                            this.setState({
                                vacantesfijos: parseInt(this.numVacantesFijoRef.current.value),
                                horarionueve: parseInt(this.horarioNueveRef.current.value),
                                horarioonce: parseInt(this.horarioOnceRef.current.value),
                                horariodiez: parseInt(this.horarioDiezRef.current.value),
                                horariodoce: parseInt(this.horarioDoceRef.current.value),
                                vacantestemporales: parseInt(this.numVacantesTemporalesRef.current.value),
                                horarionueveTemp: parseInt(this.horarioNueveTempRef.current.value),
                                horarioonceTemp: parseInt(this.horarioOnceTempRef.current.value),
                                horariodiezTemp: parseInt(this.horarioDiezTempRef.current.value),
                                horariodoceTemp: parseInt(this.horarioDoceTempRef.current.value),
                                sucursal: this.props.sucursal,
                                divDetalleSucursal: false,
                                divFormularioNuevaSolicitud: false,
                                divMostrarResumen: true,
                                mostrarAutocomplete: false
    
                            });
                        }
                        
                    }

                }
            }
        }

    }

    onChangeCal = (fechaInput) => {
        let fecha = new Date(fechaInput).getDate() + "/" + (new Date(fechaInput).getMonth()+1) + "/" + new Date(fechaInput).getFullYear()
        this.setState({ fecha: fecha });
        console.log("Esta es la fecha", this.state.fecha)
    }

    mostrarDetalleFormulario = () => {
        console.log('entré a mostrar detalle formulario');
        console.log('El valor ingresado es ' + this.buscarSucursal.current.value);
        console.log('Tamaño del string: ' + this.buscarSucursal.current.value.length);
        if (this.buscarSucursal.current.value.length >= 3) {
            var solicitud = { data: { sucursalId: this.buscarSucursal.current.value } };
            console.log(solicitud);
            axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/solicits/sucursalId', solicitud)
                .then(response => {
                    console.log("respuesta datos");
                    console.log(response.data);
                    console.log("numero resultados: " + response.data.data.sucursals.length)
                    if (response.data.data.sucursals.length >= 1) {
                        this.setState({
                            sucursales: response.data.data.sucursals
                        });
                    }


                });

        }
        else {
            this.setState({
                divDetalleSucursal: false,
                divFormularioNuevaSolicitud: false
            });
        }

    }


    textoSugerido = (value) => {
        console.log("Hola SP :" + value.idSucursal);
        var solicitud = { data: { sucursalId: value.idSucursal } };
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/solicits/sucursalData', solicitud)
            .then(response => {
                console.log("respuesta datos");
                console.log(response.data.data);
                console.log("numero resultados: " + response.data.data.length);
                response.data.data.map((respuesta) => {
                    //console.log("respuesta estado: " + respuesta.estado);
                    this.buscarSucursal.current.value = value.idSucursal;//lo que hice fue apuntar lavariable a la referencia para posit el value
                    console.log("respuesta id sucursal " + value.idSucursal);
                    this.setState({
                        sucursalBuscada: value.idSucursal,
                        sucursales: [],
                        divDetalleSucursal: true,
                        divFormularioNuevaSolicitud: true,
                        suc_cp_suc: respuesta.cp_suc,
                        suc_direccion_suc: respuesta.direccion_suc,
                        suc_estado: respuesta.estado,
                        suc_idSucursal: value.idSucursal,
                        suc_id: respuesta.idSucursal,
                        suc_municipio: respuesta.municipio,
                        suc_nameSucursal: respuesta.nameSucursal,
                        suc_region: respuesta.region,
                        suc_territorio: respuesta.territorio,
                        suc_zona: respuesta.zona,
                        mostrarAutocomplete: true
                    });
                });
            });
    }


    obtenerSugerencias = () => {
        console.log("obtener sugerencias");
        console.log(this.state.sucursales);
        if (this.state.sucursales.length >= 1) {
            return (<ul className="listaSugerencias">
                {
                    this.state.sucursales.map((item, i) => {
                        return (
                            <div key={i}>
                                <li onClick={() => this.textoSugerido(item)}>{item.idSucursal}</li>
                                <hr />
                            </div>
                        )
                    })
                }
            </ul>);
        }
    }
    enviarSolicitud = () => {
        var idtipovacanteFijo;//cambiar dependiendo el ambiente 3 dev, 2 qa

        if (process.env.REACT_APP_ENVIRONMEN === 'dev') {
            idtipovacanteFijo = 3;
        } else {
            idtipovacanteFijo = 1;
        }

        var idtipovacanteTemp;//cambiar dependiendo el ambiente 4 dev, 2 qa

        if (process.env.REACT_APP_ENVIRONMEN === 'dev') {
            idtipovacanteTemp = 4;
        } else {
            idtipovacanteTemp = 2;
        }
        console.log("ambiente : " + environment[process.env.REACT_APP_ENVIRONMEN]);

        var solicitud = {
            data: {
                tipoVancantes: [
                    { idTipoVacante: idtipovacanteFijo, totalVacantes: this.state.horarionueve, idHorario: 1, numWeek: null },
                    { idTipoVacante: idtipovacanteFijo, totalVacantes: this.state.horarioonce, idHorario: 2, numWeek: null },
                    { idTipoVacante: idtipovacanteFijo, totalVacantes: this.state.horariodiez, idHorario: 3, numWeek: null },
                    { idTipoVacante: idtipovacanteFijo, totalVacantes: this.state.horariodoce, idHorario: 4, numWeek: null },
                    { idTipoVacante: idtipovacanteTemp, totalVacantes: this.state.horarionueveTemp, idHorario: 1, numWeek: this.state.selectsemanas },
                    { idTipoVacante: idtipovacanteTemp, totalVacantes: this.state.horarioonceTemp, idHorario: 2, numWeek: this.state.selectsemanas },
                    { idTipoVacante: idtipovacanteTemp, totalVacantes: this.state.horariodiezTemp, idHorario: 3, numWeek: this.state.selectsemanas },
                    { idTipoVacante: idtipovacanteTemp, totalVacantes: this.state.horariodoceTemp, idHorario: 4, numWeek: this.state.selectsemanas }
                ],
                idsucursal: this.state.suc_id,
                fechaTentativa: moment(this.state.fecha).format('DD/MM/YYYY'),
                responsableId: this.state.responsable
            }
        };
        console.log(solicitud);
        axios.post(environment[process.env.REACT_APP_ENVIRONMEN].url + '/solicits/saveSolicits', solicitud)
            .then(response => {
                //console.log("respuesta datos");
                //console.log(response.data);

                Swal.fire({
                    position: 'top',
                    imageUrl: "https://i.ibb.co/Hg9yL5V/aplauso.png",
                    imageWidth: 70,
                    imageHeight: 70,
                    title: "¡Felicidades! - Solicitaste " + (this.state.vacantesfijos + this.state.vacantestemporales) + " vacantes nuevas",
                    showConfirmButton: false,
                    timer: 3000
                }).then((response) => {
                    console.log("RESpuesta sev",response)
                    window.location.href = "/vacantes";
                });
            });
    }

    volverMostar = () => {
        console.log("Click en volver a mostrar");
        console.log("horas fijas totales state " + this.state.vacantesfijos);

        this.setState({
            mostrarAutocomplete: true,
            divDetalleSucursal: true,
            divFormularioNuevaSolicitud: true,
            divMostrarResumen: false,
        });
    }

    activarCalendario = () => {
        console.log("entré a activar calendario");

        var totalSumaVacantesFijas = parseInt(this.horarioNueveRef.current.value) + parseInt(this.horarioOnceRef.current.value) + parseInt(this.horarioDiezRef.current.value) + parseInt(this.horarioDoceRef.current.value);
        var totalSumaVacantesTemporales = parseInt(this.horarioNueveTempRef.current.value) + parseInt(this.horarioOnceTempRef.current.value) + parseInt(this.horarioDiezTempRef.current.value) + parseInt(this.horarioDoceTempRef.current.value);
        console.log("Total fijas: " + totalSumaVacantesFijas);
        console.log("Total temporales: " + totalSumaVacantesTemporales);

        if (totalSumaVacantesFijas === parseInt(this.numVacantesFijoRef.current.value) && totalSumaVacantesTemporales === parseInt(this.numVacantesTemporalesRef.current.value)) {

            console.log("La suma de ambas coincide");
            if ((totalSumaVacantesFijas + totalSumaVacantesTemporales) <= 20) {
                console.log("cantidad adecuada");
                console.log("select semanas: " + this.state.selectsemanas);
                this.setState({
                    calendarioActivo: true,
                    botonGuardarActivo: false
                });
            }
            else {
                console.log("El máximo de solicitudes es 20");
                this.setState({
                    calendarioActivo: false,
                    botonGuardarActivo: true
                });
            }
        }
        else {
            console.log("La suma de ambas no coincide");
            this.setState({
                calendarioActivo: false,
                botonGuardarActivo: true
            });
        }
    }

    cambiarSemanas = (event) => {
        this.setState({
            selectsemanas: event.target.value
        });
    }

    render() {
        return (<div>


            <div className="row">
                <div className="col col-lg-1 offset-lg-11">
                    <Usuario />
                </div>
            </div>
            <div className="row">
                <div className="FondoBlanco mt-3 p-5">
                    <div className="row mt-3">
                        <div>
                            <div className="row">
                                <div className="col col-lg-10">
                                    <Link to="/vacantes">
                                        <button type="button" className="btn btn-outline-success btn-rounded btn-icon">
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        </button>
                                    </Link>
                                    <span className="textoSuperior">Crear nueva solicitud</span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {this.state.mostrarAutocomplete ? (<div className="col col-lg-4">
                                    <div className="mb-3 col-7">
                                        <label htmlFor="buscarSucursal" className="form-label">Sucursal:</label>
                                        <input
                                            ref={this.buscarSucursal}
                                            onChange={(e) => this.mostrarDetalleFormulario()}
                                            type="text"
                                            className="form-control"
                                            id="buscarSucursal"
                                            placeholder="ID de sucursal*"
                                            autoComplete="off"
                                            defaultValue={this.state.suc_idSucursal}
                                        />
                                        {this.obtenerSugerencias()}
                                    </div>
                                </div>) : null}
                                <div className="col col-lg-8">
                                    {this.state.divDetalleSucursal ? (<div className="mb-3">
                                        <span className="span-title">{this.state.suc_nameSucursal}</span>
                                        <p className="pNormal mt-3"><b>Territorio:</b>  {this.state.suc_territorio}  <b className="m-2">Zona - Región: </b>{this.state.suc_region}</p>
                                        <p className="pNormal"><b>Dirección:</b> {this.state.suc_direccion_suc} CP:{this.state.suc_cp_suc} </p>
                                        <p className="pNormal"><b>Municipio:</b> {this.state.suc_municipio} <b className="m-2">Estado:</b> {this.state.suc_estado}</p>
                                    </div>) : null}


                                </div>
                            </div>
                            {this.state.divFormularioNuevaSolicitud ? (
                                <div className="row mt-3" id="FormularioNuevaSolicitud">
                                    <div className="col col-lg-4">
                                        <p className="pNormal mt-4 mb-4">Número de Vacantes</p>
                                        <div className="form-group row">
                                            <label htmlFor="numVacantesFijo" className="col-sm-1 col-form-label">Fijo</label>
                                            <div className="col-3">
                                                <input pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.vacantesfijos} max="20" min="0" ref={this.numVacantesFijoRef} type="number" className="form-control" id="numVacantesFijo" placeholder="Número" />
                                            </div>
                                        </div>
                                        <p className="pNormal mt-4 mb-4">Ingresa el número de vacantes por cada horario:</p>

                                        <div className="form-group row mb-1">
                                            <div className="col-lg-3">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horarionueve} ref={this.horarioNueveRef} className="form-control" id="horarioNueve" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioNueve">9:00 am - 6:00 pm</label>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-1">
                                            <div className="col-lg-3">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horariodiez} ref={this.horarioDiezRef} className="form-control" id="horarioDiez" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioDiez">10:00 am - 7:00 pm</label>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-1">
                                            <div className="col-lg-3">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horarioonce} ref={this.horarioOnceRef} className="form-control" id="horarioOnce" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioOnce">11:00 am - 8:00 pm</label>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-1">
                                            <div className="col-lg-3">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horariodoce} ref={this.horarioDoceRef} className="form-control" id="horarioDoce" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioDoce">12:00 pm - 9:00 pm</label>
                                            </div>
                                        </div>
                                        <p className="pNormal mt-4 mb-4">Fecha tentativa de ingreso:<i className="fa fa-calendar-alt" aria-hidden="true"></i></p>
                                        <div className="form-group row mb-1">

                                        <div className="col-lg-11">
                                        {/*<DatePicker  selected={this.state.fecha} onChange={this.onChangeCal} locale="es" dateFormat="dd/MM/yyyy" readOnly={(this.state.calendarioActivo) ? "" : true} />*/}

                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                <DatePicker
                                                    className="form-control"
                                                    inputFormat="dd/MM/yyyy"
                                                    value={this.state.fecha}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            fechaView: new Date(newValue).getDate() + "/" + (new Date(newValue).getMonth()+1) + "/" + new Date(newValue).getFullYear()
                                                        });
                                                        //console.log("Cambio calendariooo", newValue)
                                                        this.setState({
                                                            fecha: newValue,
                                                            //disableBtnGuardar: false
                                                        });
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    disabled={(this.state.calendarioActivo) ? false : true}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                            
                                            
                                        </div>
                                        <div className="col-lg-4">
                                            <button onClick={this.guardarNuevaSolicitud} id="guardarSolicitudBtn" type="button" className="btn btn-success mt-2" disabled={this.state.botonGuardarActivo}>Guardar</button>
                                        </div>


                                    </div>
                                    <div className="col col-lg-6 mt-5">
                                        <div className="form-group row mt-3">
                                            <label htmlFor="numVacantesTemporal" className="col-2 col-form-label" style={{width: 'auto'}}>Temporal</label>
                                            <div className="col-sm-2">
                                                <input pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.vacantestemporales} max="20" min="0" ref={this.numVacantesTemporalesRef} type="number" className="form-control" id="numVacantesTemporal" />
                                            </div>
                                            <label htmlFor="numVacantesFijo" className="col-sm-3 col-form-label" style={{width: 'auto'}}>Número de Semanas</label>
                                            <div className="col-sm-2">
                                                <select onChange={this.cambiarSemanas} value={this.state.selectsemanas} className="form-select" aria-label="Número de semanas">
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                </select>
                                            </div>
                                        </div>
                                        <p className="pNormal mt-4 mb-4">Ingresa el número de vacantes por cada horario:</p>

                                        <div className="form-group row mb-1">
                                            <div className="col-lg-2">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horarionueveTemp} ref={this.horarioNueveTempRef} className="form-control" id="horarioNueveTemp" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioNueveTemp">9:00 am - 6:00 pm</label>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-1">
                                            <div className="col-lg-2">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horariodiezTemp} ref={this.horarioDiezTempRef} className="form-control" id="horarioDiezTemp" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioDiezTemp">10:00 am - 7:00 pm</label>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-1">
                                            <div className="col-lg-2">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horarioonceTemp} ref={this.horarioOnceTempRef} className="form-control" id="horarioOnceTemp" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioOnceTemp">11:00 am - 8:00 pm</label>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-1">
                                            <div className="col-lg-2">
                                                <input max="20" min="0" pattern="[0-9]*" onChange={this.activarCalendario} defaultValue={this.state.horariodoceTemp} ref={this.horarioDoceTempRef} className="form-control" id="horarioDoceTemp" type="number"  />
                                            </div>
                                            <div className="col-lg-7">
                                                <label className="lbl-hr" htmlFor="horarioDoceTemp">12:00 pm - 9:00 pm</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}


                            {this.state.divMostrarResumen ? (<div>
                                <div className="row mb-4">
                                    <div className="col col-lg-8">
                                        <span className="textoSuperior">Nueva solicitud</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-lg-10">
                                        <span className="textoSuperior">A continuación se muestra el detalle de la Solicitud de la vacante</span>
                                        <p className="pFade">Revisa que todos los datos sean correctos y al final da clic <b>aceptar</b></p>
                                    </div>
                                    <div className="col col-lg-2">

                                        <button onClick={this.volverMostar} id="btnDescargar" type="button" className="btn btn-outline-success">
                                            <i className="fa fa-pencil-square-o espaciador" aria-hidden="true"></i>Editar</button>

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col col-lg-12">
                                        <div className="mb-3">
                                            <p><b className="verdeTitle">{this.state.suc_idSucursal}</b><b className="negroTitle">-{this.state.suc_nameSucursal}</b></p>
                                            <p className="pFade"><b className="pFadeB">Territorio:</b> {this.state.suc_territorio} <b className="pFadeB">Zona - Región</b> {this.state.suc_region} <b className="pFadeB">Dirección:</b> {this.state.suc_direccion_suc}</p>
                                            <p className="pFade"><b className="pFadeB">Municipio:</b> {this.state.suc_municipio} <b className="pFadeB">Estado:</b> {this.state.suc_estado}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col col-lg-12">
                                        <div className="mb-3">
                                            <p className="pNormal"><b className="bNormal">Responsable de la solicitud:</b> {this.state.responsable}</p>
                                            <p className="pNormal"><b className="bNormal">Fecha de solicitud:</b> <Moment locale='es' format="DD/MM/YYYY">{this.state.fechaHoy.toString()}</Moment></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col col-lg-6">
                                        <div className="mb-3">
                                            <p><b className="verdeTitle">Número de Vacantes</b></p>
                                            <p className="pNormal">{this.state.vacantesfijos + this.state.vacantestemporales}</p>
                                            <p className="pNormal"><b className="bNormal">Vacantes fijos: </b>{this.state.vacantesfijos}</p>
                                            {
                                                this.state.horarionueve > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horarionueve}</label> <b className="bNormal">Horario: </b>9:00am - 6:00 pm</p>) : null
                                            }
                                            {
                                                this.state.horarioonce > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horarioonce}</label> <b className="bNormal">Horario: </b>11:00am - 8:00 pm</p>) : null
                                            }
                                            {
                                                this.state.horariodiez > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horariodiez}</label> <b className="bNormal">Horario: </b>10:00am - 7:00 pm</p>) : null
                                            }
                                            {
                                                this.state.horariodoce > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horariodoce}</label> <b className="bNormal">Horario: </b>12:00am - 9:00 pm</p>) : null
                                            }

                                        </div>
                                    </div>
                                    <div className="col col-lg-6 mt-4">
                                        <div className="mb-3 mt-5">
                                            <p className="pNormal"><b className="bNormal">Vacantes temporales: </b>{this.state.vacantestemporales}</p>
                                            <p className="pNormal"><b className="bNormal">Número de semanas: </b>{this.state.selectsemanas}</p>
                                            {
                                                this.state.horarionueveTemp > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horarionueveTemp}</label> <b className="bNormal">Horario: </b>9:00am - 6:00 pm</p>) : null
                                            }
                                            {
                                                this.state.horarioonceTemp > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horarioonceTemp}</label> <b className="bNormal">Horario: </b>11:00am - 8:00 pm</p>) : null
                                            }
                                            {
                                                this.state.horariodiezTemp > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horariodiezTemp}</label> <b className="bNormal">Horario: </b>10:00am - 7:00 pm</p>) : null
                                            }
                                            {
                                                this.state.horariodoceTemp > 0 ? (<p className="pNormal"><label className="numberV">{this.state.horariodoceTemp}</label> <b className="bNormal">Horario: </b>12:00am - 9:00 pm</p>) : null
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col col-lg-12">
                                        <div className="mb-3">
                                            <p><b className="verdeTitle">Fecha Tentativa de Ingreso</b></p>
                                            <p className="pNormal"><Moment locale='es' format="DD/MM/YYYY">{this.state.fecha.toString()}</Moment></p>
                                        </div>
                                        <button onClick={this.enviarSolicitud} type="button" className="btn btn-success mt-2">Aceptar</button>
                                    </div>
                                </div>
                            </div>) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
export default VacanteNuevaSolicitud;